import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFound from './views/NotFound.vue';

Vue.use(VueRouter);

export const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
});

router.addRoute('', {
    name: 'Not Found',
    meta: {
        title: 'Ooops',
    },
    path: '*',
    component: NotFound,
});
