







import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ContentfulMixin from '../content/mixin';
import {penniStepModule} from '@/store';

@Component
export default class PenniControl extends mixins(ContentfulMixin) {
    @Prop({required: false, type: Boolean, default: false}) public hideLabel!: boolean;
    @Prop({required: false, type: Boolean, default: false}) public horizontal!: boolean;
    @Prop({required: false, type: Boolean, default: false}) public wrapElements!: boolean;

    public mounted(): void {
        /**
         * When any control in the flow is clicked we have to disable auto proceeding.
         * If we don't do this, the flow would continue while the user is interacting with input fields.
         */
        this.$el.addEventListener('click', () => {
            if (penniStepModule.shouldAutoProceed) {
                penniStepModule.setAutoProceed(false);
            }
        });
    }
}
