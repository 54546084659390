import {PluginObject, VueConstructor} from 'vue';
import {Radio} from 'buefy';
import PenniRadio from './Radio.vue';
import PenniRadioGroup from './RadioGroup.vue';

export * from './Radio.vue';
export * from './RadioGroup.vue';

export const PenniRadioPlugin: PluginObject<null> = {
    install(Vue: VueConstructor): void {
        Vue.use(Radio);
        Vue.component('PenniRadio', PenniRadio);
        Vue.component('PenniRadioGroup', PenniRadioGroup);
    },
};
