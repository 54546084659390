var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"penni-phone"},[_c('BField',{attrs:{"label":_vm.contentfulLabel,"type":_vm.fieldValidationType}},[_c('vue-tel-input',{class:[
                ("penni-input-" + _vm.name),
                'penni-input-phone',
                ("" + _vm.width),
                _vm.showValidationError ? 'check is-danger' : '' ],attrs:{"value":_vm.model,"name":_vm.name,"defaultCountry":_vm.defaultCountry,"autoFormat":false,"validCharactersOnly":true,"disabled":_vm.disabled,"onlyCountries":_vm.countries,"inputOptions":{
                name: _vm.name,
                required: _vm.required,
                placeholder: _vm.contentfulPlaceholder,
                type: 'tel',
            }},on:{"input":_vm.onInput,"validate":_vm.validatePhoneInput},nativeOn:{"keydown":function($event){return _vm.keydown.apply(null, arguments)}}})],1),(_vm.contentfulValidationMsg != null && _vm.showValidationError)?_c('div',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.contentfulValidationMsg)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }