export function getWidgetLoader(): string {
    switch (process.env.VUE_APP_CF_ENTRY_ENVIRONMENT_VARIANT) {
        case 'master1':
            return 'https://widget.penni-connect.io/v3-loader.js';
        case 'demo':
            return 'https://widget1.demo.dev.penni-connect.io/v3-loader.js';
        case 'ci':
            return 'https://widget1.ci.dev.penni-connect.io/v3-loader.js';
        default:
            return 'https://widget1.staging.dev.penni-connect.io/v3-loader.js';
    }
}
