import Vue from 'vue';
import Vuex, {Store} from 'vuex';
import {InputModule} from './inputModule';
import {StepModule} from './stepModule';
import {ContentModule} from './contentModule';
import {CheckoutModule} from './checkoutModule';
import {CommonModule} from './commonModule';
import {ProductModule} from './productModule';
import {PaymentModule} from './paymentModule';
import {getModule} from 'vuex-module-decorators';
import {SignatureModule} from './signatureModule';

Vue.use(Vuex);

export const store: Store<unknown> = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        inputModule: InputModule,
        stepModule: StepModule,
        contentModule: ContentModule,
        checkoutModule: CheckoutModule,
        commonModule: CommonModule,
        productModule: ProductModule,
        paymentModule: PaymentModule,
        signatureModule: SignatureModule,
    },
});

export const penniContentModule = getModule(ContentModule, store);
export const penniCheckoutModule = getModule(CheckoutModule, store);
export const penniStepModule = getModule(StepModule, store);
export const penniInputModule = getModule(InputModule, store);
export const penniCommonModule = getModule(CommonModule, store);
export const penniProductModule = getModule(ProductModule, store);
export const penniPaymentModule = getModule(PaymentModule, store);
export const penniSignatureModule = getModule(SignatureModule, store);
