






import {ContentModelTypes} from '@penni/contentful-api';
import ResourceMixin from './mixin';
import {Component, Prop, Mixins} from 'vue-property-decorator';
import {penniContentModule, penniProductModule} from '../store';
import ContentfulMixin from '../content/mixin';

@Component
export default class RichTextResource extends Mixins(ResourceMixin, ContentfulMixin) {
    public resourceType: ContentModelTypes.RICH_TEXT = ContentModelTypes.RICH_TEXT;

    @Prop({required: true, type: String}) public resourceId!: string;
    @Prop({required: false, type: Object}) public variablesToReplace!: Record<string, string>;

    public created(): void {
        if (penniContentModule.checkout == null) {
            throw new Error('There is no checkout in the Content store');
        }
    }

    public get resourceText(): {template: string} | undefined {
        const resource = this.getRawRichTextResource(this.resourceId);

        if (resource == null) {
            return {
                template: `<span>missing resource: ${this.checkoutType}-${ContentModelTypes.RICH_TEXT}-${this.resourceId}</span>`,
            };
        }

        return this.generateRichTextTags(
            this.variablesToReplace == null
                ? resource.htmlString
                : this.replaceVariables(resource.htmlString, this.variablesToReplace)
        );
    }

    /* istanbul ignore next */
    public generateRichTextTags(target: string): {template: string} {
        // Replace all modals
        // Group 1: Name - Group 2: Text
        const modalRegex = /\[modal:(\w*):(.*?)\]/g;
        target = target.replace(modalRegex, (match, name, text) => {
            return `<PenniModalTrigger name="${name}" text="${text}" />`;
        });

        // Replace all dropdowns
        // Group 1: Name - Group 2: Form name - Group 3: Option key
        const dropdownRegex = /\[dropdown:(\w*?):(.*?):(.*?)\]/g;
        target = target.replace(dropdownRegex, (match, name, form, option) => {
            if (penniProductModule.getOption(option) == null) {
                return `<span></span>`;
            }

            return `<PenniDropdownInitializer name="${name}" form="${form}" option="${option}" />`;
        });

        return {
            template: `<span>${target}</span>`,
        };
    }
}
