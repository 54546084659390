





















































































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import * as Api from '../types';
import * as GenericApi from '@penni/generic-api';
import {
    penniInputModule,
    penniProductModule,
    penniCheckoutModule,
    penniCommonModule,
    penniContentModule,
} from '@/store';
import {CancellationType, OptionItem} from '@penni/generic-api';
import {
    getResource,
    ContentModelTypes,
    CustomerJourneyOptions,
    CustomerJourneyCheckoutOptions,
} from '@penni/contentful-api';
import cloneDeep from 'lodash.clonedeep';
import {Input, MessageAutomationInsuranceTypesEnum, penniHttpClient, redirectAfterPurchase} from '@/index';

interface ContentfulOptions {
    steps?: {
        askIsMember: boolean;
    };
}

@Component
export default class MotorView extends Vue {
    public odometerReadingFromBasket: string | null = null;
    public cachedAge: number = 18;
    public askForDriverExperienceCache: boolean = true;
    private hasRegno: boolean = true;

    public async created(): Promise<void> {
        penniCommonModule.setAllowSendToEmail(true);

        this.setSidebarData();
        this.setOrderSummary();

        const basket = penniCheckoutModule.basketResponse;

        if (basket != null) {
            // Set odometer reading in the local component, so that we can use it later.
            this.odometerReadingFromBasket = (basket.products[0].response
                .productPrices[0] as Api.ApiCalculationProductResponse).odometerReading.toString(10);

            this.hasRegno =
                (basket.products[0].request.products[0] as Api.ApiCalculationSimpleRequest).insuredObject.regno !==
                undefined;

            const basketCalculationRequest = basket.products[0].request.products[0] as Api.ApiCalculationSimpleRequest;
            this.cachedAge = basketCalculationRequest.customer.age ?? 18;
            this.askForDriverExperienceCache = this.cachedAge ? this.cachedAge < 30 : true;
        }
    }

    public get product() {
        return penniProductModule.product;
    }

    @Watch('product')
    public updateAfterGettingProduct(): void {
        this.setOrderSummary();
    }

    private setOrderSummary(): void {
        const variantTitle =
            penniContentModule.checkout == null
                ? ''
                : getResource(
                      `${penniContentModule.checkout.type}-text-variation-title-${
                          penniCheckoutModule.basketSelectedVariation?.id ?? ''
                      }`,
                      ContentModelTypes.TEXT,
                      penniContentModule.checkout
                  )?.value;

        const calculationRequest = penniCheckoutModule.calculationRequest
            ?.products[0] as Api.ApiCalculationSimpleRequest;
        const calculationResponse = penniCheckoutModule.calculationResponse
            ?.productPrices[0] as Api.ApiCalculationProductResponse;
        const selectedVariationId = penniCheckoutModule.basketSelectedVariation?.id;
        const selectedVariation = penniCheckoutModule.calculationResponse?.productPrices[0].variations.find(
            (v) => v.id === selectedVariationId
        );
        const variationCoverages = penniProductModule.variations.find((v) => v.id === selectedVariationId)?.coverages;

        /**
         * Map the parameters from the request to the text they correspond to in the product options
         * This can significantly be improved by either having the text returned in the calculation
         * request or having a specific way of getting that
         */
        const {excess, mileage} = calculationRequest.parameters;
        const parameters = {
            excess: {
                text:
                    penniProductModule.options?.excess == null
                        ? ''
                        : (penniProductModule.options.excess as Record<string, unknown>[]).find(
                              (e: Record<string, unknown>) => e.code === excess?.code
                          )?.text || '',
            },
            mileage: {
                text:
                    penniProductModule.options?.mileage == null
                        ? ''
                        : (penniProductModule.options.mileage as Record<string, unknown>[]).find(
                              (e: Record<string, unknown>) => e.code === mileage?.code
                          )?.text || '',
            },
        };

        penniCheckoutModule.setOrderSummary([
            {
                type: MessageAutomationInsuranceTypesEnum.Car,
                title: variantTitle ?? 'Bilforsikring',
                price: selectedVariation?.premium.monthly,
                subtitle: `${calculationResponse?.description} ${
                    calculationRequest?.insuredObject.regno !== undefined
                        ? `(${calculationRequest?.insuredObject.regno?.toUpperCase()})`
                        : ''
                }`,
                parameters,
                coverages: variationCoverages,
            },
        ]);
    }

    private setSidebarData(): void {
        penniCheckoutModule.setDocumentList([
            {
                id: 'ipid-link',
            },
            {
                id: 'betingelser',
            },
        ]);
    }

    public get askIsMember(): boolean {
        return (penniContentModule.checkout?.options as ContentfulOptions)?.steps?.askIsMember ?? true;
    }

    public get isMember(): boolean | undefined {
        const value = (penniInputModule.getInput('member')?.value as string) ?? '';
        if (!value.length) {
            return;
        }
        return value === 'true';
    }

    public get askForRegno(): boolean {
        return !this.hasRegno;
    }

    public marketingConsent(): boolean {
        const value = penniInputModule.getInput('marketingConsent')?.value;
        return value === 'true' ? true : false;
    }

    public get radio(): Input | undefined {
        return penniInputModule.getInput('existingInsurrance');
    }

    public get options(): Api.ApiProductOptions | null {
        return penniProductModule.options;
    }

    public get contentOptions(): CustomerJourneyOptions<CustomerJourneyCheckoutOptions> {
        return penniContentModule.options;
    }

    public get existingInsuranceOptions(): OptionItem[] | undefined {
        return this.options ? this.options.insuranceCompany : undefined;
    }

    public get carVariantOptions(): OptionItem[] | undefined {
        return (penniCheckoutModule.calculationResponse?.productPrices[0] as Api.ApiCalculationProductResponse)
            ?.vehicleVariants;
    }

    public get equipmentDropdownItems(): OptionItem[] | undefined {
        return this.options ? this.options.addons : undefined;
    }

    public get timeDropdownItems(): OptionItem[] | undefined {
        return this.options ? this.filterDriverExperience(this.options.driverExperience) : undefined;
    }

    private filterDriverExperience(optionList?: OptionItem[]): OptionItem[] {
        if (optionList === undefined) {
            return [];
        }
        if (this.cachedAge >= 30) {
            return [
                optionList.find((item: OptionItem) => {
                    return item.code === '99';
                }) as OptionItem,
            ];
        } else {
            const maxExperience = this.cachedAge - 18;
            return optionList.filter((item: OptionItem) => {
                return Number.parseInt(item.code, 10) <= maxExperience;
            });
        }
    }

    public get claimsDropdownItems(): OptionItem[] | undefined {
        return this.options ? this.options.claimCount : undefined;
    }

    public get askForDriverExperience(): boolean {
        return this.askForDriverExperienceCache;
    }

    public get transferDatesOptionText(): string {
        return penniContentModule.checkout == null
            ? ''
            : getResource(
                  `${penniContentModule.checkout.type}-text-existing-insurance-cancel-first-option`,
                  ContentModelTypes.TEXT,
                  penniContentModule.checkout
              )?.value ?? 'text-not-found-existing-insurance-cancel-first-option';
    }

    public get transferDates(): OptionItem[] | undefined {
        const formatOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'long', day: 'numeric'};
        return this.options && this.options.transferDate
            ? cloneDeep(this.options.transferDate).map((option: GenericApi.OptionItem, index: number) => {
                  const text: string = new Date(option.code).toLocaleDateString('da-DK', formatOptions);
                  return {
                      code: option.code,
                      text: index === 0 ? `${this.transferDatesOptionText} (${text})` : text,
                      default: option.default,
                  };
              })
            : undefined;
    }

    public get basketWasMappedToInput(): boolean {
        return penniCheckoutModule.basketMappedToInputs;
    }

    public async recalculate(): Promise<void> {
        const params = penniInputModule.getInputsAsParameters;
        const basketCalculationRequest = penniCheckoutModule.basketResponse?.products[0].request
            .products[0] as Api.ApiCalculationSimpleRequest;
        const calculationRequest = cloneDeep(basketCalculationRequest) as Api.ApiCalculationSimpleRequest;
        const basketSelectedVariation = penniCheckoutModule.basketSelectedVariation;

        if (basketSelectedVariation == null) {
            return;
        }
        delete calculationRequest.customer.age;
        calculationRequest.customer.ssn = `${params['socialSecurityNumber']}`;
        if (this.hasRegno) {
            calculationRequest.insuredObject.modelVariantId = {
                code: `${params['confirmCarVariant']}`,
            };
        } else {
            calculationRequest.insuredObject.regno = `${params['regNo']}`;
        }
        calculationRequest.parameters = {
            ...calculationRequest.parameters,
            addons: {
                code: params['addons'] as string,
            },
            driverExperience: this.askForDriverExperience
                ? {
                      code: params['driverExperience'] as string,
                  }
                : undefined,
            claimCount: {
                code: params['claimCount'] as string,
            },
            odometerReading: Number.parseInt(`${params['odometerReading']}`, 10),
        };

        await this.recalculatePrice(calculationRequest, basketSelectedVariation.id);

        this.setOrderSummary();
    }

    public async recalculatePrice(
        calculationRequest: Api.ApiCalculationSimpleRequest,
        variationId: string
    ): Promise<void> {
        try {
            const response = await penniHttpClient.post<Api.ApiCalculationProductResponse>(
                `/calculate/${variationId}`,
                calculationRequest
            );
            penniCheckoutModule.setCalculationRequest({
                products: [calculationRequest],
            });
            penniCheckoutModule.setCalculationResponse({
                productPrices: [response.data],
            });
        } catch (error) {
            penniCheckoutModule.setCalculationResponse({productPrices: []});
            penniCommonModule.setDidThrow({error: error as Error, didThrow: true});
            penniCommonModule.setLoading(false);
            throw new Error('failed to recalculate product');
        }
    }

    public async createQuote(): Promise<void> {
        const calculationRequest = cloneDeep(
            penniCheckoutModule.calculationRequest?.products[0]
        ) as Api.ApiCalculationSimpleRequest;
        const params = penniInputModule.getInputsAsParameters;
        const basketSelectedVariation = penniCheckoutModule.basketSelectedVariation;

        const basket = penniCheckoutModule.basketResponse;
        const basketCalculationRequest = basket?.products[0].request.products[0] as Api.ApiCalculationSimpleRequest;

        if (basketSelectedVariation == null) {
            return;
        }

        const request: Api.ApiQuoteRequest = {
            customer: {
                name: {
                    firstName: `${params['firstname']}`,
                    lastName: `${params['lastname']}`,
                },
                ssn: `${params['socialSecurityNumber']}`.replace(/-/g, ''),
                contact: {
                    email: `${params['email']}`,
                    phone:
                        basketCalculationRequest.customer.contact?.phone ??
                        basketCalculationRequest.customer.phone ??
                        '',
                },
                address: calculationRequest.customer.address,
                consent: {
                    all: this.marketingConsent(),
                },
            },
            payment: {
                type: Api.PaymentType.PPS,
                bank: `${params['bank']}`,
                account: `${params['banknumber']}`,
            },
            products: [
                {
                    productId: calculationRequest.productId,
                    productVariationId: basketSelectedVariation.id,
                    partnerId: calculationRequest.partnerId,
                    parameters: calculationRequest.parameters,
                    insuredObject: calculationRequest.insuredObject,
                    campaignCode: calculationRequest.campaignCode,
                    cancellation: this.getCancellationInformation(),
                },
            ],
        };

        if (penniCheckoutModule.basketResponse?.affiliates != null) {
            request.affiliates = penniCheckoutModule.basketResponse.affiliates;
        }

        await penniCheckoutModule.postQuote(request);

        const selectedVariation = penniCheckoutModule.calculationResponse?.productPrices[0].variations.find(
            (v) => v.id === basketSelectedVariation.id
        );

        redirectAfterPurchase(
            this.contentOptions,
            {
                inputs: penniInputModule.getInputsAsParameters,
                product: penniProductModule.product,
                calculationRequest: penniCheckoutModule.calculationRequest,
                basketResponse: penniCheckoutModule.basketResponse,
                selectedVariation,
            },
            {status: penniCommonModule.didThrow ? 'error' : 'success', id: penniCheckoutModule.quoteResponse?.quoteId}
        );
    }

    private getCancellationInformation(): Api.ApiCancellation {
        const params = penniInputModule.getInputsAsParameters;
        switch (params['existingInsurrance']) {
            case 'true':
                return {
                    type: CancellationType.TRANSFER,
                    insuranceCompany: {
                        code: params['selectExistingInsurance'],
                    },
                    transferDate: {
                        code: params['cancelInsuranceDate'],
                    },
                } as Api.ApiCancellationTransfer;
            case 'false':
                return {
                    type: CancellationType.NEW,
                } as Api.ApiCancellationNew;
            default:
                throw Error('cancellation information not specified');
        }
    }
}
