






























import {penniInputModule} from '@/store';
import {RadioItem} from '@/store/models';
import {Component, Mixins, Prop} from 'vue-property-decorator';
import {FormValidation} from '../formValidation';
import InputMixin from '../mixin';

@Component
export default class PenniRadioGroup extends Mixins(InputMixin) {
    /**
     * @description
     * A list of RadioItem that will be rendered into `<BRadio />` components
     */
    @Prop({required: true, type: Array, default: () => []}) public readonly items!: RadioItem[];
    /**
     * @description
     * Specifies the valid option which will be checked with `===`
     * If all options are valid don't use this option, or set it to an empty string
     */
    @Prop({required: false, type: String, default: ''}) public readonly validOption?: string;

    public mounted(): void {
        const itemsWithEvaluatedResources = this.items.map((item) => ({
            ...item,
            resourceValue: this.getTextResource(item.resourceId, ''),
        }));

        penniInputModule.setInput({
            name: this.name,
            req: this.req,
            form: this.form,
            type: 'radio',
            value: '',
            summary: '',
            valid: false,
            required: this.required,
            validationMessage: this.validationMessage,
            props: {...this.$props, ...{items: itemsWithEvaluatedResources}},
        });
    }

    public isValid(): boolean {
        if (this.input == null) {
            return false;
        }

        return new FormValidation(this.input).isValid();
    }

    public onChange(value: boolean): void {
        const selectedItem = this.items.find((item: RadioItem) => item.value === value.toString());
        const summary = selectedItem != null ? this.getTextResource(selectedItem.resourceId, '') : '';

        /**
         * We have to update the value by it self first,
         * since `isValid` uses the value from the store (input.value) and not the internal model
         */
        penniInputModule.updateInput({
            name: this.name,
            value: value.toString(),
            summary,
            required: this.required,
        });
        penniInputModule.updateInput({
            dirty: true,
            name: this.name,
            valid: this.isValid(),
            value: value.toString(),
            summary,
            required: this.required,
        });
        this.valueChange(value.toString());
        this.blur();
    }

    public onClick(item: RadioItem): void {
        if (item.onClick != null) {
            item.onClick();
        }
    }

    public get model(): string {
        if (this.input?.value == null) {
            return '';
        }

        if (typeof this.input.value !== 'string') {
            return this.input.value.toString();
        }

        return this.input.value;
    }

    public set model(value: string) {
        penniInputModule.updateInput({
            name: this.name,
            value,
            valid: this.isValid(),
            summary: value,
            required: this.required,
        });
        this.valueChange(value);
        this.blur();
    }
}
