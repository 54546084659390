import {
    Client as PrismClient,
    ClientConfig,
    CookieConfig,
    DeviceIdentifier,
    EventEmitter,
    ExperimentConfig,
    initEmitter,
} from '@penni/analytics-tracker';
import {CheckoutEntry} from '@penni/contentful-api';
import {PrismConfig} from './model';

/**
 * Client implementing {@link @penni/analytics-tracker} AnalyticsEvent
 */
export class PrismCheckoutClient {
    public eventEmitter: EventEmitter | null = null;
    public client: PrismClient | undefined;

    public deviceIdentifier: DeviceIdentifier;

    private readonly baseUrl: string;

    public constructor(private readonly config: PrismConfig) {
        this.deviceIdentifier = new DeviceIdentifier(window);
        this.baseUrl = `${this.config.baseUrl}/prism`;
    }

    public configureDeviceIdentifier(client?: ClientConfig, cookie?: CookieConfig): void {
        this.deviceIdentifier = new DeviceIdentifier(window, {client, cookie});
    }

    public setupImpressionObserver(element: Element): void {
        const onImpression = (entries: IntersectionObserverEntry[]): void => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    return;
                }

                this.client?.impression();
            });
        };

        const impressionObserver = new IntersectionObserver(onImpression, {threshold: 0});
        impressionObserver.observe(element);
    }

    public configure(checkout: CheckoutEntry): void {
        let experimentConfig: ExperimentConfig | undefined = undefined;
        if (checkout.experiment != null) {
            experimentConfig = {
                id: checkout.experiment.id,
                controlGroup: checkout.experiment.isInControlGroup,
            };
        }

        if (checkout.options?.features?.analytics?.enabled !== false) {
            // we're using some simplified window in analytics-tracker which we dont have here
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.eventEmitter = initEmitter(window as any, this.deviceIdentifier, {
                endpointUrl: this.baseUrl,
                emitter: {
                    source: {
                        id: checkout.id,
                        format: 'full-page',
                        type: 'checkout',
                    },
                    experiment: experimentConfig,
                },
            });
        }

        this.client = new PrismClient(this.deviceIdentifier, this.eventEmitter);
    }
}
