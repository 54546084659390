import {PluginObject, VueConstructor} from 'vue';
import {Numberinput} from 'buefy';
import PenniNumber from './Number.vue';

export * from './Number.vue';

export const PenniNumberPlugin: PluginObject<null> = {
    install(Vue: VueConstructor): void {
        Vue.use(Numberinput);
        Vue.component('PenniNumber', PenniNumber);
    },
};
