






import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class PaymentOption extends Vue {
    @Prop({required: false, type: Boolean, default: false}) public readonly active!: boolean;
}
