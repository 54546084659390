import {bootstrap, router} from '@/app';
import App from '@/app/views/App.vue';
import {PenniRouteConfig} from 'vue-router';

import(
    /* webpackChunkName: "customer" */
    /* webpackMode: "eager" */
    `./${process.env.VUE_APP_CUSTOMER}/src/router/index.ts`
).then(({routes}: {routes: PenniRouteConfig[]}) => {
    routes.forEach((route) => router.addRoute('', route));
    bootstrap(App);
});
