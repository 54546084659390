// TODO: PCN-1661: This is a copy of the interfaces and enums in @penni/core-api's MessageService.ts.
// This copy exists because of the current incompatibity of the latest generic-api version.
export enum MessageAutomationEnum {
    SavedBasket = 'has_saved_basket',
    AbandonedBasket = 'has_abandoned_basket',
    SavedQuote = 'has_saved_quote',
}

export enum MessageAutomationInsuranceTypesEnum {
    Car = 'car',
    Child = 'child',
    Pet = 'pet',
    Other = 'other',
}

export interface BasketItem {
    name: string;
    detail: string;
    price: string;
    type?: MessageAutomationInsuranceTypesEnum;
}

export interface CreateBasketEmailReminderRequest {
    email: string;
    marketingConsent: boolean;
    type: MessageAutomationEnum;
    basketUrl: string;
    name?: string;
    partnerName?: string;
    basket?: {
        products?: BasketItem[];
        total?: string;
    };
}

export interface UpdateBasketEmailReminderRequest {
    email: string;
    basket: {
        products: BasketItem[];
        total: string;
    };
}
