






import {ContentModelTypes} from '@penni/contentful-api';
import ResourceMixin from './mixin';
import ContentfulMixin from '../content/mixin';
import {Component, Prop, Mixins} from 'vue-property-decorator';
import {penniContentModule} from '../store';
import {prism} from '../analytics/plugin';

@Component
export default class LinkResource extends Mixins(ResourceMixin, ContentfulMixin) {
    public resourceType: ContentModelTypes.LINK = ContentModelTypes.LINK;

    @Prop({required: true, type: String}) public resourceId!: string;
    @Prop({required: false, type: Boolean, default: false}) public trackClick!: boolean;

    public created(): void {
        if (penniContentModule.checkout == null) {
            throw new Error('There is no checkout in the Content store');
        }
    }

    public click(elementText: string): void {
        if (this.trackClick) {
            prism?.client?.click(this.resourceId, elementText);
        }
    }

    public get resource(): {url: string; text: string} | string {
        return this.getLinkResource(this.resourceId, `missing resource: ${this.resourceId}`);
    }
}
