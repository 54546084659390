


















import {ContentModelTypes, MediaResourceEntry} from '@penni/contentful-api';
import {Component, Prop, Mixins} from 'vue-property-decorator';
import {penniContentModule} from '../store';
import ContentfulMixin from '../content/mixin';

@Component
export default class MediaResource extends Mixins(ContentfulMixin) {
    public resourceType: ContentModelTypes.MEDIA = ContentModelTypes.MEDIA;

    /**
     * @description
     * The id of the resource.
     */
    @Prop({required: true, type: String, default: ''}) public resourceId!: string;
    /**
     * @description
     * `width` is used if given, otherwise it will fallback to the values from the image it self.
     */
    @Prop({required: false, type: Number, default: undefined}) public width!: number;
    /**
     * @description
     * `height` is used if given, otherwise it will fallback to the values from the image it self.
     */
    @Prop({required: false, type: Number, default: undefined}) public height!: number;
    /**
     * @description
     * This is passed directly to `background-size`.
     */
    @Prop({required: false, type: String, default: 'auto'}) public size!: 'contain' | 'cover' | 'auto';
    /**
     * @description
     * Allows to pass in a resource directly for rendering, instead of looping through the Checkout entry resources.
     */
    @Prop({required: false, type: Object, default: null}) public resource!: MediaResourceEntry;
    /**
     * @description
     * Allows to pass parameters directly into the contentful URL in order to utilize the Contentful Image API.
     * @link
     * Image API documentation: https://www.contentful.com/developers/docs/references/images-api/.
     */
    @Prop({required: false, type: Object, default: undefined}) public mediaParams!: Record<string, string>;

    public created(): void {
        if (penniContentModule.checkout == null) {
            throw new Error('There is no checkout in the Content store');
        }
    }

    public get resourceMedia(): MediaResourceEntry | undefined {
        if (this.resource != null) {
            return this.resource;
        }
        return this.getMediaResource(this.resourceId);
    }

    public get resourceMediaUrl(): string {
        if (this.resourceMedia) {
            const mediaUrl = this.resourceMedia.value.fields.file.url;
            if (this.mediaParams != null) {
                const newParams = new URLSearchParams(this.mediaParams).toString();
                return `${mediaUrl}?${newParams}`;
            }
            return mediaUrl;
        }
        return '';
    }

    public get resourceMediaTitle(): string {
        if (this.resourceMedia) {
            return this.resourceMedia.value.fields.title;
        }
        return '';
    }

    public get resourceMediaSize(): {width?: number; height?: number} {
        return {
            width: this.resourceMedia?.value.fields.file.details.image?.width,
            height: this.resourceMedia?.value.fields.file.details.image?.height,
        };
    }
}
