import {PluginObject, VueConstructor} from 'vue';
import PenniAddressLookup from './AddressLookup.vue';

export * from './AddressLookup.vue';

export const PenniAddressLookupPlugin: PluginObject<null> = {
    install(Vue: VueConstructor): void {
        Vue.component('PenniAddressLookup', PenniAddressLookup);
    },
};
