


















import ContentfulMixin from '@/content/mixin';
import {Premium} from '@penni/generic-api';
import {Component, Mixins, Prop} from 'vue-property-decorator';
import {penniCheckoutModule, penniCommonModule} from '../store';
import {formatPrice} from './helpers';

@Component
export default class PenniPrice extends Mixins(ContentfulMixin) {
    @Prop({required: true, type: Number}) public readonly price!: number;
    @Prop({required: false, type: Boolean, default: false}) public readonly button!: string;

    public get dineroFormattedPrice(): string {
        return formatPrice(this.price, penniCommonModule.currency);
    }

    public get formattedPrice(): string | string[] {
        if (this.price == null) {
            return '';
        }
        return this.price.toString().split('.');
    }

    public get useDineroPrices(): boolean {
        return penniCommonModule.useDineroPrices;
    }

    public get didThrow(): boolean {
        return penniCommonModule.didThrow;
    }

    public get prefix(): boolean {
        return this.getTextResource('price-prefix', '') !== '';
    }

    public get selectedFrequency(): keyof Premium {
        return penniCheckoutModule.selectedPremiumType;
    }
}
