import {prism} from '../analytics/plugin';
import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';
import {penniCheckoutModule} from '../store';

export const STEP_MODULE_NAMESPACE = 'stepModule';

export interface Step {
    header: string;
    step: number;
    dirty: boolean;
    partialSummary: boolean;
}

@Module({
    name: STEP_MODULE_NAMESPACE,
    stateFactory: true,
    namespaced: true,
})
export class StepModule extends VuexModule {
    public completionPct: number = 50;
    public currentStep: number = 1;
    public flowCompleted: boolean = false;
    public steps: Step[] = [];
    public shouldAutoProceed: boolean = false;

    @Mutation
    public setStep(payload: number): void {
        this.currentStep = payload;
    }

    @Mutation
    public setFlowCompleted(payload: boolean): void {
        this.flowCompleted = payload;
    }

    @Mutation
    public setCompletionPct(payload: number): void {
        this.completionPct = payload;
    }

    @Mutation
    public updateStep(payload: Step): void {
        const index = this.steps.findIndex((item: Step) => item.step === payload.step);
        const step = this.steps[index];
        if (step == null) {
            return;
        }
        step.dirty = payload.dirty;
        step.header = payload.header;
        step.partialSummary = payload.partialSummary;
    }

    @Mutation
    public addStep(payload: Step): void {
        if (!this.steps.find((item: Step) => item.step === payload.step)) {
            this.steps.push(payload);
        }
    }

    @Mutation
    public resetState(): void {
        this.completionPct = 50;
        this.currentStep = 1;
        this.flowCompleted = false;
        this.steps = [];
    }

    @Mutation
    public setAutoProceed(payload: boolean): void {
        this.shouldAutoProceed = payload;
    }

    public get getStep(): (step: number) => Step | undefined {
        return (step: number): Step | undefined => this.steps.find((item: Step) => item.step === step);
    }

    @Action
    public async nextStep(): Promise<void> {
        if (
            this.flowCompleted ||
            (this.context.rootState.commonModule != null && this.context.rootState.commonModule.didThrow)
        ) {
            return;
        }

        const nextStep = this.currentStep + 1;
        const flowCompleted = this.currentStep === this.steps.length;

        if (flowCompleted) {
            prism?.client?.flowCompleted('purchase', {
                products: penniCheckoutModule.basketResponse?.products,
            });
            prism?.client?.transmitBuffer();
        } else {
            prism?.client?.stepLoaded(nextStep);
        }

        this.context.commit('setStep', nextStep);
        this.context.commit('setCompletionPct', Math.round((50 / this.steps.length) * (this.currentStep - 1)) + 50);
        this.context.commit('setFlowCompleted', flowCompleted);
    }

    @Action
    public async previousStep(): Promise<void> {
        if (this.flowCompleted || this.currentStep === 1) {
            return;
        }

        const previousStep = this.currentStep - 1;
        this.context.commit('setStep', previousStep);
        this.context.commit('setCompletionPct', Math.round((50 / this.steps.length) * (this.currentStep - 1)) + 50);
    }
}
