




import ContentfulMixin from '../../content/mixin';
import {Component, Mixins, Prop} from 'vue-property-decorator';
import {penniCommonModule} from '../../store';

@Component
export default class PenniModalTrigger extends Mixins(ContentfulMixin) {
    @Prop({required: true, type: String, default: ''}) name!: string;
    @Prop({required: true, type: String, default: ''}) text!: string;

    public toggleModal(): void {
        penniCommonModule.toggleModal({name: this.name, active: true});
    }

    /**
     * @description
     * Search for a resource or show the text given directly.
     * If the modal trigger is from CF [modal:$id:$text] it should not be a CF resource,
     * but if being used in the library it should be possible to pass in a CF resource so that we can control it from CF.
     */
    public get resourceOrText(): string {
        return this.getTextResource(this.text, this.text);
    }
}
