










import GenericError from '@/views/GenericError.vue';
import {penniCheckoutModule, penniCommonModule, penniContentModule, penniProductModule} from '../store';
import {Component, Vue} from 'vue-property-decorator';
import {CustomerJourneyCheckoutOptions, CustomerJourneyOptions} from '@penni/contentful-api';
import {redirectAfterPurchase} from './helpers';

@Component({components: {GenericError}})
export default class PaymentError extends Vue {
    public async mounted(): Promise<void> {
        if (this.shouldCancelQuote) {
            penniCommonModule.setLoading(true);
            await penniCheckoutModule.cancelQuote({
                transactionId:
                    (this.$route.params.transactionId || penniCheckoutModule.quoteResponse?.transactionId) ?? '',
                partnerId: (penniCheckoutModule.basketResponse?.products[0].request.products[0] as Record<
                    string,
                    string
                >).partnerId,
            });
            penniCommonModule.setLoading(false);
        }

        redirectAfterPurchase(
            this.options,
            {
                product: penniProductModule.product,
                calculationRequest: penniCheckoutModule.calculationRequest,
                basketResponse: penniCheckoutModule.basketResponse,
            },
            {status: 'error', id: penniCheckoutModule.quoteResponse?.transactionId}
        );
    }

    public get options(): CustomerJourneyOptions<CustomerJourneyCheckoutOptions> {
        return penniContentModule.options;
    }

    public get shouldCancelQuote(): boolean {
        return this.options.features?.cancelQuote?.enabled != null;
    }
}
