


























import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import Vue from 'vue';
import {Datepicker} from 'buefy';
import InputMixin from '../mixin';
import {penniInputModule} from '../../store';
import {getLocaleDateString} from '@/utils/dateUtils';

Vue.use(Datepicker);

@Component
export default class PenniDatepicker extends Mixins(InputMixin) {
    @Prop({required: false, type: Boolean, default: () => false}) public range!: boolean;
    // focuses a date but does not select it
    @Prop({required: false, type: Date}) public focusedDate!: Date;
    // selects a date
    @Prop({required: false, type: Date}) public preselectedDate!: Date | Date[];
    @Prop({required: false, type: Date}) public minDate!: Date;
    @Prop({required: false, type: Date}) public maxDate!: Date;
    /**
     * @description
     * Add a range of years that changes the visible selection of years in the years dropdown.
     * @example [-100, 100]
     */
    @Prop({required: false, type: Array, default: () => [-100, 100]}) public yearsRange!: number[];
    @Prop({required: false, type: String, default: () => 'en-US'}) public locale!: string;
    @Prop({required: false, type: Number, default: 0}) public firstWeekDay!: number;

    public mounted(): void {
        penniInputModule.setInput({
            name: this.name,
            req: this.req,
            type: 'text',
            value: '',
            valid: false,
            form: this.form,
            required: this.required ?? false,
            summary: '',
        });

        this.setPreselectedDate();
    }

    public dateFormatter(date: Date | Date[]): string {
        if (Array.isArray(date)) {
            const targetDates = date.map((d: Date) => this.dateFormatter(d));
            return targetDates.join(' - ');
        }

        return getLocaleDateString(date, this.locale);
    }

    public stringToDate(value: string): Date[] {
        const dates = value.split(' - ');
        if (dates.length === 0 || dates[0] === '') {
            return [];
        }
        return dates.map((date: string): Date => new Date(date));
    }

    /**
     * Return the ISO 8601 date (not date-time).
     * E.g. `2020-10-20`
     */
    public dateToISO(date: Date | Date[]): string {
        if (Array.isArray(date)) {
            const targetDates = date.map((d: Date) => this.dateToISO(d));
            return targetDates.join(' - ');
        }

        const month = date.getMonth() + 1;
        const date1 = date.getDate();
        return `${date.getFullYear()}-${month < 10 ? '0' + month : month}-${date1 < 10 ? '0' + date1 : date1}`;
    }

    public setPreselectedDate(): void {
        if (
            this.preselectedDate == null ||
            this.input == null ||
            this.input.value === this.dateFormatter(this.preselectedDate)
        ) {
            return;
        }

        const localizedDateString = this.dateFormatter(this.preselectedDate);
        penniInputModule.updateInput({
            name: this.name,
            value: this.dateToISO(this.preselectedDate),
            summary: localizedDateString,
            required: this.required,
        });
    }

    public isValid(): boolean {
        if (this.input?.value == null) {
            return false;
        }

        if (this.isNative()) {
            const value = this.stringToDate(this.input.value as string);
            if (
                (this.minDate != null && value[0] < this.minDate) ||
                (this.maxDate != null && value[0] > this.maxDate)
            ) {
                return false;
            }
        }

        return this.input.value !== '';
    }

    /**
     * Step summary wasn't updated correctly when returning with resumable basket
     * so we are watching the dateValue in order to ensure the step summary value is correct.
     */
    @Watch('dateValue')
    public updateSummary(newDateValue: Date | Date[], oldDateValue: Date | Date[]): void {
        const localizedDateString = this.dateFormatter(newDateValue);
        if (this.input?.summary === localizedDateString || newDateValue === []) {
            return;
        }
        const newDate = this.dateToISO(newDateValue);
        const oldDate = this.dateToISO(oldDateValue);
        penniInputModule.updateInput({
            name: this.name,
            value: newDate,
            summary: localizedDateString,
            required: this.required,
        });

        if (newDate !== oldDate) {
            this.valueChange(newDate);
        }
    }

    @Watch('validity')
    public updateInputValidity(valid: boolean): void {
        if (this.input == null) {
            return;
        }
        penniInputModule.updateInput({...this.input, valid: valid});
    }

    public get validity(): boolean {
        return this.isValid();
    }

    public get dateValue(): Date | Date[] {
        return this.input && this.input.value != null && typeof this.input.value === 'string'
            ? this.stringToDate(this.input.value)
            : [];
    }

    public isNative(): boolean {
        return document.querySelectorAll('input[type="date"]').length > 0;
    }

    public onInput(value: Date | Date[]): void {
        if (value == null) {
            penniInputModule.updateInput({
                name: this.name,
                value: '',
                summary: '',
                required: this.required,
            });
            return;
        }

        const localizedDateString = this.dateFormatter(value);
        penniInputModule.updateInput({
            name: this.name,
            value: this.dateToISO(value),
            summary: localizedDateString,
            required: this.required,
        });
        this.valueChange(this.dateToISO(value));
    }
}
