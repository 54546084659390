import Vue, {VueConstructor} from 'vue';
import PenniConditional from './form/Conditional.vue';
import PenniControl from './form/Control.vue';
import PenniCheckout from './PenniCheckout.vue';
import TextResource from './resources/TextResource.vue';
import LinkResource from './resources/LinkResource.vue';
import RichTextResource from './resources/RichTextResource.vue';
import MediaResource from './resources/MediaResource.vue';
import {Intersect} from './directives/intersect';
import axios from 'axios';

Vue.config.devtools = true;
Vue.config.productionTip = false;

export const penniHttpClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
});

export const CheckoutPlugin = {
    install(Vue: VueConstructor): void {
        Vue.component('PenniCheckout', PenniCheckout);
        Vue.component('TextResource', TextResource);
        Vue.component('LinkResource', LinkResource);
        Vue.component('RichTextResource', RichTextResource);
        Vue.component('MediaResource', MediaResource);
        Vue.component('PenniConditional', PenniConditional);
        Vue.component('PenniControl', PenniControl);

        Vue.directive('intersect', Intersect);
    },
};
