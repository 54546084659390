









































import ContentfulMixin from '../../content/mixin';
import {Component, Prop, Mixins} from 'vue-property-decorator';
import {ContentModelTypes} from '@penni/contentful-api';

@Component
export default class Hero extends Mixins(ContentfulMixin) {
    public resourceType: ContentModelTypes.MEDIA = ContentModelTypes.MEDIA;
    public missingResourceText: string[] = [];

    @Prop({required: true, type: String}) public readonly id!: string;
    @Prop({required: false, type: String}) public readonly textColor?: string;
    @Prop({required: false, type: Boolean, default: true}) public readonly enableButton?: string;

    /**
     * @description
     * Get the image url from Contentful.
     */
    public get imageUrl(): string | undefined {
        const imageResource = this.getMediaResource(`lp-hero-image-${this.id}`);
        return imageResource == null ? undefined : imageResource.value.fields.file.url;
    }
}
