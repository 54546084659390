import {penniHttpClient} from '@/plugin';
import {NavigationGuard, NavigationGuardNext, Route} from 'vue-router';
import {prism} from '../analytics/plugin';
import {penniCheckoutModule, penniCommonModule, penniContentModule} from '../store';
import type {EntryCollection, Entry} from 'contentful';
import {CheckoutContentModel} from '@penni/contentful-api';

export function basketRouteBeforeEnter(): NavigationGuard {
    return async (to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
        const fetchContent = sessionStorage.getItem('penni-local-content') !== 'true';
        const fetchBasket = to.meta?.fetchBasket !== false;
        const setupPrism = to.meta?.prism == null || to.meta?.prism;
        const headlessCheckoutId = to.meta?.checkoutId;
        const params = to.params as Record<'basketId' | 'partner' | 'product', string>;
        if (fetchBasket) {
            await penniCheckoutModule.fetchBasket({basketId: params.basketId});

            let partnerId = '';
            if (
                penniCheckoutModule.basketResponse != null &&
                penniCheckoutModule.basketResponse.products.length > 0 &&
                penniCheckoutModule.basketResponse.products[0].request.products.length > 0
            ) {
                partnerId = (penniCheckoutModule.basketResponse.products[0].request.products[0] as Record<
                    string,
                    string
                >).partnerId;
            }

            // Axios changed their internal types of the default headers, so there is a type mismatch, hence the 'as unknown as Record<string, unknown>'
            // https://github.com/axios/axios/issues/4193
            ((penniHttpClient.defaults.headers as unknown) as Record<string, unknown>) = {
                ...penniHttpClient.defaults.headers,
                'PENNI-CHECKOUT-ID': penniCheckoutModule.basketResponse?.sources?.checkoutId,
                'PENNI-WIDGET-ID': penniCheckoutModule.basketResponse?.sources?.widgetId,
                'PENNI-PARTNER-ID': partnerId,
            };

            // if the basket does not exist, we fail the flow.
            if (penniCheckoutModule.basketRetrievalFailed || penniCheckoutModule.basketResponse == null) {
                const error = new Error('Basket does not exist or has expired');
                penniCommonModule.setDidThrow({error, didThrow: true});
                to.query['genericError'] = 'true';
                return next();
            }
        }

        // if the basket contains analytics identifiers,
        // we configure our deviceIdentifier to use those identifiers
        if (
            setupPrism &&
            penniCheckoutModule.basketResponse?.analytics.clientId != null &&
            penniCheckoutModule.basketResponse?.analytics.sessionId != null
        ) {
            prism?.configureDeviceIdentifier({
                clientId: penniCheckoutModule.basketResponse.analytics.clientId,
                sessionId: penniCheckoutModule.basketResponse.analytics.sessionId,
            });
        }

        if (fetchContent) {
            try {
                const checkoutId = penniCheckoutModule.basketResponse?.sources?.checkoutId ?? headlessCheckoutId;
                const searchParams = {};
                const product = params.product != null ? params.product : to.meta?.product;

                if (checkoutId) {
                    Object.assign(searchParams, {'sys.id': checkoutId});
                }

                if (params.partner && product) {
                    Object.assign(searchParams, {
                        content_type: 'checkout',
                        'fields.partnerId': params.partner,
                        'fields.productId': product,
                        'fields.environmentVariant': process.env.VUE_APP_CF_ENTRY_ENVIRONMENT_VARIANT,
                    });
                }

                await penniContentModule.fetchCheckout({
                    searchParams,
                    // we base our experiment seed in the prism device identifier
                    experimentSeed: prism?.deviceIdentifier.getClientId() ?? '',
                });

                // configure prism to use the checkout and experiment id's for subsequent events
                // since configuration happens after plugins have loaded and before components have mounted,
                // we should not have sent any analytic events.
                if (setupPrism && penniContentModule.checkout != null) {
                    prism?.configure(penniContentModule.checkout);
                }
            } catch {
                const error = new Error('Could not fetch checkout');
                // how do we handle potential errors when the content cannot be fetched?
                // we cannot show the generic error page, as we don't have content to show?
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                penniCommonModule.setDidThrow({error, didThrow: true});
                to.query['genericError'] = 'true';
                return next();
            }
        } else {
            const contentfulConfig = process.env.VUE_APP_CONTENT_CONFIG as string;
            const allEntries = JSON.parse(contentfulConfig) as EntryCollection<CheckoutContentModel>;
            const product = params.product != null ? params.product : to.meta?.product;
            const customer = to.meta?.customer ?? '';

            /*
             * we determine what contentful file to use by finding contentful entries
             * with a id containing the partner and customer route parameters
             */
            const checkoutEntries = allEntries.items.filter(
                (item: Entry<CheckoutContentModel>) =>
                    item.fields.id.includes(product ?? '') && item.fields.id.includes(customer)
            );
            console.info('using local content');

            const sessionCheckoutId = sessionStorage.getItem('penni-local-checkout-id');
            let checkoutEntryId = checkoutEntries[0].sys.id;

            if (sessionCheckoutId != null && sessionCheckoutId !== 'not-found') {
                console.log('existing checkout found in session');
                checkoutEntryId = sessionCheckoutId;
            }

            await penniContentModule.fetchCheckout({
                checkoutId: checkoutEntryId,
                experimentSeed: '',
                contentfulData: contentfulConfig,
            });
        }
        next();
    };
}
