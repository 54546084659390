import {PluginObject, VueConstructor} from 'vue';
import PenniAutocomplete from './Autocomplete.vue';

export * from './Autocomplete.vue';

export const PenniAutocompletePlugin: PluginObject<null> = {
    install(Vue: VueConstructor): void {
        Vue.component('PenniAutocomplete', PenniAutocomplete);
    },
};
