









import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {penniStepModule} from '../store';

@Component
export default class StickySummary extends Vue {
    public get completionPct(): number {
        return penniStepModule.completionPct;
    }
}
