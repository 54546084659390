










import ContentfulMixin from '../content/mixin';
import {Component} from 'vue-property-decorator';
import {Mixins} from 'vue-property-decorator';
import {penniContentModule} from '@/store';

@Component
export default class Logos extends Mixins(ContentfulMixin) {
    public get showPoweredBy(): boolean {
        return penniContentModule.checkout?.options.layout?.header?.showPoweredBy ?? true;
    }

    public get poweredByResource(): string {
        return this.getTextResource('header-powered-by', 'Powered by');
    }
}
