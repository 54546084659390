

































import {Component, Mixins, Vue, Prop} from 'vue-property-decorator';
import InputMixin from '../mixin';
import {penniInputModule} from '../../store';
import {Field, Input} from 'buefy';

Vue.use(Field);
Vue.use(Input);

@Component
export default class PenniText extends Mixins(InputMixin) {
    /**
     * @description
     * Triggers when value is changed. Returns current input value.
     * @param
     * string Current input text value.
     */
    @Prop({required: false, type: Function, default: null}) public readonly inputChange?: (
        value: string
    ) => void | null;
    /**
     * @description
     * Preset value that input will start with when mounted. Input will be empty if undefined or null
     */
    @Prop({required: false, type: String}) presetValue!: string;

    public mounted(): void {
        penniInputModule.setInput({
            name: this.name,
            req: this.req,
            form: this.form,
            type: 'text',
            value: this.presetValue || '',
            valid: this.isValid(),
            required: this.required ?? false,
            label: this.label,
            pattern: this.pattern,
            validationMessage: this.contentfulValidationMsg,
            placeholder: this.placeholder,
            summary: this.presetValue || '',
        });
    }

    public get model(): string {
        if (this.input == null || typeof this.input.value !== 'string') {
            return '';
        }
        return this.input.value;
    }

    public set model(value: string) {
        penniInputModule.updateInput({
            name: this.name,
            value,
            summary: value,
            required: this.required,
        });
        this.valueChange(value);
    }
}
