






































import {Component, Vue, Prop} from 'vue-property-decorator';
import {OrderProduct} from '../store/models';
import PenniPrice from './Price.vue';

@Component({components: {PenniPrice}})
export default class SummaryOrderItem extends Vue {
    @Prop({
        required: true,
        type: Object,
        default: () => ({}),
    })
    public readonly product!: OrderProduct;

    @Prop({
        required: true,
        type: Boolean,
        default: false,
    })
    public readonly expanded!: boolean;

    public get hasCoverages(): boolean {
        return this.product != null && this.product.coverages != null && this.product.coverages.length > 0;
    }

    public get hasParameters(): boolean {
        return (
            this.product != null && this.product.parameters != null && Object.keys(this.product.parameters).length > 0
        );
    }

    public get hasDetails(): boolean {
        return this.hasCoverages || this.hasParameters;
    }
}
