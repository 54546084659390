import {Module, VuexModule, Mutation, Action} from 'vuex-module-decorators';
import {Input, InputUpdate, InputValues} from './models';
import _get from 'lodash/get';
import {BasketCalculation} from '@penni/generic-api';
import {FormValidation} from '@/form/formValidation';
import {InputSummary} from './inputSummary';
import {UnknownRecord} from '@penni/generic-api/build/utility';

export const INPUT_MODULE_NAMESPACE = 'inputModule';

@Module({
    name: INPUT_MODULE_NAMESPACE,
    stateFactory: true,
    namespaced: true,
})
export class InputModule extends VuexModule {
    public inputs: Input[] = [];
    public amountOfInputsMappedFromBasket: number = 0;

    /**
     * @description
     * Sets an input only if it does not exist in the input object
     */
    @Mutation
    public setInput(input: Input): void {
        const stateInput = this.inputs.find((item: Input) => item.name === input.name);
        if (stateInput == null) {
            input.dirty = false;
            this.inputs.push(input);
        }
    }

    /**
     * @description
     * Updates an input that already exists in the input object
     */
    @Mutation
    public updateInput(input: InputUpdate): void {
        const stateInput = this.inputs.find((item: Input) => item.name === input.name);
        if (stateInput == null) {
            return;
        }

        stateInput.value = input.value;
        stateInput.valid = input.valid;
        stateInput.dirty = input.dirty ?? false;
        stateInput.summary = input.summary;
        stateInput.required = input.required ?? false;

        if (input.overrideValidation === true) {
            stateInput.overrideValidation = true;
        }
    }

    @Mutation
    public setAmountOfInputsMappedFromBasket(amount: number): void {
        this.amountOfInputsMappedFromBasket = amount;
    }

    @Mutation
    public updateInputFromBasket(payload: {input: Input; basketInputValue: unknown}): void {
        payload.input.dirty = true;

        new InputSummary(payload.input, payload.basketInputValue).set();

        switch (payload.input.type) {
            case 'number':
                payload.input.value = payload.basketInputValue as InputValues;
                break;

            case 'text':
                payload.input.value =
                    typeof payload.basketInputValue === 'object'
                        ? (payload.basketInputValue as UnknownRecord)
                        : `${payload.basketInputValue}`;
                break;

            default:
                payload.input.value = `${payload.basketInputValue}`;
        }
        payload.input.valid = new FormValidation(payload.input).isValid();
    }

    @Mutation
    public deleteInput(inputName: string): void {
        const inputIndex = this.inputs.findIndex((item: Input) => item.name === inputName);
        if (inputIndex == -1) {
            return;
        }
        this.inputs.splice(inputIndex, 1);
    }

    @Action
    public updateInputsFromBasket(basket: BasketCalculation): void {
        let amountOfInputsMapped = 0;

        this.inputs.forEach((stateInput: Input) => {
            if (stateInput == null || stateInput.req == null || stateInput.req === '') {
                return;
            }
            const basketInputValue = this.getInputBasketValue(basket, stateInput.req);

            // if the input.req value is undefined (untouched), it is not updated
            if (basketInputValue == null) {
                return;
            }

            // update mapped counter, this is used to indicate changes
            amountOfInputsMapped = amountOfInputsMapped + 1;

            this.context.commit('updateInputFromBasket', {input: stateInput, basketInputValue});
        });

        this.context.commit('setAmountOfInputsMappedFromBasket', amountOfInputsMapped);
    }

    public get getInputBasketValue(): (basket: BasketCalculation, req: string) => InputValues {
        return (basket: BasketCalculation, req: string): InputValues => {
            // search for values in both the basket request and response
            const haystack = {...basket.request, ...basket.response};
            const basketInputValue = _get(haystack, req, undefined);

            return basketInputValue;
        };
    }

    public get getInput(): (name: string) => Input | undefined {
        return (name: string): Input | undefined => this.inputs.find((item: Input) => item.name === name);
    }

    public get getInputValidity(): (input: Input | undefined) => boolean | null {
        return (input: Input | undefined): boolean | null => {
            if (input == null || input.valid == null) {
                return null;
            }
            return input.valid;
        };
    }

    public get getInputsByForm(): (form: string) => Input[] | undefined {
        return (form: string): Input[] | undefined => this.inputs.filter((item: Input) => item.form === form);
    }

    public get getFormValidity(): (form: string) => boolean {
        return (form: string): boolean => {
            const inputs = this.getInputsByForm(form);
            if (inputs == null || inputs.length === 0) {
                return false;
            }
            const requiredInputs = inputs.filter((input: Input) => input.required);

            return requiredInputs.every((item: Input) => item.valid);
        };
    }

    public get getInputsAsParameters(): Record<string, InputValues> {
        const params: Record<string, InputValues> = {};
        this.inputs.forEach((input: Input) => {
            params[input.name] = input.value;
        });
        return params;
    }

    @Mutation
    public resetState(): void {
        this.inputs = [];
    }
}
