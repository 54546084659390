































import ContentfulMixin from '../content/mixin';
import {Component, Mixins} from 'vue-property-decorator';
import {prism} from '../analytics/plugin';
import {penniContentModule} from '@/store';

type SupportButton = {
    text: string;
    link: string;
    icon: string;
};

@Component
export default class SupportArea extends Mixins(ContentfulMixin) {
    public windowWidth: number = window.innerWidth;

    public mounted(): void {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    }

    public onChange(): void {
        const text = (this.$refs.trigger as Vue).$el.textContent ?? 'support-area-modal-text';
        prism?.client?.click('support-area-trigger', text);
    }

    public onOptionClick(button: SupportButton): void {
        prism?.client?.click('support-area-option', button.text);
        window.location.href = button.link;
    }

    public get supportButtons(): SupportButton[] {
        const types = ['phone', 'email'];

        return (
            types
                .map((type) => ({
                    text: this.getTextResource(`support-${type}`, 'null'),
                    link: this.getTextResource(`support-${type}-link`, 'null'),
                    icon: type,
                }))
                // Only show button if it has both text and link resources defined.
                .filter((x) => x.text !== 'null' && x.link !== 'null')
        );
    }

    public get dropdownPosition(): string {
        return this.windowWidth > 768 ? 'is-bottom-left' : 'is-top-left';
    }

    /**
     * The Support Area will be turned on by default,
     * but can be disabled via the feature flag of a checkout.
     */
    public get enableSupportArea(): boolean {
        return penniContentModule.checkout?.options.features?.supportArea?.enabled ?? true;
    }
}
