



































import ContentfulMixin from '../../content/mixin';
import {Component, Mixins, Prop} from 'vue-property-decorator';
import {ContentModelTypes} from '@penni/contentful-api';

@Component
export default class ImageWithText extends Mixins(ContentfulMixin) {
    public resourceType: ContentModelTypes.MEDIA = ContentModelTypes.MEDIA;
    public missingResourceText: string[] = [];
    public mediaParams: Record<string, string> = {fit: 'fill', h: '700', w: '576'};

    @Prop({required: true, type: String}) public readonly id!: string;
    @Prop({required: false, type: Boolean, default: false}) public reverseImage!: boolean;
    @Prop({required: false, type: Boolean, default: false}) public enableButton!: boolean;
}
