



















import {getWidgetLoader} from '@/utils';
import {Component, Vue, Prop} from 'vue-property-decorator';
import get from 'lodash.get';

export type WidgetIds = {
    default: string;
    ci?: string;
    staging1?: string;
    staging2?: string;
    demo?: string;
    master1?: string;
};

@Component
export default class Widget extends Vue {
    public errorText: string = '';
    @Prop({required: true, type: String}) public readonly id!: string;
    @Prop({required: true, type: Object}) public readonly widgetIds!: WidgetIds;

    public mounted(): void {
        if (this.widgetIds == null || this.widgetIds.default == null) {
            this.errorText = this.widgetIds == null ? 'Missing widgetId' : 'No default widgetId';
            console.error(this.errorText);
        } else {
            /**
             * Adding the Widget script to the page.
             */
            const widgetScript: HTMLScriptElement = document.createElement('script');
            widgetScript.async = true;
            widgetScript.defer = true;
            widgetScript.src = getWidgetLoader();
            document.head.appendChild(widgetScript);
        }
    }

    public get environment(): string {
        return process.env.VUE_APP_CF_ENVIRONMENT;
    }

    public get widgetId(): string {
        if (this.widgetIds != null) {
            return get(this.widgetIds, process.env.VUE_APP_CF_ENTRY_ENVIRONMENT_VARIANT, this.widgetIds.default);
        }
        return '';
    }
}
