



























































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import * as Api from '../types';
import * as GenericApi from '@penni/generic-api';
import {
    penniInputModule,
    penniProductModule,
    penniCheckoutModule,
    penniCommonModule,
    penniContentModule,
} from '@/store';
import {Coverage, OptionalCoverage, OptionItem} from '@penni/generic-api';
import {getResource, ContentModelTypes} from '@penni/contentful-api';
import cloneDeep from 'lodash.clonedeep';
import {MessageAutomationInsuranceTypesEnum} from '@/index';
import {UnknownRecord} from '@penni/generic-api/build/utility';

@Component
export default class CardView extends Vue {
    public premium: number | undefined = undefined;
    public selectedVariationId: string | undefined = undefined;
    public filteredCoverages: Api.TrygCardApiCoverage[] | undefined = undefined;

    public async created(): Promise<void> {
        penniCommonModule.setAllowSendToEmail(false);
        penniCheckoutModule.setSelectedPremiumType('oneTimePayment');
        this.setOrderSummary();
    }

    public get product() {
        return penniProductModule.product;
    }

    @Watch('product')
    public updateAfterGettingProduct(): void {
        this.setOrderSummary();
    }

    private setOrderSummary(): void {
        this.selectedVariationId = penniCheckoutModule.basketSelectedVariation?.id;
        const basketCalculationRequest = penniCheckoutModule.basketResponse?.products[0].request
            .products[0] as Api.CardCalculationRequest;

        const optionalCoverageIds = basketCalculationRequest.optionalCoverages
            .filter((optionalCoverage: OptionalCoverage<UnknownRecord>) =>
                (optionalCoverage.parameters.selectedVariationIds as string[]).find(
                    (variationId: string) => this.selectedVariationId === variationId
                )
            )
            .map((c) => c.id);

        this.premium = penniCheckoutModule.calculationResponse?.productPrices[0].variations.find(
            (v) => v.id === this.selectedVariationId
        )?.premium.oneTimePayment;

        const variationCoverages = penniProductModule.variations.find((v) => v.id === this.selectedVariationId)
            ?.coverages as Api.TrygCardApiCoverage[];

        this.filteredCoverages = variationCoverages?.filter(
            (coverage) =>
                optionalCoverageIds.find((coverageId) => coverage.id === coverageId) != null ||
                !coverage.options?.optional
        );

        const variantTitle =
            penniContentModule.checkout == null
                ? ''
                : getResource(
                      `${penniContentModule.checkout.type}-item-summary-title`,
                      ContentModelTypes.TEXT,
                      penniContentModule.checkout
                  )?.value;

        const variantSubtitle =
            penniContentModule.checkout == null
                ? ''
                : getResource(
                      `${penniContentModule.checkout.type}-item-summary-subtitle`,
                      ContentModelTypes.TEXT,
                      penniContentModule.checkout
                  )?.value;

        penniCheckoutModule.setOrderSummary([
            {
                type: MessageAutomationInsuranceTypesEnum.Other,
                title: variantTitle ?? 'Rejseforsikring',
                price: this.premium,
                subtitle: variantSubtitle ?? 'Dækning for din rejse',
                coverages: this.filteredCoverages,
            },
        ]);
    }

    public marketingConsent(): boolean {
        const value = penniInputModule.getInput('marketingConsent')?.value;
        return value === 'true' ? true : false;
    }

    public get options(): Api.ApiProductOptions | null {
        return penniProductModule.options;
    }

    public get transferDatesOptionText(): string {
        return penniContentModule.checkout == null
            ? ''
            : getResource(
                  `${penniContentModule.checkout.type}-text-existing-insurance-cancel-first-option`,
                  ContentModelTypes.TEXT,
                  penniContentModule.checkout
              )?.value ?? 'text-not-found-existing-insurance-cancel-first-option';
    }

    public get transferDates(): OptionItem[] | undefined {
        const formatOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'long', day: 'numeric'};
        return this.options && this.options.transferDate
            ? cloneDeep(this.options.transferDate).map((option: GenericApi.OptionItem, index: number) => {
                  const text: string = new Date(option.code).toLocaleDateString('da-DK', formatOptions);
                  return {
                      code: option.code,
                      text: index === 0 ? `${this.transferDatesOptionText} (${text})` : text,
                      default: option.default,
                  };
              })
            : undefined;
    }

    public get basketWasMappedToInput(): boolean {
        return penniCheckoutModule.basketMappedToInputs;
    }

    public async createQuoteOnFirstStep(): Promise<void> {
        if (!this.insuredPeopleMoreThan1) {
            this.createQuote();
        }
    }
    public async createQuote(): Promise<void> {
        if (this.selectedVariationId == null) {
            throw new Error('selectedVariationId is undefined');
        }
        const params = penniInputModule.getInputsAsParameters;
        const basketProduct = penniCheckoutModule.basketResponse?.products[0].request
            .products[0] as Api.CardCalculationRequest;
        const insuredObject = {
            premium: this.premium,
            currencyCode: basketProduct.currencyCode,
            ...basketProduct.insuredObject,
        };

        const selectedCoverages = this.filteredCoverages?.map((filteredCoverage) => filteredCoverage.id);

        if (penniProductModule.product == null) {
            console.log('why isnt the product fetched?');
            return;
        }

        if (this.insuredPeopleMoreThan1) {
            basketProduct.insuredObject.travellers = this.insuredPeople;
        }

        const request = {
            customer: {
                name: {
                    firstName: `${params['traveller-1-firstname']}`,
                    lastName: `${params['traveller-1-lastname']}`,
                },
                ssn: `${params['socialSecurityNumber']}`,
                contact: {
                    email: `${params['email']}`,
                    phone: `${params['phone']}`,
                },
                address: `${params['address']}`,
                consent: {
                    all: true,
                },
            },
            products: [
                {
                    productId: basketProduct.productId,
                    productVariationId: this.selectedVariationId,
                    productSelectedCoverages: selectedCoverages,
                    partnerId: basketProduct.partnerId,
                    insuredObject,
                },
            ],
            payment: {
                paymentFrequency: {
                    code: 'oneTimePayment',
                },
            },
            completeBasket: false,
        };

        penniCommonModule.setLoading(true);

        try {
            await penniCheckoutModule.postQuote(request);
        } catch (error) {
            throw error;
        } finally {
            penniCommonModule.setLoading(false);
        }
    }

    public insuredPersonDetails(index: number): Api.Travellers {
        const params = penniInputModule.getInputsAsParameters;
        const details: Api.Travellers = {
            index,
            name: {
                firstName: `${params[`traveller-${index}-firstname`]}`,
                lastName: `${params[`traveller-${index}-lastname`]}`,
            },
        };

        return details;
    }

    public get insuredPeopleMoreThan1(): boolean {
        const insuredPeopleCount = (penniCheckoutModule.basketResponse?.products[0].request
            .products[0] as Api.CardCalculationRequest).insuredObject.numberOfTravellers;
        return insuredPeopleCount != undefined && insuredPeopleCount > 1;
    }

    public get insuredPeople(): Api.Travellers[] {
        const insuredPeople: Api.Travellers[] = [];
        const insuredPeopleCount = (penniCheckoutModule.basketResponse?.products[0].request
            .products[0] as Api.CardCalculationRequest).insuredObject.numberOfTravellers;
        if (insuredPeopleCount == undefined) {
            return insuredPeople;
        }

        for (let index = 1; index <= insuredPeopleCount; index++) {
            insuredPeople.push(this.insuredPersonDetails(index));
        }
        return insuredPeople;
    }

    private get getStepNumber(): number {
        if (this.insuredPeopleMoreThan1) {
            return 3;
        }
        return 2;
    }
}
