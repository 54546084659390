







































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import InputMixin from '../mixin';
import {penniInputModule} from '../../store';
import {OptionItem} from '@penni/generic-api';
import {Autocomplete} from 'buefy';

Vue.use(Autocomplete);

@Component
export default class PenniAutocomplete extends mixins(InputMixin) {
    public model: string = '';
    public typingTimer: number | undefined;
    /**
     * @description
     * currentItems is the data variable for the items prop as props cannot be mutated
     * and we need to update it if asynchronous updates need to be done in the getAsyncItems function.
     */
    public currentItems: OptionItem[] = [];

    @Prop({required: true, type: Array, default: () => []}) items!: OptionItem[];
    @Prop({required: false, type: Function}) filteringFunction!: (items: OptionItem[]) => OptionItem[];
    /**
     * @description
     * Prop for function that will be run every time user types in input. Returns a string of text that the input contains.
     */
    @Prop({required: false, type: Function, default: null}) public readonly getAsyncItems?: (
        text: string
    ) => Promise<OptionItem[]> | null;
    /**
     * @description
     * Prop for enabling typing free text in the Autocomplete component.
     * The component will not validate with an empty value but will accept value not present in the list of options.
     */
    @Prop({required: false, type: Boolean, default: false}) allowFreeText!: boolean;

    public created(): void {
        penniInputModule.setInput({
            name: this.name,
            req: this.req,
            form: this.form,
            type: 'autocomplete',
            value: '',
            valid: false,
            required: this.required ?? false,
            label: this.label,
            pattern: this.pattern,
            validationMessage: this.contentfulValidationMsg,
            placeholder: this.placeholder,
            summary: '',
        });
    }

    @Watch('items', {immediate: true})
    public watchItems(): void {
        this.currentItems = this.items;
    }

    public mounted(): void {
        /**
         * When going back and forth between steps, the value would not be shown
         * even though it's present in the Store.
         */
        if (this.input?.value != null) {
            this.model = this.currentItems.find((item) => item.code === this.input?.value)?.text ?? '';
        }
    }

    public onSelect(option: OptionItem | null): void {
        // sometimes Buefy pukes and returns `null`
        if (option == null) {
            return;
        }
        penniInputModule.updateInput({
            name: this.name,
            value: option.code,
            dirty: true,
            valid: true,
            summary: option.text || '',
            required: this.required,
        });
    }

    /**
     * @description
     * When typing in the field it should always validate as false, and set the value to empty string.
     * This is to prevent that you can select a valid option, and then type in the field without selecting a valid option,
     * and still have the field as valid.
     * Can be disabled with the prop "allowFreeText" which will validate true as long as there's any text in the input.
     */
    public onTyping(text: string): void {
        if (this.getAsyncItems !== null) {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => this.updateAsyncItems(text), 500);
        }

        if (this.allowFreeText) {
            penniInputModule.updateInput({
                name: this.name,
                value: text,
                valid: text.trim().length > 0,
                dirty: true,
                summary: text,
                required: this.required,
            });
            return;
        }

        penniInputModule.updateInput({
            name: this.name,
            value: '',
            dirty: false,
            valid: false,
            summary: '',
            required: this.required,
        });
    }

    public isValid(): boolean {
        return this.input != null && this.input.value !== '';
    }

    public async updateAsyncItems(inputValue: string): Promise<void> {
        if (inputValue !== '' && this.getAsyncItems != null) {
            this.currentItems = [];
            try {
                const res: OptionItem[] | null = await this.getAsyncItems(inputValue);
                if (res != null) {
                    this.currentItems = res;
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    public get filteredData(): OptionItem[] {
        return this.currentItems.filter((option) => option.text!.toLowerCase().indexOf(this.model.toLowerCase()) > -1);
    }

    /**
     * @description
     * Buefy requires `customClass` to be a String, so we have to do some magic here.
     */
    public get customClasses(): string {
        if (this.input == null) {
            return '';
        }
        return `${this.input.valid === false && this.input.dirty ? 'is-danger' : ''} ${this.customClass}`;
    }
}
