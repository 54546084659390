import {PluginObject, VueConstructor} from 'vue';
import PenniCheckbox from './Checkbox.vue';

export * from './Checkbox.vue';

export const PenniCheckboxPlugin: PluginObject<null> = {
    install(Vue: VueConstructor): void {
        Vue.component('PenniCheckbox', PenniCheckbox);
    },
};
