













import ContentfulMixin from '@/content/mixin';
import {SidebarResource} from '@/store/models';
import {ContentModelTypes} from '@penni/contentful-api';
import {Component, Mixins} from 'vue-property-decorator';
import {penniCheckoutModule, penniContentModule} from '../store';

@Component
export default class ContentBlock extends Mixins(ContentfulMixin) {
    public socialProof: SidebarResource[] = [];

    public beforeMount(): void {
        /**
         * For backwards compatibility the items in the store will be used as the main list if it has been set.
         * If not, then socialProof will be fetched directly from Contentful.
         */
        if (this.items != null && this.items.length > 0) {
            this.socialProof = this.items;
        } else {
            let count = 0;
            while (this.resourceExists(`social-proof-${count}`, ContentModelTypes.TEXT)) {
                this.socialProof.push({id: `social-proof-${count}`});
                count++;
            }
        }
    }
    public created(): void {
        if (penniContentModule.checkout == null) {
            throw new Error('There is no checkout in the Content store');
        }
    }

    public get items(): SidebarResource[] | null {
        return penniCheckoutModule.sidebarSocialProof;
    }
}
