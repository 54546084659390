







import {penniCommonModule} from '../store';
import {Component, Mixins} from 'vue-property-decorator';
import ViewsMixin from './mixin';

@Component
export default class GenericError extends Mixins(ViewsMixin) {
    public mounted(): void {
        penniCommonModule.setTitle('error-header');

        this.setSelectedPremium();
    }
}
