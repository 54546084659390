































import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import InputMixin from '../mixin';
import {Autocomplete, dawaAutocomplete} from 'dawa-autocomplete2';
import {penniInputModule} from '../../store';
import axios from 'axios';

export type Address =
    | {
          address: string;
          city: string;
          postalCode: string;
          id: string;
          kvhx: string;
      }
    | undefined;

type Selected = {
    tekst: string;
    data: {
        id: string;
        postnr: string;
        postnrnavn: string;
    };
};

@Component
export default class PenniAddressLookup extends mixins(InputMixin) {
    @Prop({required: false, type: String, default: ''}) public readonly kvhxValidationMessage!: string;

    public dawaInstance: Autocomplete | null = null;
    public customValidationMsg: string = '';
    public inputModel: string = '';

    public created(): void {
        penniInputModule.setInput({
            name: this.name,
            req: this.req,
            form: this.form,
            type: 'text',
            value: this.input?.value ?? undefined,
            valid: this.isValid(),
            required: this.required ?? false,
            label: this.label,
            pattern: this.pattern,
            validationMessage: this.contentfulValidationMsg,
            placeholder: this.placeholder,
            summary: '',
        });
    }

    public mounted(): void {
        const el = document.querySelector('[name="address"]');
        if (el != null) {
            this.dawaInstance = dawaAutocomplete(el, {
                select: this.select,
            });
        }
    }

    public async select(selected: Selected): Promise<void> {
        try {
            const kvhx = await this.getKvhx(selected.data.id);
            const address = {
                address: selected.tekst,
                postalCode: selected.data.postnr,
                city: selected.data.postnrnavn,
                id: selected.data.id,
                kvhx,
            };
            penniInputModule.updateInput({
                name: this.name,
                value: address,
                summary: address.address,
                required: this.required,
            });
        } catch (error) {
            this.addressValidationMsg = this.kvhxValidationMessage;
            penniInputModule.updateInput({
                name: this.name,
                dirty: false,
                value: undefined,
                summary: '',
                required: this.required,
            });
        } finally {
            this.model = selected.tekst;
        }
    }

    public getKvhx = async (id: string): Promise<string> => {
        try {
            const {data} = await axios.get('https://dawa.aws.dk/adresser', {
                params: {
                    id,
                },
            });
            return data[0].kvhx;
        } catch (error) {
            throw new Error((error as Error).message);
        }
    };

    public isValid(): boolean {
        return this.input?.value != null;
    }

    public destroyed(): void {
        this.dawaInstance?.destroy();
    }

    /**
     * @description
     * When typing in the field it should always validate as false, and set the value to empty string.
     * This is to prevent that you can select a valid option, and then type in the field without selecting a valid option,
     * and still have the field as valid.
     */
    public onInput(): void {
        if (this.customValidationMsg.length > 0) {
            this.addressValidationMsg = '';
        }
        penniInputModule.updateInput({
            name: this.name,
            dirty: false,
            value: undefined,
            valid: false,
            summary: '',
            required: this.required,
        });
    }

    /**
     * @description
     * Check validity of input and set dirty to true.
     */
    public onBlur(): void {
        const storeInput = penniInputModule.getInput(this.name);
        penniInputModule.updateInput({
            name: this.name,
            dirty: true,
            valid: this.isValid(),
            value: storeInput?.value,
            summary: `${storeInput?.value}` || '',
            required: this.required,
        });
    }

    public get addressValidationMsg(): string {
        return this.customValidationMsg.length > 0
            ? this.getTextResource(this.customValidationMsg, 'no custom validation message')
            : this.getTextResource(this.validationMessage!, 'no validation message');
    }

    public set addressValidationMsg(id: string) {
        this.customValidationMsg = id;
    }

    public get selected(): Address {
        return this.input?.value as Address;
    }

    public get model(): string {
        return this.selected?.address ?? this.inputModel;
    }
    public set model(value: string) {
        this.inputModel = value;
    }

    /**
     * @description
     * Buefy requires `customClass` to be a String, so we have to do some magic here.
     */
    public get customClasses(): string {
        if (this.input == null) {
            return '';
        }
        return `${this.input.valid === false && this.input.dirty ? 'is-danger' : ''} ${this.customClass}`;
    }
}
