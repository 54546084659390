
















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class GenericError extends Vue {
    @Prop({required: false}) public readonly error?: Error;
    @Prop({required: false, default: () => true}) public readonly showRetry?: boolean;

    public get errorMessage(): string {
        return this.$route.meta?.genericErrorMessage ?? this.error?.message ?? 'Something happend';
    }

    public get errorTitle(): string {
        return this.$route.meta?.genericErrorTitle ?? this.error?.name ?? 'Error';
    }
}
