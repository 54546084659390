import {Module, Mutation, VuexModule, Action} from 'vuex-module-decorators';
import {penniCheckoutModule, penniContentModule} from '../store';
import {ProductResponse, ProductVariation} from '@penni/generic-api';
import {penniHttpClient} from '@/plugin';
import {HttpMethods} from '@penni/contentful-api';

export const PRODUCT_MODULE_NAMESPACE = 'productModule';

/**
 * A temporary type for what we can expect in the penniContentModule.options.
 * It is not the full dictionary of options. That will come later via contentful-api.
 * TODO: Add core-checkout options to contentful-api.
 */
type Options = {
    product: string;
    productOptions?: string[];
    productAdditionalOptions?: [
        /**
         * Selected variation id
         */
        'variation',
        /**
         * Selected variation premium
         */
        'price',
        /**
         * PartnerId
         */
        'partnerId'
    ];
};

export type ProductOptions = Record<string, unknown>;
export type ProductRes = ProductResponse<string, ProductOptions, ProductVariation[]>;

@Module({
    name: PRODUCT_MODULE_NAMESPACE,
    stateFactory: true,
    namespaced: true,
})
export class ProductModule extends VuexModule {
    public product: ProductRes | null = null;
    public variations: ProductVariation[] = [];
    public options: ProductOptions | null = null;

    public get getOption(): (name: string) => unknown | undefined {
        return (name: string): unknown | undefined => (this.options == null ? null : this.options[name]);
    }

    @Mutation
    public setProductResponse(payload: ProductRes): void {
        this.product = payload;
    }

    @Mutation
    public setProductVariations<P extends ProductVariation>(payload: P[] | null): void {
        if (payload == null) {
            this.variations = [];
        } else {
            this.variations = payload;
        }
    }

    @Mutation
    public setProductOptions<P extends ProductOptions>(payload: P | null): void {
        this.options = payload;
    }

    @Action
    public async fetchProduct(requestMethod?: HttpMethods): Promise<void> {
        try {
            const options = penniContentModule.checkout?.options as Options | null;
            const additionalOptions: Record<string, string> = {};

            if (options == null) {
                throw new Error(`Checkout lacks "options" in the configuration`);
            }

            /**
             * Assign any additional options
             * TODO: Find a way to generalize this more.
             */
            if (
                options.productAdditionalOptions != null &&
                options.productAdditionalOptions.length > 0 &&
                penniCheckoutModule.basketSelectedVariation != null
            ) {
                if (options.productAdditionalOptions.includes('variation')) {
                    additionalOptions.variation = penniCheckoutModule.basketSelectedVariation.id;
                }

                if (
                    options.productAdditionalOptions.includes('price') &&
                    penniCheckoutModule.basketSelectedVariation.premium.monthly != null
                ) {
                    additionalOptions.price = penniCheckoutModule.basketSelectedVariation.premium.monthly.toString();
                }

                if (options.productAdditionalOptions.includes('partnerId')) {
                    additionalOptions.partnerId = (penniCheckoutModule.basketResponse?.products[0].request
                        .products[0] as Record<string, string>).partnerId;
                }
            }

            const requestUrl = new URL(`${penniHttpClient.defaults.baseURL}/product/${options.product}`);

            if (Array.isArray(options.productOptions) && options.productOptions.length > 0) {
                requestUrl.searchParams.set('options', options.productOptions.join(','));
            }

            if (additionalOptions != null) {
                for (const [key, value] of Object.entries(additionalOptions)) {
                    requestUrl.searchParams.set(key, value);
                }
            }

            const callback = (product: ProductRes) => {
                this.context.commit('setProductResponse', product);
                this.context.commit('setProductVariations', product.variations);
                this.context.commit('setProductOptions', product.options);
            };

            if (requestMethod != null && requestMethod === HttpMethods.POST) {
                if (penniCheckoutModule.basketResponse != null) {
                    const request = penniCheckoutModule.basketResponse.products[0].request.products[0];
                    penniHttpClient
                        .post<ProductRes>(requestUrl.toString(), request)
                        .then((response) => callback(response.data));
                } else {
                    throw new Error(
                        `Basket response is ${penniCheckoutModule.basketResponse}, cannot fetch product using ${requestMethod}`
                    );
                }
            } else {
                const response = await penniHttpClient.get<ProductRes>(requestUrl.toString());
                callback(response.data);
            }
        } catch (error) {
            console.error('failed to fetch product', error);
            this.context.commit('setProductResponse', null);
            this.context.commit('commonModule/setDidThrow', {error, didThrow: true}, {root: true});
        }
    }

    @Mutation
    public resetState(): void {
        this.product = null;
        this.variations = [];
        this.options = {};
    }
}

export const getProductIcon = (productId: string): string => {
    const productIcons = {
        motor: 'car',
        motor2022: 'car',
        card: 'wallet-travel',
        child: 'baby-face-outline',
        purchase: 'wallet-giftcard',
        escooter: 'scooter-electric',
        bicycle: 'bicycle',
        mortgage: 'cash-100',
    } as Record<string, string>;

    return productIcons[productId] ?? 'lightbulb-question-outline';
};
