import {DefaultTheme} from '@penni/contentful-api';
import {ContentModule} from '@/store/contentModule';

// from https://stackoverflow.com/a/49042916
function flatten(obj: Record<string, unknown> | DefaultTheme, path = ''): Record<string, unknown> {
    if (!(obj instanceof Object)) return {[`--${path.replace(/\-$/g, '')}`]: obj};
    return Object.keys(obj).reduce((output: Record<string, unknown>, key: string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return {...output, ...flatten(obj[key], path + key + '-')};
    }, {});
}

export function themeVariables(contentModule: ContentModule): Record<string, unknown> {
    const theme: DefaultTheme | undefined = contentModule.checkout?.theme;
    return theme == null ? {} : flatten(theme);
}

export function setTheme(contentModule: ContentModule): void {
    const theme = themeVariables(contentModule);
    const stylesId = 'penni-theme-styles';
    const styles = document.createElement('style');
    styles.id = stylesId;
    styles.type = 'text/css';
    styles.innerHTML += ':root {';
    Object.keys(theme).forEach((key: string) => {
        styles.innerHTML += `${key}: ${theme[key]};`;
    });
    styles.innerHTML += '}';
    document.head.append(styles);
}
