import {Component, Vue} from 'vue-property-decorator';

@Component
export default class ResourceMixin extends Vue {
    public replaceVariables(resource: string, replaceObject: Record<string, string>): string {
        const re = new RegExp(Object.keys(replaceObject).join('|'), 'gi');

        return resource.replace(re, (matched: string): string => {
            return replaceObject[matched];
        });
    }
}
