import {VNodeDirective} from 'vue/types/vnode';

type HTMLElementObserver = HTMLElement & {
    _observe?: {
        init: boolean;
        observer: IntersectionObserver;
    };
};

function inserted(el: HTMLElement, binding: VNodeDirective): void {
    const newEl = el as HTMLElementObserver;
    const value = binding.value;
    const {handler, options} = typeof value === 'object' ? value : {handler: value, options: {}};
    const observer = new IntersectionObserver(
        (entries: IntersectionObserverEntry[] = [], observer: IntersectionObserver) => {
            if (!newEl._observe) {
                return;
            }

            // invoke the user callback
            if (handler && newEl._observe.init) {
                const isIntersecting = Boolean(entries.find((entry) => entry.isIntersecting));

                handler(entries, observer, isIntersecting);
            } else {
                newEl._observe.init = true;
            }
        },
        options
    );

    newEl._observe = {init: false, observer};

    observer.observe(el);
}

function unbind(el: HTMLElement): void {
    const newEl = el as HTMLElementObserver;
    if (!newEl._observe) {
        return;
    }

    newEl._observe.observer.unobserve(el);
    delete newEl._observe;
}

export const Intersect = {
    inserted,
    unbind,
};
