









import {Component, Vue} from 'vue-property-decorator';

import SupportArea from './SupportArea.vue';
import Logos from './Logos.vue';

@Component({components: {SupportArea, Logos}})
export default class Header extends Vue {}
