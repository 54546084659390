







import Vue from 'vue';
import GenericError from './GenericError.vue';
import Component from 'vue-class-component';
import {penniCommonModule} from '@/store';

@Component({
    components: {GenericError},
    metaInfo() {
        return {
            title: this.$route.meta?.title,
        };
    },
})
export default class App extends Vue {
    public get showGenericError(): boolean {
        return penniCommonModule.didThrow && this.$route.query['genericError'] === 'true';
    }

    public get genericError(): Error | null {
        return penniCommonModule.error;
    }
}
