import {PenniRouteConfig, Route} from 'vue-router';
import Motor from '../views/Motor.vue';
import Motor2022 from '../views/Motor2022.vue';
import Child from '../views/Child.vue';
import Purchase from '../views/Purchase.vue';
import Card from '../views/Card.vue';
import CardSuccess from '../views/CardSuccess.vue';
import {basketRouteBeforeEnter} from '@/basket';
import {PenniLandingPage, PenniPaymentError, PenniPaymentSuccess} from '@/layout';
import {paymentRouteBeforeEnter} from '@/payment';

export const routes: PenniRouteConfig[] = [
    {
        component: Motor,
        path: '/motor/:basketId',
        name: 'Tryg - Motor',
        meta: {
            title: 'Tryg - Forsikring',
            customer: 'tryg',
            product: 'motor',
            apiBaseUrl: process.env.VUE_APP_TRYG_MOTOR_BASE_URL,
        },
        beforeEnter: basketRouteBeforeEnter(),
    },
    {
        component: Motor2022,
        path: '/motor2022/:basketId',
        name: 'Tryg - Motor 2022',
        meta: {
            title: 'Tryg - Forsikring',
            customer: 'tryg',
            product: 'motor2022',
            apiBaseUrl: process.env.VUE_APP_TRYG_MOTOR_2022_BASE_URL,
        },
        beforeEnter: basketRouteBeforeEnter(),
    },
    {
        component: Child,
        path: '/child/:basketId',
        name: 'Tryg - Children',
        meta: {
            title: 'Tryg - Forsikring',
            customer: 'tryg',
            product: 'child',
            apiBaseUrl: process.env.VUE_APP_TRYG_CHILD_BASE_URL,
        },
        beforeEnter: basketRouteBeforeEnter(),
    },
    {
        path: '/card/:basketId',
        component: Card,
        name: 'Tryg - Card',
        meta: {
            title: 'Tryg - Forsikring',
            customer: 'tryg',
            product: 'card',
            apiBaseUrl: process.env.VUE_APP_TRYG_CARD_BASE_URL,
        },
        beforeEnter: basketRouteBeforeEnter(),
    },
    {
        path: '/dk/card/:basketId',
        meta: {
            title: 'Tryg - Forsikring',
            customer: 'tryg',
            product: 'card',
            apiBaseUrl: process.env.VUE_APP_TRYG_CARD_BASE_URL,
        },
        redirect: (to: Route): Record<string, unknown> => {
            const isLocalHost = process.env.VUE_APP_ENV === 'development';
            const path = isLocalHost ? `${to.meta!.customer}/card/` : 'card/';
            return {path: path + to.params.basketId};
        },
    },
    {
        component: Purchase,
        path: '/dk/purchase/:basketId',
        name: 'Tryg - DK - Purchase',
        meta: {
            title: 'Tryg - Forsikring',
            customer: 'tryg',
            product: 'purchase',
            apiBaseUrl: process.env.VUE_APP_TRYG_PURCHASE_BASE_URL_DK,
        },
        beforeEnter: basketRouteBeforeEnter(),
    },
    {
        component: Purchase,
        path: '/se/purchase/:basketId',
        name: 'Tryg - SE - Purchase',
        meta: {
            title: 'Tryg - Forsikring',
            customer: 'tryg',
            product: 'purchase',
            apiBaseUrl: process.env.VUE_APP_TRYG_PURCHASE_BASE_URL_SE,
        },
        beforeEnter: basketRouteBeforeEnter(),
    },
    {
        component: Purchase,
        path: '/no/purchase/:basketId',
        name: 'Tryg - NO - Purchase',
        meta: {
            title: 'Tryg - Forsikring',
            customer: 'tryg',
            product: 'purchase',
            apiBaseUrl: process.env.VUE_APP_TRYG_PURCHASE_BASE_URL_NO,
        },
        beforeEnter: basketRouteBeforeEnter(),
    },
    {
        component: PenniPaymentSuccess,
        path: '/dk/purchase/payment-success/:basketId/:transactionId/:paymentProvider',
        name: 'Tryg - DK - Payment Success',
        meta: {
            title: 'Tryg',
            customer: 'tryg',
            product: 'purchase',
            apiBaseUrl: process.env.VUE_APP_TRYG_PURCHASE_BASE_URL_DK,
        },
        beforeEnter: paymentRouteBeforeEnter(),
    },
    {
        component: PenniPaymentSuccess,
        path: '/no/purchase/payment-success/:basketId/:transactionId/:paymentProvider',
        name: 'Tryg - NO - Payment Success',
        meta: {
            title: 'Tryg',
            customer: 'tryg',
            product: 'purchase',
            apiBaseUrl: process.env.VUE_APP_TRYG_PURCHASE_BASE_URL_NO,
        },
        beforeEnter: paymentRouteBeforeEnter(),
    },
    {
        component: PenniPaymentSuccess,
        path: '/se/purchase/payment-success/:basketId/:transactionId/:paymentProvider',
        name: 'Tryg - SE - Payment Success',
        meta: {
            title: 'Tryg',
            customer: 'tryg',
            product: 'purchase',
            apiBaseUrl: process.env.VUE_APP_TRYG_PURCHASE_BASE_URL_SE,
        },
        beforeEnter: paymentRouteBeforeEnter(),
    },
    {
        component: CardSuccess,
        path: '/dk/card/payment-success/:basketId/:transactionId/:paymentProvider',
        name: 'Tryg - DK - Card - Payment Success',
        meta: {
            title: 'Tryg',
            customer: 'tryg',
            product: 'card',
            apiBaseUrl: process.env.VUE_APP_TRYG_CARD_BASE_URL,
        },
        beforeEnter: paymentRouteBeforeEnter(),
    },
    {
        component: PenniPaymentError,
        path: '/dk/card/payment-error/:basketId',
        name: 'Tryg - DK - Card - Payment Error',
        meta: {
            title: 'Tryg',
            customer: 'tryg',
            product: 'card',
            apiBaseUrl: process.env.VUE_APP_TRYG_CARD_BASE_URL,
        },
        beforeEnter: basketRouteBeforeEnter(),
    },
    {
        component: PenniPaymentError,
        path: '/dk/purchase/payment-error/:basketId',
        name: 'Tryg - DK - Payment Error',
        meta: {
            title: 'Tryg',
            customer: 'tryg',
            product: 'purchase',
            apiBaseUrl: process.env.VUE_APP_TRYG_PURCHASE_BASE_URL_DK,
        },
        beforeEnter: basketRouteBeforeEnter(),
    },
    {
        component: PenniPaymentError,
        path: '/no/purchase/payment-error/:basketId',
        name: 'Tryg - NO - Payment Error',
        meta: {
            title: 'Tryg',
            customer: 'tryg',
            product: 'purchase',
            apiBaseUrl: process.env.VUE_APP_TRYG_PURCHASE_BASE_URL_NO,
        },
        beforeEnter: basketRouteBeforeEnter(),
    },
    {
        component: PenniPaymentError,
        path: '/se/purchase/payment-error/:basketId',
        name: 'Tryg - SE - Payment Error',
        meta: {
            title: 'Tryg',
            customer: 'tryg',
            product: 'purchase',
            apiBaseUrl: process.env.VUE_APP_TRYG_PURCHASE_BASE_URL_SE,
        },
        beforeEnter: basketRouteBeforeEnter(),
    },
    {
        component: PenniLandingPage,
        path: '/:partner/purchase',
        name: 'Tryg - Purchase - Landing Page',
        meta: {
            title: 'Tryg - Forsikring',
            customer: 'tryg',
            product: 'purchase',
            fetchBasket: false,
            prism: false,
            apiBaseUrl: process.env.VUE_APP_TRYG_PURCHASE_BASE_URL_DK,
        },
        beforeEnter: basketRouteBeforeEnter(),
    },
    {
        component: PenniLandingPage,
        path: '/:partner/motor',
        name: 'Tryg - Motor - Landing Page',
        meta: {
            title: 'Tryg - Forsikring',
            customer: 'tryg',
            product: 'motor',
            fetchBasket: false,
            prism: false,
            apiBaseUrl: process.env.VUE_APP_TRYG_MOTOR_BASE_URL,
        },
        beforeEnter: basketRouteBeforeEnter(),
    },
    {
        component: PenniLandingPage,
        path: '/:partner/motor2022',
        name: 'Tryg - Motor 2022 - Landing Page',
        meta: {
            title: 'Tryg - Forsikring',
            customer: 'tryg',
            product: 'motor2022',
            fetchBasket: false,
            apiBaseUrl: process.env.VUE_APP_TRYG_MOTOR_2022_BASE_URL,
        },
        beforeEnter: basketRouteBeforeEnter(),
    },
    {
        component: PenniLandingPage,
        path: '/:partner/card',
        name: 'Tryg - Card - Landing Page',
        meta: {
            title: 'Tryg - Forsikring',
            customer: 'tryg',
            product: 'card',
            fetchBasket: false,
            prism: false,
            apiBaseUrl: process.env.VUE_APP_TRYG_CARD_BASE_URL,
        },
        beforeEnter: basketRouteBeforeEnter(),
    },
    {
        component: PenniLandingPage,
        path: '/:partner/child',
        name: 'Tryg - Child - Landing Page',
        meta: {
            title: 'Tryg - Forsikring',
            customer: 'tryg',
            product: 'child',
            fetchBasket: false,
            prism: false,
            apiBaseUrl: process.env.VUE_APP_TRYG_CHILD_BASE_URL,
        },
        beforeEnter: basketRouteBeforeEnter(),
    },
];
