import {PluginObject, VueConstructor} from 'vue';
import PenniText from './Text.vue';

export * from './Text.vue';

export const PenniTextPlugin: PluginObject<null> = {
    install(Vue: VueConstructor): void {
        Vue.component('PenniText', PenniText);
    },
};
