





















import {Component, Prop, Mixins, Vue, Watch} from 'vue-property-decorator';
import InputMixin from '../mixin';
import {Select} from 'buefy';
import {penniCheckoutModule, penniInputModule} from '../../store';
import {BasketResponse, OptionItem} from '@penni/generic-api';
import _get from 'lodash/get';
import {Input, InputType, InputUpdate, InputValues} from '@/store/models';
import {isEmpty} from 'lodash';

Vue.use(Select);

@Component
export default class PenniSelect extends Mixins(InputMixin) {
    @Prop({required: true, type: Array, default: () => []}) public readonly options!: OptionItem[];
    @Prop({required: false, type: Boolean, default: false}) public readonly overrideValidation!: boolean;
    @Prop({required: false, type: String, default: null}) public readonly icon!: string;

    /*
    First we check if this basket has value for this input
    If basket has it then rely on it, input gets dirty

    Otherwise we try to find default option in options
    and try to save in the basket
    */

    public async mounted(): Promise<void> {
        const defaultOption = this.getDefaultOption(this.options);
        const fallbackValue = this.options?.length > 0 ? this.options?.[0].code : '';
        const value = defaultOption == null ? fallbackValue : defaultOption.code;

        const inputState = this.getInputStateConfig(value);

        penniInputModule.setInput(inputState as Input);

        await this.tryStoreValueInBasket(value, defaultOption);
    }

    /**
     * This watcher is a dirty solution to fixing Vue's lack of support for async props.
     * A Select with async options from a customer project, would fail on mounted since Vue
     * waits for no one. This will "fix" that.
     */
    @Watch('options', {deep: true})
    public watchOptions(newOptions: OptionItem[]): void {
        const defaultOption = this.getDefaultOption(newOptions);
        const fallbackFirstOptionValue = newOptions?.[0] != null ? newOptions?.[0].code : '';
        const fallbackValue = defaultOption == null ? fallbackFirstOptionValue : defaultOption.code;
        const value = isEmpty(this.input?.value) ? fallbackValue : this.input?.value;

        const inputState = this.getInputStateConfig(value) as InputUpdate;
        penniInputModule.updateInput(inputState);

        this.tryStoreValueInBasket(value, defaultOption);
    }

    public get model(): string {
        return this.input?.value as string;
    }

    public set model(value: string) {
        const inputState = this.getInputStateConfig(value, true) as InputUpdate;
        penniInputModule.updateInput(inputState);

        this.valueChange(value);

        this.updateBasketInputValue();
    }

    public isValid(): boolean {
        if (!this.required) {
            return true;
        }
        if (this.input == null) {
            return false;
        }
        if (this.overrideValidation) {
            return this.validity;
        }
        return this.options?.length > 0 && this.input?.value != null && this.input.value != '';
    }

    private getDefaultOption(options?: OptionItem[]): OptionItem | undefined {
        if (options == null) {
            return;
        }
        return options.find((option: OptionItem) => option.default);
    }

    public tryStoreValueInBasket(value: InputValues, defaultOption?: OptionItem): void {
        /**
         * check if we have any data in the resumable baskets
         * if not update input value in basket
         */

        const basket = penniCheckoutModule.basketResponse?.products[0].request;
        if (basket == null || this.req == null || this.req === '') {
            return;
        }

        const basketValue = _get(basket, this.req);

        if (basketValue == null && defaultOption?.code === value) {
            this.updateBasketInputValue();
        }
    }

    private getInputStateConfig(value: InputValues, dirty?: boolean): Partial<Input> {
        const staticValues = {
            type: 'select' as InputType,
            name: this.name,
            req: this.req,
            form: this.form,
            required: this.required,
            props: {...this.$props},
        };

        const summary = this.options.find((option: OptionItem) => option.code === value)?.text ?? '';

        const dynamicValues = {
            value,
            summary,
            valid: this.isValid(),
            dirty: dirty ?? this.input?.dirty,
        };

        return {...staticValues, ...dynamicValues};
    }
}
