


















































import {getProductIcon} from '@/store/productModule';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {WidgetIds} from './Widget.vue';
import {Mixins} from 'vue-property-decorator';
import ContentfulMixin from '@/content/mixin';

enum viewType {
    MULTIPLE = 'multiple',
    SINGLE = 'single',
}
type ProductSelectionViewType = viewType.MULTIPLE | viewType.SINGLE;

type Product = {
    id: string;
    icon?: string;
    widgetIds: WidgetIds;
    goTo?: string;
};

@Component
export default class ProductSelection extends Mixins(ContentfulMixin) {
    @Prop({required: true, type: String}) public readonly id!: string;
    @Prop({required: true, type: Array}) public readonly products!: Product[];
    @Prop({required: false, type: String, default: viewType.MULTIPLE}) public readonly type!: ProductSelectionViewType;
    @Prop({required: true, type: String}) public readonly productId!: string;

    public errorText: string = '';
    public isLoading: boolean = false;

    public mounted(): void {
        if (this.products == null || this.products.length === 0) {
            this.errorText = 'Missing products in product selection component';
            console.error(this.errorText);
        }
    }

    public get routeProductId(): string {
        const routeMetaProduct = this.$route?.meta?.product ?? '';
        return this.productId != null && this.productId.length !== 0 ? this.productId : routeMetaProduct;
    }

    public get selectedProduct(): Product | undefined {
        if (this.$route?.meta?.product == null) {
            return;
        }
        return this.products.find((product: Product): boolean => product.id === this.routeProductId);
    }

    public getHeaderResourceId(productId: string): string {
        return `lp-product-selection-title-${productId}`;
    }

    public titleExists(productId: string): boolean {
        const id = this.getHeaderResourceId(productId);
        return this.getAnyTextResource(id) !== '';
    }

    @Watch('selectedProduct')
    onSelect(current: Product | null, old: Product | null): void {
        if (current == null || current.id === old?.id) return;
        this.scrollToElement(current.id);
    }

    private scrollToElement(productId: string) {
        const el = document.getElementById(productId);
        if (el) {
            setTimeout(() => {
                el.scrollIntoView({behavior: 'smooth', block: 'center'});
            }, 500);
        }
    }

    public redirectOnClick(product: Product): void {
        if (this.routeProductId == null || this.routeProductId.trim().length === 0) {
            this.errorText = 'Missing product in route params or route meta for this landing page';
            console.error(this.errorText);
            return;
        }

        this.isLoading = true;

        const url = window.location.pathname.replace(this.routeProductId, product.id);
        const externalUrl = product.goTo;

        if (externalUrl != null && externalUrl.trim().length > 0 && /^https?:\/\//.test(externalUrl)) {
            window.location.pathname = url;
        } else {
            this.$router.push(url);
        }
    }

    public productIcon(product: Product): string {
        return product.icon ?? getProductIcon(product.id);
    }
}
