





























































import {penniCheckoutModule, penniCommonModule} from '../store';
import {Component, Vue} from 'vue-property-decorator';
import PenniPrice from '../layout/Price.vue';
import PenniModal from '../layout/modal/Modal.vue';
import {OrderProduct} from '../store/models';
import Sidebar from 'buefy';
import {prism} from '../analytics/plugin';

Vue.use(Sidebar);

@Component({components: {PenniPrice, PenniModal}})
export default class SummaryOrder extends Vue {
    public expandedProduct: number | null = null;
    public readonly saveBasketModal: string = 'saveBasket';

    public expandProduct(productKey: number, line: OrderProduct): void {
        this.expandedProduct = this.expandedProduct === productKey ? null : productKey;
        prism?.client?.click('order-summary-item-expand', line.title);
    }

    public closeOrderSummaryOverlay(): void {
        penniCommonModule.setShowMobileOrderSummaryDetails(false);
    }

    public openSaveBasketModal(): void {
        penniCommonModule.toggleModal({name: this.saveBasketModal, active: true});

        const text = (this.$refs.trigger as Vue).$el.textContent ?? 'open-save-basket-triger';
        prism?.client?.click('save-basket-checkout', text);
    }

    /**
     * Sum the price of all basket products.
     * Will grab the price from the selected variation.
     */
    public get price(): number | undefined {
        const selectedVariation = penniCheckoutModule.calculationResponse?.productPrices[0].variations.find(
            (variation) => variation.id === penniCheckoutModule.basketSelectedVariation?.id
        );

        return selectedVariation?.premium[penniCheckoutModule.selectedPremiumType ?? 'monthly'];
    }

    public get allowSendToEmail(): boolean {
        return (
            penniCommonModule.allowSendToEmail && !penniCommonModule.didThrow && !penniCheckoutModule.basketCompleted
        );
    }

    public get summary(): OrderProduct[] {
        return penniCheckoutModule.orderSummary || [];
    }

    public get open(): boolean {
        return penniCommonModule.showMobileOrderSummaryDetails;
    }

    public set open(open: boolean) {
        penniCommonModule.setShowMobileOrderSummaryDetails(open);
    }

    public get useDineroPrices(): boolean {
        return penniCommonModule.useDineroPrices;
    }
}
