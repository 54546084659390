import {Action, Module, VuexModule} from 'vuex-module-decorators';
import {SignatureProviderEnum} from '@/signature/model';
import {penniHttpClient} from '@/plugin';

export const SIGNATURE_MODULE_NAMESPACE = 'signatureModule';

export type SignatureDocument = {
    id: string;
    name: string;
};

export type SignatureResponse = {
    signatureUrl: string;
    envelopeId: string;
};

@Module({
    name: SIGNATURE_MODULE_NAMESPACE,
    stateFactory: true,
    namespaced: true,
})
export class SignatureModule extends VuexModule {
    public static getDocumentUrl(
        documentId: string,
        documentName: string,
        envelopeId: string,
        provider: SignatureProviderEnum
    ): string {
        return `${penniHttpClient.defaults.baseURL}/signature/documents/${provider}/${envelopeId}/${documentId}/${documentName}`;
    }

    @Action
    public async getProviders(): Promise<SignatureProviderEnum[]> {
        try {
            const requestUrl = new URL(`${penniHttpClient.defaults.baseURL}/signature/providers`);

            const {data} = await penniHttpClient.get<SignatureProviderEnum[]>(requestUrl.toString());

            return data;
        } catch (error) {
            console.error(`Failed to fetch signature providers`, error);
            return [];
        }
    }

    @Action
    public async getSignatureUrl(payload: {
        provider: SignatureProviderEnum;
        basketId: string;
        transactionId: string;
        customerName: string;
        email: string;
        phone: string | undefined;
        returnUrl: string;
    }): Promise<SignatureResponse | undefined> {
        try {
            const requestUrl = new URL(
                `${penniHttpClient.defaults.baseURL}/signature/start/${payload.basketId}/${payload.transactionId}`
            );

            const {data} = await penniHttpClient.post<SignatureResponse>(requestUrl.toString(), {
                signatureProvider: payload.provider,
                name: payload.customerName,
                email: payload.email,
                phoneNumber: payload.phone,
                returnUrl: payload.returnUrl,
            });

            return data;
        } catch (error) {
            console.error('failed to fetch signature provider URL', error);
        }
    }

    @Action
    public async getSignatureDocuments(payload: {
        envelopeId: string;
        provider: SignatureProviderEnum;
    }): Promise<SignatureDocument[]> {
        try {
            const requestUrl = new URL(
                `${penniHttpClient.defaults.baseURL}/signature/documents/${payload.provider}/${payload.envelopeId}`
            );

            const {data} = await penniHttpClient.get<SignatureDocument[]>(requestUrl.toString(), {});

            return data;
        } catch (error) {
            console.error('failed to fetch signature provider URL', error);
            return [];
        }
    }

    @Action
    public async completeSignature(payload: {
        provider: SignatureProviderEnum;
        signatureId: string;
        basketId: string;
        transactionId: string;
    }): Promise<string | undefined> {
        try {
            const requestUrl = new URL(
                `${penniHttpClient.defaults.baseURL}/signature/complete/${payload.basketId}/${payload.transactionId}`
            );

            const {data} = await penniHttpClient.post<string>(requestUrl.toString(), {
                signatureProvider: payload.provider,
                signatureId: payload.signatureId,
            });

            return data;
        } catch (error) {
            console.error('failed to fetch signature provider URL', error);
        }
    }
}
