








import {Component, Prop, Mixins} from 'vue-property-decorator';
import InputMixin from '../mixin';
import {Radio} from 'buefy';
import {penniInputModule} from '../../store';
import Vue from 'vue';

Vue.use(Radio);

type SlotPropsData = {
    resourceId?: string;
};

@Component
export default class PenniRadio extends Mixins(InputMixin) {
    public optionResourceId?: string;

    @Prop({required: true, type: String}) public readonly value!: string;
    /**
     * @description
     * Determines if this option is the valid one. This is used when there is a yes/no situation.
     */
    @Prop({required: false, type: Boolean, default: undefined}) public readonly validOption!: boolean;
    @Prop({required: false, type: Boolean, default: false}) public readonly default!: boolean;

    public mounted(): void {
        const slotPropsData = this.$slots.default?.[0].componentOptions?.propsData as SlotPropsData;
        if (slotPropsData != null) {
            this.optionResourceId = slotPropsData.resourceId || '';
        }

        penniInputModule.setInput({
            name: this.name,
            req: this.req,
            form: this.form,
            type: 'radio',
            value: this.default ? this.value : '',
            valid: this.default ? true : this.isValid(),
            required: this.required ?? false,
            summary: this.summary,
            props: {...this.$props},
        });
    }

    public isValid(): boolean {
        if (this.input == null) {
            return false;
        }

        if (this.validOption != null) {
            return this.validOption;
        }

        if (this.input.value !== '') {
            return true;
        }

        return false;
    }

    public get model(): string {
        if (this.input == null || typeof this.input.value !== 'string') {
            return '';
        }
        return this.input.value;
    }

    public set model(value: string) {
        /**
         * If the radio does not have a `default` value we cannot validate the value the first time choice is made,
         * since it will be an empty string, which is invalid, but there is in fact a value it is just not updated in the store yet.
         * Therefore we use true or the validOption (if supplied) if the inital value is empty string but the `value` is not,
         * otherwise we use the regular `isValid` method.
         *
         * This is somewhat of an edgecase, since normally a radio group have an option selected already.
         */
        const validOption = this.validOption == null ? true : this.validOption;
        const valid = this.input?.value === '' && value !== '' ? validOption : this.isValid();

        penniInputModule.updateInput({
            name: this.name,
            value,
            valid,
            summary: this.summary,
            required: this.required,
        });
        this.valueChange(value);
        this.blur();
    }

    public get summary(): string {
        return this.optionResourceId != null ? this.getAnyTextResource(this.optionResourceId) : '';
    }
}
