import {PluginObject, VueConstructor} from 'vue';
import {PrismCheckoutClient} from './client';
import {PrismConfig} from './model';

export let prism: PrismCheckoutClient | null;

export const CheckoutPrismPlugin: PluginObject<PrismConfig> = {
    install(_Vue: VueConstructor, options: PrismConfig | undefined): void {
        if (options == null) {
            throw new Error('Please pass in a prism configuration');
        }

        prism = new PrismCheckoutClient(options);
    },
};
