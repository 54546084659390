






import {Component, Vue, Prop} from 'vue-property-decorator';
import {penniInputModule} from '../../store';
import {Input} from '../../store/models';

@Component
export default class PenniForm extends Vue {
    @Prop({required: true, default: 'penni-form'}) public readonly name!: string;

    public onSubmit(event: Event): void {
        event.stopPropagation();
        event.preventDefault();
    }

    public get inputs(): Input[] {
        return penniInputModule.getInputsByForm(this.name) ?? [];
    }

    public get isValid(): boolean {
        return this.inputs.every((item: Input) => item.valid);
    }
}
