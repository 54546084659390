import {penniCommonModule} from '../store';
import DineroFactory from 'dinero.js';
import dinero from 'dinero.js';
import {InputValues} from '../../lib/store/models';
import _get from 'lodash/get';
import {
    BasketResponse,
    CalculatedProductVariationReference,
    CalculationProduct,
    CalculationRequest,
    ProductResponse,
} from '@penni/generic-api';
import {CustomerJourneyCheckoutOptions, CustomerJourneyOptions} from '@penni/contentful-api';

/**
 * Format prices to a specific format from minors.
 *
 * @example
 * formatPrice(2500, 'DKK') => 'DKK 25.00'
 *
 * @param {number} price The price in minors.
 * @param {string|undefined} currency The currency to use as format options.
 *
 * @returns {string} A formatted string
 */
export const formatPrice = (price: number, currency: string | undefined): string => {
    /**
     * Use Dinero.js.
     * Pass in Store information to format the price correctly.
     *
     * What if that way is different from the other prices on the Store? That might be
     * very confusing for the users of the Store and our widget.
     *
     * A way to do this is to have the configuration object handle what currency and format to show prices in.
     */
    // TODO: What do we actually want to have as a placeholder? 'X', 0 or?
    return currency == null
        ? 'X'
        : dinero({amount: price, currency: currency as DineroFactory.Currency})
              .setLocale(penniCommonModule.locale)
              .toFormat();
};

export type SubstitutionContext = {
    inputs?: Record<string, InputValues>;
    product?: ProductResponse | null;
    calculationRequest?: CalculationRequest<CalculationProduct<string, Record<string, unknown>>> | null;
    basketResponse?: BasketResponse | null;
    selectedVariation?: CalculatedProductVariationReference | null;
};

const braceRegex = /{(\d+|[a-z$_][a-z\d$_]*?(?:\.[a-z\d$_]*?)*?)}/gi;

/**
 * Variable substitution
 * Replaces {xx.yy.zz} paths to a value in the substitution context (see above)
 *
 * @example
 * "Hello this is my registration number: {request.insuredObject.regno}"
 * => "Hello this is my registration number: AC13119"
 */
export const variableSubstitution = (template: string, data: SubstitutionContext): string => {
    return template.replace(braceRegex, (_, key) => {
        return _get(data, key);
    });
};

export const redirectAfterPurchase = (
    options: CustomerJourneyOptions<CustomerJourneyCheckoutOptions>,
    searchContext: SubstitutionContext,
    quoteDetails?: Record<string, unknown>
): void => {
    if (
        options.features?.redirectOnFlowEnd?.enabled === true &&
        searchContext.basketResponse?.products[0].meta != null
    ) {
        const foundRedirectMeta = searchContext.basketResponse?.products[0].meta.find(
            (item) => item.id === 'redirectUrl'
        );
        if (
            foundRedirectMeta != null &&
            typeof foundRedirectMeta.value === 'string' &&
            foundRedirectMeta.value !== ''
        ) {
            let redirectUrl = foundRedirectMeta.value;
            if (quoteDetails != null) {
                redirectUrl += `?quoteStatus=${quoteDetails.status}&quoteId=${quoteDetails.id}&`;
            }
            if (
                options.features?.redirectOnFlowEnd?.options != null &&
                Object.keys(options.features?.redirectOnFlowEnd?.options.parameters).length > 0
            ) {
                for (const [key, value] of Object.entries(options.features?.redirectOnFlowEnd?.options.parameters)) {
                    const paramValue = variableSubstitution(`{${value}}`, searchContext);
                    if (paramValue != null) {
                        redirectUrl += `${key}=${paramValue}&`;
                    }
                }
            }
            window.location.href = redirectUrl;
        }
    }
};
