import {Dropdown} from 'buefy';
import {PluginObject, VueConstructor} from 'vue';
import PenniDropdown from './Dropdown.vue';
import PenniDropdownInitializer from './DropdownInitializer.vue';

export * from './Dropdown.vue';
export * from './DropdownInitializer.vue';

export const PenniDropdownPlugin: PluginObject<null> = {
    install(Vue: VueConstructor): void {
        Vue.use(Dropdown);
        Vue.component('PenniDropdown', PenniDropdown);
        Vue.component('PenniDropdownInitializer', PenniDropdownInitializer);
    },
};
