import {Component, Mixins, Prop} from 'vue-property-decorator';
import {penniInputModule, penniCheckoutModule, penniCommonModule} from '../store';
import {prism} from '../analytics/plugin';
import {Input} from '@/store/models';
import ContentfulMixin from '../content/mixin';
import _set from 'lodash/set';
import {cloneDeep} from 'lodash';
import isEmpty from 'lodash.isempty';
import {FormValidation} from './formValidation';

export enum InputWidth {
    HALF = 'half',
    FULL = 'full',
}

/**
 * InputMixin
 * Contains all shared Props, Methods etc. for Input fields.
 */
@Component
export default class InputMixin extends Mixins(ContentfulMixin) {
    @Prop({required: false, type: Boolean}) public readonly required!: boolean;
    @Prop({required: false, type: Boolean, default: false}) public readonly trackValue!: boolean;
    @Prop({required: false, type: Boolean, default: false}) public readonly loading!: boolean;
    @Prop({required: false, type: Boolean}) public readonly disabled!: boolean;
    @Prop({
        required: false,
        type: String,
        default: () => 'text',
    })
    public readonly HTMLType!: string;
    @Prop({required: false, type: String}) public readonly pattern!: string;
    @Prop({required: false, type: String}) public readonly validationMessage!: string;
    @Prop({required: false, type: String}) public readonly customClass!: string;
    @Prop({required: false, type: String}) public readonly icon!: string;
    @Prop({required: false, type: String}) public readonly placeholder!: string;
    @Prop({required: false, type: String}) public readonly label!: string;
    @Prop({required: false, type: String, default: InputWidth.FULL}) public readonly width!: InputWidth;
    @Prop({required: false, type: String}) public readonly autocomplete!: string;
    @Prop({required: false, type: Number}) public readonly maxLength!: number;
    /**
     * Resumable baskets JSON path for storing and re-hydrating of the input field
     */
    @Prop({required: false, type: String}) public readonly req?: string;
    @Prop({required: false, type: Boolean, default: false}) public readonly hasCounter?: boolean;
    @Prop({required: true, type: String}) public readonly name!: string;
    @Prop({required: true, type: String}) public readonly form!: string;
    /**
     * @description
     * If this is set to false, then normal HTML5Validation will not be used.
     * If validation needs to be shown or set, then it has to be set manually via PenniInputModule functions.
     */
    @Prop({required: false, type: Boolean, default: false}) public readonly html5Validation?: boolean;
    @Prop({required: false, type: Array}) public readonly replace?: string[];

    public created(): void {
        if (this.name == null) {
            throw new Error('No name prop given for Input field.');
        }
        if (this.form == null) {
            throw new Error('No form prop given for Input field.');
        }
    }

    public mounted(): void {
        if (!this.hasControl) {
            throw new Error('All form fields needs to be a direct child of <PenniControl />');
        }
    }

    public keydown(event: KeyboardEvent): void {
        prism?.client?.keystroke(this.name, event.key);
    }

    public valueChange(value: string): void {
        if (this.trackValue) {
            prism?.client?.value(this.name, value);
        }
    }

    /**
     * Can be overridden by each Input if the native HTML5 validation
     * is not enough for that Input.
     */
    public isValid(): boolean {
        return this.inputEl?.validity.valid ?? false;
    }

    public async blur(): Promise<void> {
        prism?.client?.engagement();
        await this.updateBasketInputValue();
    }

    public async updateBasketInputValue(): Promise<void> {
        try {
            const inputModel = penniInputModule.getInput(this.name);
            const req = inputModel?.req; // Resumable basket store JSON path
            let value = inputModel?.value;
            const basket = penniCheckoutModule?.basketResponse;

            if (inputModel != null) {
                const updateRequest = {
                    ...inputModel,
                    dirty: true,
                };

                if (inputModel.overrideValidation !== true) {
                    updateRequest.valid = this.isValid();
                }

                if (this.replace != null && this.replace.length === 2 && value != null) {
                    const [regexString, replacementValue] = this.replace;
                    const regex = new RegExp(regexString);
                    updateRequest.value = value.toString().replaceAll(regex, replacementValue);
                    updateRequest.valid = new FormValidation(updateRequest).isValid();
                    value = updateRequest.value;
                }

                penniInputModule.updateInput(updateRequest);
            }

            if (basket != null && req != null && value != null) {
                // We cannot update the state of the store directly
                const b = cloneDeep(basket);

                // We support only one product now in the the calc req
                _set(b.products[0].request, req, value);

                await penniCheckoutModule.updateBasket(b);

                if (penniCommonModule.allowSendToEmail) {
                    const userData = {
                        oldEmail: penniCheckoutModule.previousBasketEmail,
                        newEmail: penniCheckoutModule.basketEmail,
                        firstName: penniCheckoutModule.firstName,
                        partnerName: penniCheckoutModule.partnerName,
                    };

                    await penniCheckoutModule.updateAbandonedBasketReminders(userData);
                }
            }
        } catch (error) {
            console.error('Could not updated basket with field value', error);
        }
    }

    public get input(): Input | undefined {
        return penniInputModule.getInput(this.name);
    }

    public get inputEl(): HTMLInputElement | null {
        return this.$el.querySelector<HTMLInputElement>(`input[name=${this.name}]`);
    }

    public get hasControl(): boolean {
        return this.$parent!.$el.classList.contains('penni-control');
    }

    public get validity(): boolean {
        return this.input?.valid as boolean;
    }

    public get dirty(): boolean | undefined {
        return this.input?.dirty;
    }

    public get fieldValidationType(): string {
        const inputIsInvalid = !this.validity && this.dirty;

        if (penniInputModule.amountOfInputsMappedFromBasket > 0) {
            if (inputIsInvalid) {
                return 'is-danger';
            }

            if (this.input?.required && isEmpty(this.input.value)) {
                return 'is-info';
            }
        }

        return inputIsInvalid ? 'is-danger' : '';
    }
}
