








import {Component, Vue} from 'vue-property-decorator';
import {penniStepModule, penniCommonModule} from '../store';
import {Step} from '../store/stepModule';
import {Icon, Button} from 'buefy';

Vue.use(Icon);
Vue.use(Button);

@Component
export default class PenniSteps extends Vue {
    public async mounted(): Promise<void> {
        // reset the header to the regular one.
        penniCommonModule.setTitle('general-header');
    }

    public get steps(): Step[] {
        return penniStepModule.steps;
    }

    public get currentStep(): number {
        return penniStepModule.currentStep;
    }
}
