










import {Component} from 'vue-property-decorator';
import Vue from 'vue';
import {penniCheckoutModule, penniCommonModule, penniContentModule, penniProductModule, penniStepModule} from '@/store';
import GenericSuccess from '@/views/GenericSuccess.vue';
import {CustomerJourneyCheckoutOptions, CustomerJourneyOptions} from '@penni/contentful-api';
import {prism} from '@/analytics/plugin';
import {redirectAfterPurchase} from './helpers';

@Component({components: {GenericSuccess}})
export default class PaymentSuccess extends Vue {
    public async mounted(): Promise<void> {
        if (this.shouldFinalizeQuote === true) {
            penniCommonModule.setLoading(true);
            await penniCheckoutModule.finalizeQuote({
                transactionId:
                    (this.$route.params.transactionId || penniCheckoutModule.quoteResponse?.transactionId) ?? '',
                partnerId: (penniCheckoutModule.basketResponse?.products[0].request.products[0] as Record<
                    string,
                    string
                >).partnerId,
            });
            penniCommonModule.setLoading(false);
        }

        // try redirect if feature enabled
        redirectAfterPurchase(
            this.options,
            {
                product: penniProductModule.product,
                calculationRequest: penniCheckoutModule.calculationRequest,
                basketResponse: penniCheckoutModule.basketResponse,
            },
            {status: 'success', id: penniCheckoutModule.quoteResponse?.quoteId}
        );

        prism?.client?.flowCompleted('purchase', {
            products: penniCheckoutModule.basketResponse?.products,
        });
        prism?.client?.transmitBuffer();

        await penniCheckoutModule.completeBasket();

        penniStepModule.setCompletionPct(100);
        penniStepModule.setFlowCompleted(true);
    }

    public get options(): CustomerJourneyOptions<CustomerJourneyCheckoutOptions> {
        return penniContentModule.options;
    }

    public get shouldFinalizeQuote(): boolean {
        return this.options.features?.finalizeQuote?.enabled != null;
    }
}
