/**
 * Returns the return URL for the current page.
 * @returns {string} The return URL.
 */
export const getBaseReturnUrl = (): string => {
    // Get the current origin (protocol, domain, and port)
    const {origin, href} = window.location;

    // Check if local development
    if (href.includes('localhost')) {
        // Regular expression pattern that match the URL up to and including the customer
        const regexPattern = /^(http:\/\/[^\/]+\/[a-zA-Z0-9_-]+).*/;
        const match = href.match(regexPattern);
        if (match) {
            return match[1];
        }
    }

    return origin;
};
