import {Component, Vue, Prop} from 'vue-property-decorator';
import {Input, InputUpdate} from '@/store/models';
import {FormValidation} from '@/form';
import {penniInputModule} from '@/store';

/**
 * StepMixin
 * Contains all shared Props, Methods etc. for Step items.
 */
@Component
export default class StepMixin extends Vue {
    @Prop({required: false, default: '', type: String}) public readonly form!: string;

    public validateInputs(): void {
        const inputs = penniInputModule.getInputsByForm(this.form);

        if (inputs == null || inputs.length <= 0) {
            return;
        }

        inputs.forEach(this.validateInput);
    }

    public validateInput(input: Input): void {
        if (input.overrideValidation && input.valid != null) {
            return;
        }
        const valid = new FormValidation(input).isValid();

        this.updateInput({...input, valid, dirty: true});
    }

    public updateInput(input: InputUpdate): void {
        penniInputModule.updateInput(input);
    }

    public focusFirstInvalidInput(): void {
        const invalidInputEl = this.$el.querySelector('input.is-danger, .check.is-danger');

        if (invalidInputEl == null) {
            return;
        }

        this.scrollIntoView(invalidInputEl);
    }

    public scrollIntoView(element: Element): void {
        this.$nextTick(() => {
            const offset = 45;
            const elementPosition = element.getBoundingClientRect().top;
            const scrollTo = elementPosition - offset;

            window.scrollBy({top: scrollTo, behavior: 'smooth'});
        });
    }
}
