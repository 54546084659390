






























































































































































import {MessageAutomationInsuranceTypesEnum} from '@/index';
import {
    penniCheckoutModule,
    penniCommonModule,
    penniContentModule,
    penniInputModule,
    penniProductModule,
} from '@/store';
import {ContentModelTypes, getResource} from '@penni/contentful-api';
import {CalculatedProductVariationReference, Coverage, ProductVariation, Premium} from '@penni/generic-api';
import {Component, Vue, Watch} from 'vue-property-decorator';

type basketRequestParameters = {
    productId: string;
    partnerId: string;
    currencyCode: currencyCodes;
    paymentLocale: string;
};

type currencyCodes = 'DKK' | 'SEK' | 'NOK';

@Component
export default class PurchaseView extends Vue {
    private calculationSelectedVariation: CalculatedProductVariationReference<ProductVariation<null>> | undefined;
    private productSelectedVariation: ProductVariation<Coverage<null, null, null, null>[]> | undefined;
    private currencyCode: currencyCodes | undefined;

    public async created(): Promise<void> {
        this.updateAfterGettingProduct();
    }

    public get product() {
        return penniProductModule.product;
    }

    @Watch('product')
    public updateAfterGettingProduct(): void {
        if (penniProductModule.product != null) {
            this.currencyCode = (penniCheckoutModule.basketResponse?.products[0].request
                .products[0] as basketRequestParameters).currencyCode;

            this.productSelectedVariation = penniProductModule.product?.variations.find(
                (variation: ProductVariation) => variation.id === penniCheckoutModule.basketSelectedVariation?.id
            );

            this.calculationSelectedVariation = penniCheckoutModule.calculationResponse?.productPrices[0].variations.find(
                (variation) => variation.id === penniCheckoutModule.basketSelectedVariation?.id
            );

            if (this.currencyCode == null) {
                throw new Error('currencyCode is not defined in basket');
            } else {
                penniCommonModule.setAllowSendToEmail(false);
                this.setOrderSummary();
            }
            penniCheckoutModule.setSelectedPremiumType(this.selectedPremiumType);
        }
    }

    public setOrderSummary(): void {
        const variantTitle =
            penniContentModule.checkout == null
                ? ''
                : getResource(
                      `${penniContentModule.checkout.type}-text-variation-title`,
                      ContentModelTypes.TEXT,
                      penniContentModule.checkout
                  )?.value;

        const subVariantTitle =
            penniContentModule.checkout == null
                ? ''
                : getResource(
                      `${penniContentModule.checkout.type}-text-variation-subtitle`,
                      ContentModelTypes.TEXT,
                      penniContentModule.checkout
                  )?.value;

        penniCheckoutModule.setOrderSummary([
            {
                title: variantTitle ?? `Købsforsikring`,
                subtitle: subVariantTitle ?? `Dækning for dine køb`,
                coverages: this.productSelectedVariation?.coverages,
                price: this.calculationSelectedVariation?.premium.yearly,
                type: MessageAutomationInsuranceTypesEnum.Other,
            },
        ]);
    }

    // quote
    public async createQuote(): Promise<void> {
        const params = penniInputModule.getInputsAsParameters;

        const request = {
            partnerId: penniCheckoutModule.partnerName.toLowerCase(),
            products: [
                {
                    productId: penniProductModule.product!.id,
                    productVariationId: this.productSelectedVariation!.id,
                    insuredObject: {
                        price: this.calculationSelectedVariation?.premium[this.selectedPremiumType],
                        currencyCode: this.currencyCode,
                    },
                },
            ],
            customer: {
                name: {
                    firstName: params['firstname'],
                    lastName: params['lastname'],
                },
                ssn: params['ssn'],
                contact: {
                    email: params['email'],
                    phone: params['phone']?.toString(),
                },
                /**
                 * We'll continue to mock some of the address payload as we have
                 * not defined how to fetch the address for the different languages
                 */
                payment: {
                    type: 'PPS',
                    bank: '123',
                    account: '123',
                    paymentFrequency: {
                        code: 'monthly',
                    },
                },
                address: {
                    address: params['address'],
                    postalCode: params['postalcode']?.toString(),
                    city: params['city'],
                    id: '123',
                    kvhx: '1',
                },
                consent: {
                    all: false,
                },
            },
            payment: {
                paymentFrequency: {
                    code: 'monthly',
                },
            },
            allowSendToEmail: penniCommonModule.allowSendToEmail,
            completeBasket: false,
        };

        penniCommonModule.setLoading(true);

        try {
            await penniCheckoutModule.postQuote(request);
        } catch (error) {
            throw error;
        } finally {
            penniCommonModule.setLoading(false);
        }
    }

    public get selectedPremiumType(): keyof Premium {
        return penniContentModule.checkout?.options.premiumType ?? 'yearly';
    }
}
