import {OptionItem} from '@penni/generic-api';
import {UnknownRecord} from '@penni/generic-api/build/utility';
import {Input, RadioItem} from './models';

export class InputSummary {
    public input: Input;
    public basketValue: unknown;

    public constructor(input: Input, value: unknown) {
        this.input = input;
        this.basketValue = value;
    }

    public set(): void {
        switch (this.input.type) {
            case 'text':
                if ((this.basketValue as UnknownRecord).address != null) {
                    this.input.summary = (this.basketValue as UnknownRecord).address as string;
                    return;
                }

                this.input.summary = `${this.basketValue}`;
                break;

            case 'radio':
                if (this.input.props?.items == null) {
                    return;
                }
                const selectedItem = (this.input.props.items as RadioItem[]).find(
                    (option) => option.value === (this.basketValue as boolean).toString()
                );
                this.input.summary = selectedItem?.resourceValue ?? '';
                break;

            case 'select':
                if (this.input.props?.options == null) {
                    return;
                }
                const item = (this.input.props.options as OptionItem[]).find(
                    (option) => option.code === (this.basketValue as string)
                );
                this.input.summary = item?.text ?? '';
                break;

            default:
                break;
        }
    }
}
