


































import {VueTelInput, VueTelInputCountryOption} from 'vue-tel-input';
import {Component, Mixins, Prop} from 'vue-property-decorator';
import Vue from 'vue';
import InputMixin from '../mixin';
import {penniInputModule} from '@/store';
import {Field} from 'buefy';
import isEmpty from 'lodash.isempty';
import {InputValues} from '@/store/models';

type PhoneValidationResponse = {
    country: VueTelInputCountryOption;
    countryCallingCode: string;
    countryCode: string;
    formatted: string;
    nationalNumber: string;
    number: string;
    valid: boolean;
};

Vue.use(Field);
Vue.use(VueTelInput);

@Component({components: {VueTelInput}})
export default class PenniPhone extends Mixins(InputMixin) {
    @Prop({required: false, type: String, default: ''}) defaultCountry!: string;
    @Prop({required: false, type: Array, default: () => []}) countries!: string[];

    public mounted() {
        penniInputModule.setInput({
            name: this.name,
            type: 'text',
            form: this.form,
            label: this.label,
            placeholder: this.placeholder,
            required: this.required ?? false,
            req: this.req,
            value: this.input?.value,
            valid: this.input?.valid,
            summary: this.input?.value != null ? (this.input?.value as string) : '',
            validationMessage: this.contentfulValidationMsg,
            overrideValidation: true,
        });
    }

    // Fires when validity of the phone number changes and when the component is mounted
    public validatePhoneInput(phoneObject: PhoneValidationResponse): void {
        if (this.input == null || isEmpty(phoneObject)) {
            return;
        }

        const newValue = phoneObject.number ?? '';

        penniInputModule.updateInput({
            ...this.input,
            value: newValue,
            summary: newValue,
            valid: phoneObject.valid,
        });
    }

    public get showValidationError(): boolean | undefined {
        return !this.input?.valid && this.input?.dirty;
    }

    public async onInput(number: string, phoneObject: PhoneValidationResponse): Promise<void> {
        if (this.input == null || isEmpty(phoneObject)) {
            return;
        }

        const newValue = phoneObject.number ?? '';

        penniInputModule.updateInput({
            ...this.input,
            value: newValue,
            summary: newValue,
            valid: phoneObject.valid,
            dirty: true,
        });

        this.valueChange(newValue);

        this.updateBasketInputValue();
    }

    public get model(): InputValues {
        return this.input?.value ?? '';
    }
}
