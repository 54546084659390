


































import ContentfulMixin from '../content/mixin';
import {ContentModelTypes} from '@penni/contentful-api';
import {Component, Mixins} from 'vue-property-decorator';

type ContactOption = {
    icon: string;
    resourceId: string;
    resourceType: ContentModelTypes;
};

@Component
export default class Footer extends Mixins(ContentfulMixin) {
    public get contactOptions(): ContactOption[] {
        return [
            {
                icon: 'phone',
                resourceId: 'footer-contact-info-phone',
                resourceType: ContentModelTypes.LINK,
            },
            {
                icon: 'email',
                resourceId: 'footer-contact-info-email',
                resourceType: ContentModelTypes.LINK,
            },
            {
                icon: 'chat',
                resourceId: 'footer-contact-info-chat',
                resourceType: ContentModelTypes.LINK,
            },
        ].filter((x) => this.resourceExists(x.resourceId, x.resourceType));
    }
}
