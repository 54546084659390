
















import {Component, Mixins, Prop} from 'vue-property-decorator';
import Vue from 'vue';
import {Dropdown} from 'buefy';
import InputMixin from '../mixin';
import {penniInputModule} from '../../store';
import {OptionItem} from '@penni/generic-api';

Vue.use(Dropdown);

@Component
export default class PenniDropdown extends Mixins(InputMixin) {
    @Prop({required: true, type: Array, default: () => []}) public options!: OptionItem[];
    @Prop({required: false, type: Boolean, default: false}) public underline!: boolean;

    public mounted(): void {
        const defaultOption = this.options.find((option: OptionItem) => option.default);

        penniInputModule.setInput({
            name: this.name,
            req: this.req,
            type: 'dropdown',
            value: defaultOption?.code ?? this.options[0].code,
            valid: true,
            form: this.form,
            required: this.required ?? false,
            summary: (defaultOption?.text ?? this.options[0].text) || '',
        });
    }

    public isValid(): boolean {
        return this.input != null && this.input.value !== '';
    }

    public onChange(value: string): void {
        const summary = this.options.find((op: OptionItem) => op.code === value);
        penniInputModule.updateInput({name: this.name, value, summary: summary?.text || '', required: this.required});
        this.valueChange(value);
        this.blur();
    }

    public get selectedText(): string | undefined {
        if (this.input == null || this.input.value == null || typeof this.input.value !== 'string') {
            return;
        }
        const value = this.input.value;
        const selectedItem = this.options.find((option) => option.code === value);
        return selectedItem != null && selectedItem.text != null ? selectedItem.text : value;
    }
}
