










import {Component, Vue, Prop} from 'vue-property-decorator';
import {Modal} from 'buefy';
import {penniCommonModule} from '../../store';

Vue.use(Modal);

@Component
export default class PenniModal extends Vue {
    @Prop({required: true, type: String, default: ''}) name!: string;

    public mounted(): void {
        penniCommonModule.addModal({name: this.name, active: false});
    }

    public get active(): boolean {
        return penniCommonModule.getModalById(this.name)?.active ?? false;
    }
    public set active(val: boolean) {
        penniCommonModule.toggleModal({name: this.name, active: val});
    }
}
