










import ContentfulMixin from '../content/mixin';
import {SidebarResource} from '@/store/models';
import {Component, Mixins} from 'vue-property-decorator';
import {penniContentModule, penniCheckoutModule} from '../store';
import {ContentModelTypes} from '@penni/contentful-api';

@Component
export default class MultipleContentBlock extends Mixins(ContentfulMixin) {
    public documents: SidebarResource[] = [];

    public beforeMount(): void {
        /**
         * For backwards compatibility the documentlist in the store will be used as the main list if it has been set.
         * If not, then documents will be fetched directly from Contentful.
         */
        if (this.documentList != null && this.documentList.length > 0) {
            this.documents = this.documentList;
        } else {
            let count = 0;
            while (this.resourceExists(`ipid-link-${count}`, ContentModelTypes.LINK)) {
                this.documents.push({id: `ipid-link-${count}`});
                count++;
            }
        }
    }

    public created(): void {
        if (penniContentModule.checkout == null) {
            throw new Error('There is no checkout in the Content store');
        }
    }

    public get documentList(): SidebarResource[] | null {
        return penniCheckoutModule.documentList;
    }
}
