export enum SignatureProviderEnum {
    DOCUSIGN = 'docusign',
}

export enum DocuSignEvents {
    // cancel (recipient cancels signing)
    CANCEL = 'cancel',
    // decline (recipient declines signing)
    DECLINE = 'decline',
    // exception (exception occurs)
    EXCEPTION = 'exception',
    // signing_complete (recipient completes signing)
    SIGNING_COMPLETE = 'signing_complete',
    // viewing_complete (recipient completes viewing the envelope)
    VIEWING_COMPLETE = 'viewing_complete',
}
