import {Datepicker} from 'buefy';
import {PluginObject, VueConstructor} from 'vue';
import PenniDatepicker from './Datepicker.vue';

export * from './Datepicker.vue';

export const PenniDatepickerPlugin: PluginObject<null> = {
    install(Vue: VueConstructor): void {
        Vue.use(Datepicker);
        Vue.component('PenniDatepicker', PenniDatepicker);
    },
};
