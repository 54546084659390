import {Input} from '@/store/models';
import isEmpty from 'lodash.isempty';

export class FormValidation {
    private readonly input: Input;

    public constructor(input: Input) {
        this.input = input;
    }

    public isValid(): boolean {
        switch (this.input.type) {
            case 'radio':
                return this.validateRadio();
            case 'number':
                return this.validateNumber();

            default:
                return this.validateDefault();
        }
    }

    private validateRadio(): boolean {
        if (!this.isRequired()) {
            return true;
        }

        if (this.isNotEmpty(this.input.props?.validOption)) {
            return this.input.value === this.input.props?.validOption;
        }

        return this.isNotEmpty(this.input.value);
    }

    private validateNumber(): boolean {
        if (!this.isRequired()) {
            return true;
        }

        if (typeof this.input.value !== 'number') {
            return false;
        }

        const min = this.input.props?.min as number | undefined;
        const max = this.input.props?.max as number | undefined;
        const numberValue = this.input.value;

        if (min != null && numberValue <= min) {
            return false;
        }

        if (max != null && numberValue >= max) {
            return false;
        }

        return true;
    }

    private validateDefault(): boolean {
        if (this.isRequired()) {
            return this.validateRequired();
        }

        return this.validateOptional();
    }

    private validateOptional(): boolean {
        if (this.isEmpty(this.input.value)) {
            return true;
        }

        if (this.hasPattern()) {
            return this.validateWithPattern();
        }

        return this.validateWithoutPattern();
    }

    private validateRequired(): boolean {
        if (this.isEmpty(this.input.value)) {
            return false;
        }

        if (!this.hasPattern()) {
            return this.validateWithoutPattern();
        }

        return this.validateWithPattern();
    }

    private validateWithPattern(): boolean {
        const regExp = new RegExp(this.input.pattern!, 'g');

        return regExp.test(this.input.value!.toString());
    }

    private validateWithoutPattern(): boolean {
        return !this.isEmpty(this.input.value);
    }

    private hasPattern(): boolean {
        return this.input.pattern != null && this.input.pattern !== '';
    }

    private isRequired(): boolean {
        return this.input.required ?? false;
    }

    private isEmpty(value: unknown): boolean {
        return isEmpty(value);
    }

    private isNotEmpty(value: unknown): boolean {
        return this.isEmpty(value) === false;
    }
}
