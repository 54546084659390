import {
    BasketResponse,
    CalculatedProductVariation,
    CalculatedProductVariationReference,
    CalculationProduct,
    CalculationProductResponse,
} from '@penni/generic-api';

declare type InputValues = string | number | Record<string, unknown>;

export interface OldBasketProduct {
    request: CalculationProduct;
    response: CalculationProductResponse<CalculatedProductVariationReference | CalculatedProductVariation>;
    selectedVariationId: string;
    meta?: {
        id: string;
        value: InputValues;
    }[];
}

export type OldBasketResponse = BasketResponse & {
    products: [OldBasketProduct];
};

/**
 * Transforms generic-api Baskets v0.1.3 or below to the v1.0.0 format
 * TODO: can be removed once widgets and backends all conform to either standard.
 */
export function transformBasket(basket: OldBasketResponse | BasketResponse): BasketResponse {
    if ((basket as BasketResponse).products[0].response.productPrices != null) {
        return basket;
    }

    const oldBasket = basket as OldBasketResponse;

    return {
        ...oldBasket,
        products: [
            {
                selectedVariationId: oldBasket.products[0].selectedVariationId,
                request: {
                    products: [oldBasket.products[0].request],
                },
                response: {
                    productPrices: [oldBasket.products[0].response],
                },
                meta: oldBasket.products[0].meta,
            },
        ],
    };
}
