

































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import {prism} from './analytics/plugin';
import {penniCheckoutModule, penniCommonModule, penniContentModule, penniInputModule, penniStepModule} from './store';
import {ContentModelTypes} from '@penni/contentful-api';
import {Titles} from './store/models';
import ContentfulMixin from './content/mixin';
import GenericSuccess from './views/GenericSuccess.vue';
import GenericError from './views/GenericError.vue';
import RecoveryError from './views/RecoveryError.vue';
import {setTheme} from './utils/theme';

import './style/_buefy.scss';
import '@mdi/font/scss/materialdesignicons.scss';
import {router} from '.';

@Component({components: {GenericSuccess, GenericError, RecoveryError}})
export default class PenniCheckout extends Mixins(ContentfulMixin) {
    /**
     * @description
     * Flag to enable/disable showing of the progress bar
     */
    @Prop({required: false, type: Boolean, default: true}) public readonly showProgress?: true;

    public mounted(): void {
        prism?.client?.visitor();
        prism?.client?.stepLoaded(1);
        prism?.setupImpressionObserver(this.$refs['penni-checkout'] as Element);
        setTheme(penniContentModule);

        this.updateInputsFromBasket();
    }

    private updateInputsFromBasket(): void {
        if (
            penniCheckoutModule.basketResponse != null &&
            (!penniCheckoutModule.basketRetrievalFailed || !penniCheckoutModule.basketMappedToInputs)
        ) {
            penniInputModule.updateInputsFromBasket(penniCheckoutModule.basketResponse.products[0]);
            penniCheckoutModule.setBasketMappedToInputs(true);
            penniStepModule.setAutoProceed(true);
        }
    }

    public goBack(): void {
        router.go(-1);
    }

    public get headerTitle(): Titles {
        return penniCommonModule.title;
    }

    public get didThrow(): boolean {
        return penniCommonModule.didThrow;
    }

    public get basketCompleted(): boolean {
        return penniCheckoutModule.basketCompleted;
    }

    public get recoveryErrorScreen(): boolean {
        return penniContentModule.checkout?.options.recoveryErrorScreen?.inputFieldName != null;
    }

    public get hasCoveragesOrFaq(): boolean {
        return (
            this.resourceExists('coverage-title-1', ContentModelTypes.TEXT) ||
            this.resourceExists('faq-title-1', ContentModelTypes.TEXT)
        );
    }

    public get hasSubtitle(): boolean {
        return this.resourceExists('header-sub-title', ContentModelTypes.TEXT);
    }

    public get hasGoBack(): boolean {
        return penniContentModule.checkout?.options.features?.backButton?.enabled ?? false;
    }

    public get isSuccess(): boolean {
        return this.basketCompleted && !this.didThrow;
    }
}
