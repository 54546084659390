import {
    BrandingEntry,
    ContentModelTypes,
    getResource,
    MediaResourceEntry,
    ProductEntry,
    RichTextResourceEntry,
} from '@penni/contentful-api';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {penniContentModule} from '../store';

/**
 * ContenfulMixin
 * Contains all shared Props, Methods etc. for interacting with CF.
 */
@Component
export default class ContentfulMixin extends Vue {
    @Prop({required: false, type: String}) public readonly label?: string;
    @Prop({required: false, type: String}) public readonly header?: string;
    @Prop({required: false, type: String}) public readonly placeholder?: string;
    @Prop({required: false, type: String}) public readonly validationMessage?: string;

    public get contentfulLabel(): string {
        if (this.label == null) {
            return '';
        }
        return this.getTextResource(this.label, 'no label');
    }

    public get contentfulHeader(): string {
        if (this.header == null) {
            return 'no header';
        }
        return this.getTextResource(this.header, 'no header');
    }

    public get contentfulPlaceholder(): string {
        if (this.placeholder == null) {
            return 'no placeholder';
        }
        return this.getTextResource(this.placeholder, 'no placeholder');
    }

    public get contentfulValidationMsg(): string | undefined {
        if (this.validationMessage == null) {
            return;
        }
        return this.getTextResource(this.validationMessage, 'no validation message');
    }

    public resourceExists(id: string, type: ContentModelTypes): boolean {
        switch (type) {
            case ContentModelTypes.TEXT:
                return this.getTextResource(id, 'null') !== 'null';
            case ContentModelTypes.LINK:
                return this.getLinkResource(id, 'null') !== 'null';
            default:
                return false;
        }
    }

    public getRawRichTextResource(id: string): RichTextResourceEntry | undefined {
        if (penniContentModule.checkout == null) {
            return undefined;
        }

        const resourceId = `${this.checkoutType}-${ContentModelTypes.RICH_TEXT}-${id}`;

        const resource = getResource<ContentModelTypes.RICH_TEXT>(
            resourceId,
            ContentModelTypes.RICH_TEXT,
            penniContentModule.checkout
        );

        return resource;
    }

    public getTextResource(id: string, fallback: string): string {
        if (penniContentModule.checkout == null) {
            throw new Error('There is no Checkout in the Store');
        }

        const resourceId = `${this.checkoutType}-${ContentModelTypes.TEXT}-${id}`;

        const resource = getResource<ContentModelTypes.TEXT>(
            resourceId,
            ContentModelTypes.TEXT,
            penniContentModule.checkout
        );

        if (resource == null) {
            return fallback;
        }

        return resource.value;
    }

    public getAnyTextResource(id: string): string {
        const richText = this.getRawRichTextResource(id);

        if (richText != null) {
            return richText.htmlString;
        }

        return this.getTextResource(id, '');
    }

    public getLinkResource(id: string, fallback: string): {url: string; text: string} | string {
        if (penniContentModule.checkout == null) {
            throw new Error('There is no Checkout in the Store');
        }

        const resourceId = `${this.checkoutType}-${ContentModelTypes.LINK}-${id}`;

        const resource = getResource<ContentModelTypes.LINK>(
            resourceId,
            ContentModelTypes.LINK,
            penniContentModule.checkout
        );

        if (resource == null) {
            return fallback;
        }

        return resource.value;
    }

    public getMediaResource(id: string): MediaResourceEntry | undefined {
        if (penniContentModule.checkout == null) {
            return;
        }

        const resourceId = `${this.checkoutType}-${ContentModelTypes.MEDIA}-${id}`;

        const resource = getResource<ContentModelTypes.MEDIA>(
            resourceId,
            ContentModelTypes.MEDIA,
            penniContentModule.checkout
        );

        if (resource == null) {
            return;
        }

        return resource;
    }

    public getTextResourceList(id: string): string[] {
        let i = 0;
        const nextId = (): string => {
            i++;
            return `${id}-${i}`;
        };
        let sequentialId = nextId();
        const result: string[] = [];

        while (this.resourceExists(sequentialId, ContentModelTypes.TEXT)) {
            try {
                result.push(this.getTextResource(sequentialId, ''));
            } catch (e) {
                console.error(e);
            } finally {
                sequentialId = nextId();
            }
        }

        return result;
    }

    public get partner(): BrandingEntry | undefined {
        if (penniContentModule.checkout == null) {
            return;
        }
        return penniContentModule.checkout.partner;
    }

    public get product(): ProductEntry | undefined {
        if (penniContentModule.checkout == null) {
            return;
        }
        return penniContentModule.checkout.product;
    }

    public get checkoutType(): string | undefined {
        if (penniContentModule.checkout!.type == null || penniContentModule.checkout!.type === '') {
            console.error('Checkout type is undefined or empty. Please check Contentful.');
            return;
        }

        return penniContentModule.checkout!.type;
    }
}
