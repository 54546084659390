
















import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import InputMixin from '../mixin';
import {penniInputModule} from '../../store';
import {Checkbox} from 'buefy';

Vue.use(Checkbox);

type SlotPropsData = {
    resourceId?: string;
};

@Component
export default class PenniCheckbox extends Mixins(InputMixin) {
    public optionResourceId?: string;

    @Prop({required: true, type: String}) public readonly value!: string;

    public mounted(): void {
        const slotPropsData = this.$slots.default?.[0].componentOptions?.propsData as SlotPropsData;
        if (slotPropsData != null) {
            this.optionResourceId = slotPropsData.resourceId || '';
        }

        penniInputModule.setInput({
            name: this.name,
            req: this.req,
            type: 'checkbox',
            value: '',
            valid: false,
            form: this.form,
            required: this.required ?? false,
            summary: this.summary,
        });
    }

    public isValid(): boolean {
        if (!this.required) {
            return true;
        }

        return (
            this.input != null &&
            this.input.value != null &&
            this.input.value !== '' &&
            (this.input.value as string).split(',').length !== 0
        );
    }

    @Watch('validity')
    public updateInputValidity(valid: boolean): void {
        if (this.input == null || this.input.value == null) {
            return;
        }

        penniInputModule.updateInput({
            name: this.name,
            value: this.input.value,
            summary: this.summary,
            required: this.required,
            valid: valid,
        });
    }

    public get validity(): boolean {
        return this.isValid();
    }

    public get model(): string[] {
        if (this.input == null || this.input.value == null) {
            return [];
        }

        return this.input.value.toString().split(',').filter(Boolean);
    }

    public set model(val: string[]) {
        penniInputModule.updateInput({
            name: this.name,
            value: val.join(','),
            summary: this.summary,
            required: this.required,
        });

        this.valueChange(val.join(','));
        this.blur();
    }

    public get summary(): string {
        return this.optionResourceId != null ? this.getAnyTextResource(this.optionResourceId) : '';
    }
}
