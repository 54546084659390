import {PluginObject, VueConstructor} from 'vue';
import {Select} from 'buefy';
import PenniSelect from './Select.vue';

export * from './Select.vue';

export const PenniSelectPlugin: PluginObject<null> = {
    install(Vue: VueConstructor): void {
        Vue.use(Select);
        Vue.component('PenniSelect', PenniSelect);
    },
};
