import * as GenericApi from '@penni/generic-api';
import {Coverage, OptionalCoverage} from '@penni/generic-api';
import {UnknownRecord} from '@penni/generic-api/build/utility';

export enum PaymentType {
    PPS = 'PPS', // Payment Processing Service (BS)
}

// Some partners will sell new cars that dont have a license plate
export enum NoIntegrationPartners {
    PS = 'polestar',
}

export interface ApiCancellationNew {
    type: GenericApi.CancellationType.NEW;
}

export interface ApiCancellationTransfer {
    type: GenericApi.CancellationType.TRANSFER;
    insuranceCompany: GenericApi.OptionItem;
    transferDate: GenericApi.OptionItem;
}

export type ApiCancellation = ApiCancellationNew | ApiCancellationTransfer;

export type ApiProductOptions = {
    /** driver experience */
    driverExperience?: GenericApi.OptionItem[];

    /** excess/deductible */
    excess?: GenericApi.OptionItem[];

    /** excess/deductible */
    deductible?: GenericApi.OptionItem[];

    /** number of claims */
    claimCount?: GenericApi.OptionItem[];

    /** mileage per year */
    mileage?: GenericApi.OptionItem[];

    /** value of car addons */
    addons?: GenericApi.OptionItem[];

    /** list of insurance companies */
    insuranceCompany?: GenericApi.OptionItem[];

    /** list of cancellation dates for transferred policies */
    transferDate?: GenericApi.OptionItem[];

    /** list of car makers */
    carMaker?: GenericApi.OptionItem[];

    /** list of car maker years */
    carMakerYear?: GenericApi.OptionItem[];

    /** list of car models */
    carModel?: GenericApi.OptionItem[];

    /** list of leasing companies */
    leasingCompany?: GenericApi.OptionItem[];

    /** list of first time payment sum */
    firstTimePaymentSum?: GenericApi.OptionItem[];

    /** list of tireAndWheelRimSum */
    tireAndWheelRimSum?: GenericApi.OptionItem[];
};

export class PremiumResponse {
    public monthly?: number;
    public quarterly?: number;
    public halfYearly?: number;
    public yearly?: number;
    public oneTimePayment?: number;
}

export class ApiCalculatedProductVariationReference {
    public id!: string;
    public premium!: PremiumResponse;
}

export class ApiCalculationProductResponse {
    public description!: string;
    public variations!: ApiCalculatedProductVariationReference[];
    public vehicleVariants!: GenericApi.OptionItem[];
    public odometerReading!: number;
}

export class ApiProductPrices {
    public productPrices!: ApiCalculationProductResponse[];
}
export interface Payment {
    /** payment type */
    type: PaymentType;

    /** bank registration number */
    bank: string;

    /** bank account number */
    account: string;
}

export class ApiCustomerName {
    public firstName!: string;
    public lastName!: string;
}

export class ApiCustomerContact {
    public email!: string;
    public phone!: string;
}

export class ApiCustomerAddress {
    public kvhx!: string;
    public address!: string;
    public postalCode!: string;
    public city!: string;
}

export class ApiCustomerConsent {
    public all!: boolean;
}

export class ApiCustomer {
    public ssn!: string;
    public name!: ApiCustomerName;
    public contact!: ApiCustomerContact;
    public address!: ApiCustomerAddress;
    public consent!: ApiCustomerConsent;
    public isMember?: boolean;
    public age?: number;
}

export enum ApiProduct {
    DEFAULT = 'motor',
}

export type ApiProductParameters = {
    /** driver experience */
    driverExperience?: GenericApi.OptionItem;

    /** excess/deductible */
    deductible?: GenericApi.OptionItem;

    /** excess/deductible */
    excess?: GenericApi.OptionItem;

    /** number of claims */
    claimCount?: GenericApi.OptionItem;

    /** mileage per year */
    mileage?: GenericApi.OptionItem;

    /** value of car addons */
    addons?: GenericApi.OptionItem;

    /** odometer reading */
    odometerReading?: number;
};

export type ApiInsuredObject = {
    /** registration number */
    regno?: string;

    /**
     * The car maker
     */
    carMaker?: GenericApi.OptionItem;

    /**
     * The car maker year
     */
    carMakerYear?: GenericApi.OptionItem;

    /** model variant id */
    modelVariantId?: GenericApi.OptionItem;

    leasing?: string;

    fuelType?: string;

    transferDate?: string;

    insuranceCompany?: string;

    /** deductible */
    deductible?: {
        code: string;
    };

    /** mileage per year */
    mileage?: {
        code: string;
    };
};

export class ApiQuoteProduct {
    public productId!: ApiProduct;
    public productVariationId!: string;
    public partnerId!: string;
    public parameters!: ApiProductParameters;
    public insuredObject!: ApiInsuredObject;
    public campaignCode?: string;
    public premium?: number;
    public cancellation!: ApiCancellation;
}

export class ApiQuoteRequest {
    public products!: ApiQuoteProduct[];
    public customer!: ApiCustomer;
    public payment!: Payment;
    public affiliates?: ApiQuoteAffiliates;
    public basketId?: string;
}

export class ApiPartialCustomerContact {
    public phone?: string;
    public email?: string;
}

export class ApiPartialCustomer {
    public ssn?: string;
    public age?: number;
    public address!: ApiCustomerAddress;
    public contact?: ApiPartialCustomerContact;
    public phone?: string;
}

export class ApiCalculationProduct {
    public productId!: ApiProduct;
    public parameters!: ApiProductParameters;
    public insuredObject!: ApiInsuredObject;
}

export class ApiCalculationSimpleRequest extends ApiCalculationProduct {
    public partnerId!: string;
    public campaignCode?: string;
    public customer!: ApiPartialCustomer;
}

export class ApiCalculationRequest {
    public partnerId!: string;
    public campaignCode?: string;
    public customer!: ApiPartialCustomer;
    public products!: ApiCalculationProduct[];
}

export class ApiQuoteAffiliate {
    public network!: string;
    public parameters!: Record<string, unknown>;
}

export class ApiQuoteAffiliates {
    public networks!: ApiQuoteAffiliate[];
}

export class ApicardCustomer {
    public ssn!: string;
    public address!: string;
    public contact!: ApiCustomerContact;
    public name!: ApiCustomerName;
}

type currencyCodes = 'DKK' | 'SEK' | 'NOK';

export type ApiCardInsuredObject = {
    cardType: string;
    paidWithCard?: string;
    numberOfTravellers?: number;
    tripLength?: string;
    tripPrice?: number;
    travellers?: Travellers[];
};

export interface CardCalculationRequest {
    productId: string;
    partnerId: string;
    productVariationId: string;
    currencyCode: currencyCodes;
    customer: ApicardCustomer;
    parameters: Record<string, unknown>;
    insuredObject: ApiCardInsuredObject;
    optionalCoverages: OptionalCoverage<UnknownRecord>[];
}

export type Travellers = {
    index: number;
    name: ApiCustomerName;
};

type CoverageOptions = {
    optional?: boolean;
    defaultToggleValue?: boolean;
};

export type TrygCardApiCoverage = Coverage<string, string, null, null, CoverageOptions>;
