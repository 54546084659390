






import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {penniCheckoutModule, penniInputModule} from '../store';
import {Input} from '@/store/models';

@Component
export default class PenniConditional extends Vue {
    @Prop({required: true}) public readonly input!: Input & {[key: string]: unknown};
    @Prop({required: true}) public readonly needle!: string;
    @Prop({required: true}) public readonly value!: unknown;

    @Watch('show')
    public async valueWatcher(showConditional: boolean | undefined): Promise<void> {
        if (showConditional === true) {
            // Waiting for next tick or this.$children will be empty
            await Vue.nextTick();
        }

        this.$children.forEach((child: Vue) => {
            /* istanbul ignore next */
            if (child.$vnode.componentOptions?.tag === 'PenniControl') {
                child.$children.forEach((inputChild: Vue & {input?: {name: string}}) => {
                    if (inputChild.input == null) {
                        return;
                    }
                    if (showConditional) {
                        const inputState = penniInputModule.getInput(inputChild.input.name);
                        const basket = penniCheckoutModule.basketResponse;
                        if (inputState == null || inputState.req == null || basket == null) {
                            return;
                        }

                        const basketInputValue = penniInputModule.getInputBasketValue(
                            basket.products[0],
                            inputState.req
                        );
                        if (basketInputValue != null) {
                            penniInputModule.updateInputFromBasket({input: inputState, basketInputValue});
                        }
                    } else {
                        penniInputModule.deleteInput(inputChild.input.name);
                    }
                });
            }
        });
    }

    public get show(): boolean | undefined {
        if (this.input == null || this.input[this.needle] == null) {
            return;
        }

        return this.input[this.needle] === this.value;
    }

    public get dirty(): boolean | null {
        return this.input.dirty == null ? null : this.input.dirty;
    }
}
