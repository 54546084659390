import {Action, Module, VuexModule} from 'vuex-module-decorators';
import {Premium} from '@penni/generic-api';
import {PaymentProviderEnum} from './models';
import {penniHttpClient} from '@/plugin';

export const PAYMENT_MODULE_NAMESPACE = 'paymentModule';

@Module({
    name: PAYMENT_MODULE_NAMESPACE,
    stateFactory: true,
    namespaced: true,
})
export class PaymentModule extends VuexModule {
    @Action
    public async getProviders(): Promise<PaymentProviderEnum[]> {
        try {
            const requestUrl = new URL(`${penniHttpClient.defaults.baseURL}/payment/providers`);

            const {data} = await penniHttpClient.get<PaymentProviderEnum[]>(requestUrl.toString());

            return data;
        } catch (error) {
            console.error('failed to fetch payment provider checkout URL', error);
            return [];
        }
    }

    /**
     *
     * @param basketId Should create a payment object, and return a payment initialized URL
     * @see https://developers.nets.eu/nets-easy/en-EU/docs/web-integration/integrate-nets-easy-checkout-on-your-website-hosted/#build-step-2-create-a-payment-object-backend
     * @returns The URL the frontend needs to redirect to
     */
    @Action
    public async getPaymentUrl(payload: {
        paymentProvider: PaymentProviderEnum;
        basketId: string;
        transactionId: string;
        premiumType: keyof Premium;
        returnUrl: string;
    }): Promise<string | undefined> {
        try {
            const requestUrl = new URL(
                `${penniHttpClient.defaults.baseURL}/payment/start/${payload.basketId}/${payload.transactionId}`
            );

            const {data} = await penniHttpClient.post<string>(requestUrl.toString(), {
                paymentProvider: payload.paymentProvider,
                periodicity: payload.premiumType,
                returnUrl: payload.returnUrl,
            });

            return data;
        } catch (error) {
            console.error('failed to fetch payment provider checkout URL', error);
        }
    }
}
