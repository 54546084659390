import Vue, {PluginObject, VueConstructor} from 'vue';
import ContentBlock from './ContentBlock.vue';
import CoveragesAndFaqs from './CoveragesAndFaqs.vue';
import Footer from './Footer.vue';
import Header from './Header.vue';
import Hero from './landingPage/Hero.vue';
import ImageWithText from './landingPage/ImageWithText.vue';
import ProductSelection from './landingPage/ProductSelection.vue';
import LandingPage from './landingPage/PenniLandingPage.vue';
import Usp from './landingPage/Usp.vue';
import Widget from './landingPage/Widget.vue';
import Loader from './Loader.vue';
import PenniModal from './modal/Modal.vue';
import PenniModalTrigger from './modal/ModalTrigger.vue';
import MultipleContentBlock from './MultipleContentBlock.vue';
import PaymentOption from './PaymentOption.vue';
import PaymentProvider from './PaymentProvider.vue';
import SignatureProvider from './SignatureProvider.vue';
import ProgressBar from './ProgressBar.vue';
import SaveBasketModal from './SaveBasketModal.vue';
import StickySummary from './StickySummary.vue';
import SummaryOrder from './SummaryOrder.vue';
import SummaryOrderItem from './SummaryOrderItem.vue';
import SummaryProgress from './SummaryProgress.vue';
import PaymentSuccess from './PaymentSuccess.vue';
import PaymentError from './PaymentError.vue';

export * from './helpers';

export const CheckoutLayoutPlugin: PluginObject<null> = {
    install(Vue: VueConstructor): void {
        Vue.component('ContentBlock', ContentBlock);
        Vue.component('CoveragesAndFaqs', CoveragesAndFaqs);
        Vue.component('MultipleContentBlock', MultipleContentBlock);
        Vue.component('PaymentOption', PaymentOption);
        Vue.component('PaymentProvider', PaymentProvider);
        Vue.component('SignatureProvider', SignatureProvider);
        Vue.component('SummaryProgress', SummaryProgress);
        Vue.component('SummaryOrder', SummaryOrder);
        Vue.component('SummaryOrderItem', SummaryOrderItem);
        Vue.component('SaveBasketModal', SaveBasketModal);
        Vue.component('StickySummary', StickySummary);
        Vue.component('Loader', Loader);
        Vue.component('Header', Header);
        Vue.component('Footer', Footer);
        Vue.component('Hero', Hero);
        Vue.component('Widget', Widget);
        Vue.component('ImageWithText', ImageWithText);
        Vue.component('Usp', Usp);
        Vue.component('PenniLandingPage', LandingPage);
        Vue.component('ProgressBar', ProgressBar);
        Vue.component('PenniModal', PenniModal);
        Vue.component('PenniModalTrigger', PenniModalTrigger);
        Vue.component('ProductSelection', ProductSelection);
    },
};
/**
 * Exporting landing page here to be used in Customer routing implementations.
 */
export const PenniLandingPage = Vue.extend(LandingPage);
export const PenniPaymentSuccess = Vue.extend(PaymentSuccess);
export const PenniPaymentError = Vue.extend(PaymentError);
