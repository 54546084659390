

















import {Component, Vue} from 'vue-property-decorator';
import {Loading} from 'buefy';
import {penniCommonModule} from '../store';

Vue.use(Loading);

@Component
export default class Loader extends Vue {
    public get isLoading(): boolean {
        return penniCommonModule.loading;
    }
}
