







import {ContentModelTypes} from '@penni/contentful-api';
import ResourceMixin from './mixin';
import ContentfulMixin from '../content/mixin';
import {Component, Prop, Mixins} from 'vue-property-decorator';
import {penniContentModule} from '../store';

@Component
export default class TextResource extends Mixins(ResourceMixin, ContentfulMixin) {
    public resourceType: ContentModelTypes.TEXT = ContentModelTypes.TEXT;

    @Prop({required: true, type: String}) public resourceId!: string;
    @Prop({required: false, type: Object}) public variablesToReplace!: Record<string, string>;
    @Prop({required: false, type: Boolean, default: false}) public printHtml!: boolean;

    public created(): void {
        if (penniContentModule.checkout == null) {
            throw new Error('There is no checkout in the Content store');
        }
    }

    public get resourceText(): string | undefined {
        const resource = this.getTextResource(this.resourceId, `missing resource: ${this.resourceId}`);

        if (this.variablesToReplace && resource.indexOf('missing') < 0) {
            return this.replaceVariables(resource, this.variablesToReplace);
        }

        return resource;
    }
}
