


























import {penniContentModule} from '../../store';
import {Component, Mixins} from 'vue-property-decorator';
import ContentfulMixin from '../../content/mixin';
import {ContentModelTypes, CustomerJourneyCheckoutOptions, CustomerJourneyOptions} from '@penni/contentful-api';
import {setTheme} from '../../utils/index';

import '../../style/_buefy.scss';

@Component
export default class PenniLandingPage extends Mixins(ContentfulMixin) {
    public componentNumbers: Map<string, number> = new Map<string, number>();

    public mounted(): void {
        setTheme(penniContentModule);
    }

    public get options(): CustomerJourneyOptions<CustomerJourneyCheckoutOptions> {
        return penniContentModule.options;
    }

    public get layoutConfig(): Record<string, unknown>[] {
        if (this.options.features?.landingPage?.options == null) {
            return [];
        }

        return this.options.features.landingPage.options.layout.filter((val) => val.id != null && val.id !== '');
    }

    public get hasCoveragesOrFaq(): boolean {
        return (
            this.resourceExists('coverage-title-1', ContentModelTypes.TEXT) ||
            this.resourceExists('faq-title-1', ContentModelTypes.TEXT)
        );
    }

    public get productId(): string | undefined {
        return penniContentModule.checkout?.options?.product;
    }
}
