





























import {Component, Mixins, Vue, Prop} from 'vue-property-decorator';
import InputMixin from '../mixin';
import {penniInputModule} from '../../store';
import {Field, Numberinput} from 'buefy';

Vue.use(Field);
Vue.use(Numberinput);

@Component
export default class PenniNumber extends Mixins(InputMixin) {
    /**
     * @description
     * Activates or deactives number controls. More info can be found here: https://buefy.org/documentation/numberinput.
     */
    @Prop({required: false, type: Boolean, default: false}) public readonly controls?: boolean;
    /**
     * @description
     * If this is set to true, then normal validation will not be used.
     * If validation needs to be shown or set, then it has to be set manually via PenniInputModule functions.
     */
    @Prop({required: false, type: Boolean, default: false}) public readonly disableValidation?: boolean;
    @Prop({required: false, type: Number}) public readonly min?: number;
    @Prop({required: false, type: Number}) public readonly max?: number;

    public mounted(): void {
        penniInputModule.setInput({
            name: this.name,
            form: this.form,
            req: this.req,
            type: 'number',
            valid: this.isValid(),
            value: undefined, // otherwise it validates immediatley on load
            required: this.required,
            label: this.label,
            validationMessage: this.contentfulValidationMsg,
            placeholder: this.placeholder,
            props: {...this.$props},
            summary: '',
        });
    }

    public isValid(): boolean {
        if (this.disableValidation) {
            return true;
        }

        return this.inputEl?.validity.valid === true;
    }

    public get model(): number | undefined {
        if (this.input == null || typeof this.input.value !== 'number') {
            return undefined;
        }
        return this.input.value;
    }

    public set model(value: number | undefined) {
        // buefy has an "issue" where an empty input field will be set to the min value
        let newValue: number | undefined | string = value;
        if (this.min != null && Number(value) <= this.min) {
            newValue = '';
        }

        penniInputModule.updateInput({
            name: this.name,
            value: newValue,
            summary: `${newValue}`,
            required: this.required,
        });

        this.valueChange(String(value));
    }
}
