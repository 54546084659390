

































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import * as Api from '@penni/tryg-children-api';
import {
    penniInputModule,
    penniProductModule,
    penniCheckoutModule,
    penniContentModule,
    penniCommonModule,
} from '@/store';
import {OptionItem, Premium} from '@penni/generic-api';
import {getResource, ContentModelTypes} from '@penni/contentful-api';
import cloneDeep from 'lodash.clonedeep';
import {MessageAutomationInsuranceTypesEnum} from '@/store/models/message';
import {Input} from '@/store/models';

interface ContentfulOptions {
    steps?: {
        askIsMember: boolean;
    };
}

@Component
export default class ChildView extends Vue {
    public paymentFrequencyInputValue?: string = this.options?.paymentFrequency?.[0].code;

    public async created(): Promise<void> {
        // ensure prices in the store are for the total insured children
        await this.makeCalculation();

        penniCheckoutModule.setSelectedPremiumType('monthly');

        penniCheckoutModule.setPartnerName(penniContentModule.checkout?.partner?.name || '');

        this.setOrderSummary();
        /**
         * if we want to enable sending emails
         * requires email infrastructure set up
         */
        penniCommonModule.setAllowSendToEmail(true);

        this.setSidebarData();
    }

    public get product() {
        return penniProductModule.product;
    }

    @Watch('product')
    public updateAfterGettingProduct(): void {
        this.setOrderSummary();
    }

    public setOrderSummary(): void {
        const selectedPremiumType = penniCheckoutModule.selectedPremiumType;
        const selectedVariationId = penniCheckoutModule.basketSelectedVariation?.id;
        const selectedVariation = penniCheckoutModule.calculationResponse?.productPrices[0].variations.find(
            (v) => v.id === selectedVariationId
        );
        const variationCoverages = penniProductModule.variations.find((v) => v.id === selectedVariationId)?.coverages;

        const variantTitle =
            penniContentModule.checkout == null
                ? ''
                : getResource(
                      `${penniContentModule.checkout.type}-text-variation-title-${
                          penniCheckoutModule.basketSelectedVariation?.id ?? ''
                      }`,
                      ContentModelTypes.TEXT,
                      penniContentModule.checkout
                  )?.value;

        const variantSubtitle = (id: string) => {
            return penniContentModule.checkout == null
                ? ''
                : getResource(
                      `${penniContentModule.checkout.type}-text-variation-subtitle-${id}`,
                      ContentModelTypes.TEXT,
                      penniContentModule.checkout
                  )?.value;
        };

        penniCheckoutModule.setOrderSummary([
            {
                title: `Børneforsikring ${variantTitle}`,
                subtitle: `${this.numberOfChildren} ${
                    this.numberOfChildren === 1 ? variantSubtitle('child') : variantSubtitle('children')
                }`,
                coverages: variationCoverages,
                price: selectedVariation?.premium[selectedPremiumType],
                type: MessageAutomationInsuranceTypesEnum.Child,
            },
        ]);
    }

    public setSidebarData(): void {
        penniCheckoutModule.setDocumentList([
            {
                id: 'ipid-link',
            },
        ]);
        penniCheckoutModule.setSidebarSocialProof([
            {
                id: 'social-proof',
            },
        ]);
    }

    public setPrice(event: InputEvent): void {
        const newFrequency = event != null ? (event.target as HTMLInputElement).value : 'yearly';
        penniCheckoutModule.setSelectedPremiumType(newFrequency as keyof Premium);

        this.setOrderSummary();
    }

    // calculation
    public async makeCalculation(): Promise<void> {
        if (penniCheckoutModule.basketResponse?.products != null) {
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            const basketRequest = penniCheckoutModule.basketResponse.products[0].request.products[0] as any;
            const calculationData = {
                productId: basketRequest.productId,
                insuredObject: {},
                parameters: {
                    numberOfChildren: basketRequest.parameters.numberOfChildren,
                    getTotal: true, // so we get the total price for all children
                },
                variationId: penniCheckoutModule.basketSelectedVariation?.id || 'minimum',
                customer: {},
            };
            await penniCheckoutModule.postCalculate({
                products: [calculationData],
                partnerId: basketRequest.partnerId,
                // eslint-disable-next-line
            } as any);
        }
    }

    // quote
    public async createQuote(): Promise<void> {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const calculationRequest = cloneDeep(penniCheckoutModule.calculationRequest)?.products[0] as any;
        const params = penniInputModule.getInputsAsParameters;
        const basketSelectedVariation = penniCheckoutModule.basketSelectedVariation;

        if (basketSelectedVariation == null) {
            return;
        }

        const customerAddress = params['address'] as Api.ApiCustomerAddress;
        const paymentFrequency: OptionItem = {
            code: `${params['paymentFrequency']}`,
        };
        const products: Api.ApiQuoteProduct[] = [];
        // v-for what was used to create the dom elements is 1-based - https://vuejs.org/v2/guide/list.html#v-for-with-a-Range
        for (let index = 1; index <= this.numberOfChildren; index++) {
            products.push({
                productId: calculationRequest.productId,
                productVariationId: basketSelectedVariation.id,
                insuredObject: {
                    ssn: `${params[`child-${index}-socialSecurityNumber`]}`,
                    name: {
                        firstName: `${params[`child-${index}-firstname`]}`,
                        lastName: `${params[`child-${index}-lastname`]}`,
                    },
                },
            });
        }

        const request: Api.ApiQuoteRequest = {
            partnerId: calculationRequest.partnerId,
            products,
            customer: {
                name: {
                    firstName: `${params['firstname']}`,
                    lastName: `${params['lastname']}`,
                },
                ssn: `${params['socialSecurityNumber']}`.replace(/-/g, ''),
                contact: {
                    email: `${params['email']}`,
                    phone: `${params['phone']}`,
                },
                address: {...customerAddress, kvhx: '1'},
                consent: {
                    all: params['marketingConsent'] === 'true',
                },
            },
            payment: {
                type: Api.PaymentType.PPS,
                bank: `${params['bank']}`,
                account: `${params['banknumber']}`,
                paymentFrequency: paymentFrequency,
            },
        };
        await penniCheckoutModule.postQuote(request);
    }

    // computed
    public get numberOfChildren(): number {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const calculationRequest = penniCheckoutModule.calculationRequest as any;
        return parseInt(calculationRequest.products[0].parameters.numberOfChildren.code);
    }

    public get stepTwoHeader(): string {
        return this.numberOfChildren === 1 ? 'step-2-header-single' : 'step-2-header-multiple';
    }

    public get askIsMember(): boolean {
        return (penniContentModule.checkout?.options as ContentfulOptions)?.steps?.askIsMember ?? true;
    }

    public get isMember(): boolean | undefined {
        const value = (penniInputModule.getInput('member')?.value as string) ?? '';
        return !value.length ? undefined : value === 'true' ? true : false;
    }

    public get radio(): Input | undefined {
        return penniInputModule.getInput('existingInsurrance');
    }

    public get options(): Api.ApiProductOptions | null {
        return penniProductModule.options;
    }

    public get firstName(): string {
        return penniInputModule.getInput('firstname')?.value?.toString() ?? '';
    }

    // TODO: This needs to be adjusted to reflect changes in the sales demo, which is a reference implementation
    public get email(): string {
        const emailInput = penniInputModule.getInput('email');
        if (emailInput?.valid) {
            return emailInput.value?.toString() || '';
        }
        return '';
    }

    // watchers
    @Watch('firstName')
    public setCheckoutFirstName(): void {
        penniCheckoutModule.setFirstName(this.firstName);
    }
}
