import {penniCheckoutModule, penniContentModule} from '@/store';
import {Component, Vue} from 'vue-property-decorator';
import {CalculationProduct, Premium} from '@penni/generic-api';

export type CalculationProductWithCancellation = CalculationProduct & {
    cancellation?: {
        paymentFrequency?: Record<string, unknown>;
    };
};

/**
 * ViewsMixin
 * Contains all shared Props, Methods etc. for Views.
 */
@Component
export default class ViewsMixin extends Vue {
    public setSelectedPremium(): void {
        /**
         * premiumType defined in CF options takes precedence over flow selections
         * meaning if we want the user to be able choose we should not set it
         * in the CF options
         */
        if (this.productPremiumType == null) {
            const basket = penniCheckoutModule.basketResponse?.products[0].request
                .products[0] as CalculationProductWithCancellation;
            if (basket?.cancellation?.paymentFrequency?.code != null) {
                penniCheckoutModule.setSelectedPremiumType(basket?.cancellation.paymentFrequency.code as keyof Premium);
            }
        } else {
            penniCheckoutModule.setSelectedPremiumType(this.productPremiumType);
        }
    }

    public get productPremiumType(): keyof Premium | undefined {
        return penniContentModule.checkout?.options.premiumType;
    }
}
