






























import {Component, Mixins} from 'vue-property-decorator';
import {penniStepModule} from '../store';
import {Step} from '@/store/stepModule';
import ContentfulMixin from '../content/mixin';

@Component
export default class ProgressBar extends Mixins(ContentfulMixin) {
    public stepContentfulHeader(resourceId: string): string {
        return this.getTextResource(resourceId, 'no header');
    }

    public get steps(): Step[] {
        return penniStepModule.steps;
    }

    public get activeStep(): number {
        return penniStepModule.currentStep;
    }

    public get partnerName(): string {
        return this.getTextResource('progress-bar-partner', 'Missing resource: progress-bar-partner');
    }
}
