import {
    CheckoutEntry,
    CustomerJourneyCheckoutOptions,
    CustomerJourneyOptions,
    getCheckout,
    getCheckoutFromFile,
} from '@penni/contentful-api';
import axios from 'axios';
import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {penniCheckoutModule, penniCommonModule, penniProductModule} from '.';
import isEmpty from 'lodash.isempty';

export const CONTENT_MODULE_NAMESPACE = 'contentModule';

@Module({
    name: CONTENT_MODULE_NAMESPACE,
    stateFactory: true,
    namespaced: true,
})
export class ContentModule extends VuexModule {
    public checkout: CheckoutEntry | null = null;

    // TODO: When we migrate to CustomerJourney we should use that instead of this.
    public get options(): CustomerJourneyOptions<CustomerJourneyCheckoutOptions> {
        if (this.checkout == null) {
            return {checkoutUrl: '', checkoutId: ''};
        }
        return this.checkout.options;
    }

    @Mutation
    public setCheckout(payload: CheckoutEntry): void {
        this.checkout = payload;
    }

    @Mutation
    public resetState(): void {
        this.checkout = null;
    }

    @Action
    public async fetchCheckout(payload: {
        experimentSeed: string;
        checkoutId?: string;
        contentfulData?: string;
        searchParams?: Record<string, string>;
    }): Promise<void> {
        try {
            const options = {
                space: process.env.VUE_APP_CF_SPACE,
                accessToken: process.env.VUE_APP_CF_TOKEN,
                environment: process.env.VUE_APP_CF_ENVIRONMENT,
            };
            let checkout: CheckoutEntry;

            if (payload.contentfulData == null) {
                checkout = await getCheckout(
                    options,
                    axios,
                    payload.checkoutId,
                    payload.experimentSeed,
                    payload.searchParams
                );
            } else if (payload.checkoutId != null) {
                checkout = await getCheckoutFromFile(
                    JSON.parse(payload.contentfulData),
                    payload.checkoutId,
                    payload.experimentSeed
                );
            } else {
                throw new Error('Cannot get Checkout');
            }

            this.context.commit('setCheckout', checkout);

            penniCheckoutModule.setPartnerName(checkout.partner?.name || '');

            /**
             * Fetch product with options and additional options
             * This is put here because they need to be fetched right after the Checkout,
             * and using a route guard is not enough for our dev environment
             */
            if (checkout.options != null && checkout.options.productRequestMethod != null) {
                await penniProductModule.fetchProduct(checkout.options.productRequestMethod);
            } else {
                await penniProductModule.fetchProduct();
            }

            // If the basket does not exist or has already been mapped to the inputs do nothing
            // stop any automated email reminders assigned to this email address
            if (
                penniCheckoutModule.basketResponse != null &&
                !penniCheckoutModule.basketRetrievalFailed &&
                penniCommonModule.allowSendToEmail &&
                !isEmpty(penniCheckoutModule.basketEmail)
            ) {
                await penniCheckoutModule.stopBasketReminders({email: penniCheckoutModule.basketEmail});
            }
        } catch (error) {
            console.error(error);
            this.context.commit('commonModule/setDidThrow', {error, didThrow: true}, {root: true});
            throw new Error(`could not fetch checkout with id "${payload.checkoutId}"`);
        }
    }
}
