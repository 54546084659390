






import {Component, Prop, Vue} from 'vue-property-decorator';
import {penniProductModule} from '../../store';

/*
 * This class is added to enable dynamically initializing the PenniDropdown based on options returned from an API call.
 * Those options cannot be directly sent via arrays using v-bind, so a middle layer is added to convert an option name
 * into an array of options.
 */

@Component
export default class PenniDropdownInitializer extends Vue {
    @Prop({required: true, type: String, default: ''}) name!: string;
    @Prop({required: true, type: String, default: ''}) form!: string;
    @Prop({required: false, type: String, default: ''}) req?: string;
    @Prop({required: true, type: String, default: ''}) option!: string;

    public get options(): Record<string, unknown>[] {
        return penniProductModule.getOption(this.option) as Record<string, unknown>[];
    }
}
