















































import {Component, Prop, Mixins, Watch} from 'vue-property-decorator';
import {penniStepModule, penniInputModule, penniCommonModule, penniCheckoutModule, penniContentModule} from '../store';
import ContentfulMixin from '../content/mixin';
import PenniPrice from '../layout/Price.vue';
import {prism} from '../analytics/plugin';
import StepMixin from './mixin';
import {Input} from '@/store/models';
import {RichTextResourceEntry} from '@penni/contentful-api';

export enum StepType {
    REGULAR = 'regular',
    PAYMENT = 'payment',
    MANUAL = 'manual',
}

@Component({
    components: {PenniPrice},
})
export default class PenniStepItem extends Mixins(ContentfulMixin, StepMixin) {
    public summaryResource!: RichTextResourceEntry | undefined;

    @Prop({required: true, default: 'Step header', type: String}) public readonly header!: string;
    @Prop({required: true, default: 0, type: Number}) public readonly step!: number;
    @Prop({required: false, default: '', type: String}) public readonly form!: string;
    @Prop({
        required: false,
        default: null,
        type: Function,
    })
    public readonly onNext!: () => Promise<void> | null;
    @Prop({required: false, default: true, type: Boolean}) public readonly icon!: boolean;
    @Prop({required: false, default: StepType.REGULAR, type: String}) public readonly type!: string;
    @Prop({required: false, type: String}) public readonly nextButtonId!: string;

    public created(): void {
        if (penniContentModule.checkout == null) {
            throw new Error('There is no checkout in the Content store');
        }
        this.summaryResource = this.getRawRichTextResource(`step-${this.step}-summary`);
    }

    public mounted(): void {
        penniStepModule.addStep({header: this.header, step: this.step, dirty: false, partialSummary: false});
    }

    public async goToNextStep(): Promise<void> {
        prism?.client?.stepCompleted(this.currentStep);

        if (!this.formIsValid) {
            this.validateInputs();

            this.$nextTick(() => {
                this.focusFirstInvalidInput();
            });

            return;
        }

        if (this.onNext != null) {
            penniCommonModule.setLoading(true);

            await this.onNext();

            penniCommonModule.setLoading(false);
        }

        await penniStepModule.nextStep();

        const hidden = penniStepModule.getStep(this.step)?.partialSummary;

        penniStepModule.updateStep({
            header: this.header,
            step: this.step,
            dirty: true,
            partialSummary: hidden === undefined ? false : hidden,
        });
    }

    public async goToStep(step: number): Promise<void> {
        if (!this.dirty) {
            return;
        }
        penniStepModule.setStep(step);
    }

    public get summaryProps(): Record<string, Record<string, string>> {
        if (this.showStepSummary === true) {
            const summaryItems = this.summaryResource?.htmlString.match(/\{([a-zA-Z0-9.-]+)\}/g);
            const formInputs = penniInputModule.getInputsByForm(this.form);
            const replacementVars: Record<string, string> = {};

            if (summaryItems == null || formInputs == null) {
                return {};
            }
            summaryItems.forEach((item: string) => {
                const formInput = formInputs.find((input: Input) => input.name === item.replace(/\{|\}/g, ''));

                replacementVars[item] = formInput?.value === '' || formInput?.value == null ? '' : formInput.summary;
            });

            return {variablesToReplace: replacementVars};
        }
        return {};
    }

    public get showCheckmarkIcon(): boolean {
        return this.icon && this.formIsValid && this.dirty;
    }

    public get currentStep(): number {
        return penniStepModule.currentStep;
    }

    public get isCurrentStep(): boolean {
        return this.currentStep === this.step;
    }

    public get isPartialSummary(): boolean {
        const step = penniStepModule.getStep(this.step);
        if (step == null) {
            return false;
        }
        return step.partialSummary;
    }
    public get buttonTextId(): string {
        return this.nextButtonId || 'step-next';
    }

    public get formIsValid(): boolean {
        /**
         * A form is valid if it's null or an empty string.
         * Sometimes a Step might not need a form, and that is why this is possible.
         */
        if (this.form == null || this.form === '') {
            return true;
        }
        return penniInputModule.getFormValidity(this.form);
    }

    public get dirty(): boolean {
        const step = penniStepModule.getStep(this.step);
        return step == null ? false : step.dirty;
    }

    public get price(): number | undefined {
        return penniCheckoutModule.calculationResponse?.productPrices[0].variations.find(
            (variation) => variation.id === penniCheckoutModule.basketSelectedVariation?.id
        )?.premium[penniCheckoutModule.selectedPremiumType ?? 'monthly'];
    }

    public get showPrePaymentButtonText(): boolean {
        return this.getTextResource('pre-payment-button', '') !== '';
    }

    public get isPaymentStep(): boolean {
        return this.type === StepType.PAYMENT;
    }

    public get isManualStep(): boolean {
        return this.type === StepType.MANUAL;
    }

    public get singleStep(): boolean {
        return penniStepModule.steps.length === 1;
    }

    public get isLastStep(): boolean {
        return this.currentStep === penniStepModule.steps.length;
    }

    public get showStepSummary(): boolean {
        return penniContentModule.checkout?.options.features?.stepSummary?.enabled ?? false;
    }

    @Watch('formIsValid')
    public async shouldProceedToNextStep(formIsValid: boolean): Promise<void> {
        /**
         * Added the nextTick here since we have Conditional component
         * This component will render the input fields later then this
         * We should wait for the DOM updates to complete
         */
        await this.$nextTick();

        const shouldAutoProceed =
            formIsValid &&
            penniStepModule.shouldAutoProceed &&
            !this.isLastStep &&
            !this.isPaymentStep &&
            !this.isManualStep;

        /**
         * form validity might change independantly to the current step
         * in the case of the resumable baskets
         */
        if (this.isCurrentStep && shouldAutoProceed) {
            await this.goToNextStep();
        }
    }

    @Watch('isCurrentStep', {immediate: true})
    public scrollCurrentStep(newVal: boolean): void {
        /**
         * in case the step is very long or on mobile
         * make sure people don't end up staring at the
         * footer after they filled a long step
         */
        if (newVal === true && this.currentStep !== 1) {
            this.$nextTick(() => {
                this.scrollIntoView(this.$el);
            });
        }
    }
}
