



































import StepMixin from '@/steps/mixin';
import {penniCheckoutModule, penniCommonModule, penniInputModule, penniPaymentModule} from '@/store';
import {getBaseReturnUrl} from '@/utils';
import {Component, Mixins} from 'vue-property-decorator';
import {PaymentProviderEnum} from '..';
import PenniPrice from '../layout/Price.vue';

@Component({components: {PenniPrice}})
export default class PaymentProvider extends Mixins(StepMixin) {
    public paymentMethods: PaymentProviderEnum[] = [];

    public async mounted(): Promise<void> {
        this.paymentMethods = await penniPaymentModule.getProviders();
    }

    public get price(): number | undefined {
        if (penniCheckoutModule.paymentButtonPremium != null) {
            return penniCheckoutModule.paymentButtonPremium;
        }

        return penniCheckoutModule.calculationResponse?.productPrices[0].variations.find(
            (variation) => variation.id === penniCheckoutModule.basketSelectedVariation?.id
        )?.premium[penniCheckoutModule.selectedPremiumType ?? 'monthly'];
    }

    public get selectedPayment(): PaymentProviderEnum | undefined {
        const input = penniInputModule.getInput('paymentProviderOption');
        return input?.value as PaymentProviderEnum | undefined;
    }

    public async startPayment(): Promise<void> {
        try {
            if (penniInputModule.getFormValidity(this.form) === false) {
                this.validateInputs();

                this.$nextTick(() => {
                    this.focusFirstInvalidInput();
                });

                return;
            }

            if (penniCheckoutModule?.basketResponse == null) {
                throw new Error('Cannot proceed to Payment Gateway - no basket response received.');
            }

            if (penniCheckoutModule?.quoteResponse == null) {
                throw new Error('Cannot proceed to Payment Gateway - no quote response received.');
            }

            if (this.selectedPayment == null) {
                throw new Error('Cannot proceed to Payment Gateway - no payment selected.');
            }

            penniCommonModule.setLoading(true);
            const checkoutUrl = await penniPaymentModule.getPaymentUrl({
                paymentProvider: this.selectedPayment,
                basketId: penniCheckoutModule.basketResponse.id,
                transactionId: penniCheckoutModule.quoteResponse.transactionId || '',
                premiumType: penniCheckoutModule.selectedPremiumType,
                returnUrl: getBaseReturnUrl(),
            });

            if (checkoutUrl == null) {
                throw new Error('Cannot proceed to Payment Gateway - no checkout URL received.');
            }

            window.location.href = checkoutUrl;
        } catch (err) {
            throw err;
        } finally {
            penniCommonModule.setLoading(false);
        }
    }

    public paymentMethodResourceId(paymentMethod: string): string {
        return `payment-${paymentMethod}`;
    }
}
