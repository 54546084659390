


























































import ContentfulMixin from '../content/mixin';
import {Component, Mixins} from 'vue-property-decorator';

type AccordionItem = {title: string; textResourceId: string};

@Component
export default class CoveragesAndFaqs extends Mixins(ContentfulMixin) {
    public coveragesData: AccordionItem[] = [];
    public faqsData: AccordionItem[] = [];
    public coveragesOpenIdx: number = 0;
    public faqsOpenIdx: number = 0;

    public beforeMount(): void {
        this.coveragesData = this.getTextResourceList('coverage-title').map((title, i) => {
            return {
                title,
                textResourceId: `coverage-text-${i + 1}`,
            };
        });

        this.faqsData = this.getTextResourceList('faq-title').map((title, i) => {
            return {
                title,
                textResourceId: `faq-text-${i + 1}`,
            };
        });
    }

    public get coveragesTitle(): string {
        return this.getTextResource('coverages-title', 'Coverages');
    }

    public get faqTitle(): string {
        return this.getTextResource('faqs-title', 'FAQs');
    }
}
