import {NavigationGuard, NavigationGuardNext, Route} from 'vue-router';
import {penniHttpClient} from '..';
import {basketRouteBeforeEnter} from '../basket';

const savePaidItem = async (basketId: string, quoteId: string, paymentProvider: string, paymentId: string) => {
    try {
        const payload = {paymentId, paymentProvider};
        const requestUrl = `${penniHttpClient.defaults.baseURL}/payment/complete/${basketId}/${quoteId}`;
        await penniHttpClient.post(requestUrl.toString(), payload);
    } catch (error) {
        console.error('failed to save paid item in the basket', error);
    }
};

export function paymentRouteBeforeEnter(): NavigationGuard {
    return async (to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
        await basketRouteBeforeEnter()(to, _from, async () => {
            const params = to.params as Record<'basketId' | 'transactionId' | 'paymentProvider', string>;

            // Save the updated basket with the paid product
            // TODO: Why support lowercase paymentid?
            const paymentId = (to.query['paymentid'] ?? to.query['paymentId']) as string | undefined;

            if (paymentId == null) {
                throw new Error('No Payment ID provided');
            }

            // Make call to the backend to update the basket with the paid item
            await savePaidItem(params.basketId, params.transactionId, params.paymentProvider, paymentId);

            next();
        });
    };
}
