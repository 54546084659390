import {
    CheckoutPlugin,
    CheckoutPrismPlugin,
    PenniCheckboxPlugin,
    PenniFormPlugin,
    PenniSelectPlugin,
    PenniStepsPlugin,
    PenniTextPlugin,
    PenniRadioPlugin,
    PenniDropdownPlugin,
    PenniDatepickerPlugin,
    CheckoutLayoutPlugin,
    PenniAutocompletePlugin,
    PenniAddressLookupPlugin,
    PenniNumberPlugin,
    PenniPhonePlugin,
} from '@/index';
import Vue, {Component, VNode} from 'vue';
import {router} from './router';
import VueMeta from 'vue-meta';
import {NavigationGuardNext, PenniRouteConfig, Route} from 'vue-router';
import {penniHttpClient} from '@/plugin';

export function bootstrap(Component: Component): Vue {
    Vue.config.productionTip = false;

    Vue.use(VueMeta);

    // The main Checkout plugin
    Vue.use(CheckoutPlugin);

    // Components
    Vue.use(PenniTextPlugin);
    Vue.use(PenniNumberPlugin);
    Vue.use(PenniCheckboxPlugin);
    Vue.use(PenniSelectPlugin);
    Vue.use(PenniRadioPlugin);
    Vue.use(PenniFormPlugin);
    Vue.use(PenniStepsPlugin);
    Vue.use(PenniDropdownPlugin);
    Vue.use(PenniDatepickerPlugin);
    Vue.use(CheckoutLayoutPlugin);
    Vue.use(PenniAutocompletePlugin);
    Vue.use(PenniAddressLookupPlugin);
    Vue.use(PenniPhonePlugin);

    router.beforeEach((to: Route, _from: Route, next: NavigationGuardNext) => {
        const routeMeta = to.meta as PenniRouteConfig['meta'];

        penniHttpClient.defaults.baseURL = routeMeta.apiBaseUrl;

        // Prism
        if (routeMeta.prism !== false) {
            Vue.use(CheckoutPrismPlugin, {
                baseUrl: routeMeta.apiBaseUrl,
            });
        }

        next();
    });

    return new Vue({
        router,
        render: (h): VNode => h(Component),
    }).$mount('#app');
}
