






import {Component} from 'vue-property-decorator';
import PaymentSuccess from '@/layout/PaymentSuccess.vue';
import GenericSuccess from '@/views/GenericSuccess.vue';
import {penniCheckoutModule, penniContentModule, penniProductModule} from '@/store';
import {CalculatedProductVariationReference, OptionalCoverage, ProductVariation} from '@penni/generic-api';
import {MessageAutomationInsuranceTypesEnum} from '@/store/models/message';
import * as Api from '../types';
import {UnknownRecord} from '@penni/generic-api/build/utility';

@Component({components: {GenericSuccess}})
export default class CardSuccess extends PaymentSuccess {
    // we duplicate the set order summary here because the customer wants it!
    // once we decide to give up this
    // we can delete this file and replace the CardSuccess to General Success Page
    public calculationSelectedVariation: CalculatedProductVariationReference<ProductVariation<null>> | undefined;

    public async beforeMount(): Promise<void> {
        this.setOrderSummary();
    }

    private setOrderSummary(): void {
        if (penniContentModule.checkout == null) {
            return;
        }
        const selectedVariationId = penniCheckoutModule.basketSelectedVariation?.id;
        const basketCalculationRequest = penniCheckoutModule.basketResponse?.products[0].request
            .products[0] as Api.CardCalculationRequest;

        const optionalCoverageIds = basketCalculationRequest.optionalCoverages
            .filter((optionalCoverage: OptionalCoverage<UnknownRecord>) =>
                (optionalCoverage.parameters.selectedVariationIds as string[]).find(
                    (variationId: string) => selectedVariationId === variationId
                )
            )
            .map((c) => c.id);

        const premium = penniCheckoutModule.calculationResponse?.productPrices[0].variations.find(
            (v) => v.id === selectedVariationId
        )?.premium.oneTimePayment;

        const variationCoverages = penniProductModule.variations.find((v) => v.id === selectedVariationId)
            ?.coverages as Api.TrygCardApiCoverage[];

        const filteredCoverages = variationCoverages?.filter(
            (coverage) =>
                optionalCoverageIds.find((coverageId) => coverage.id === coverageId) != null ||
                !coverage.options?.optional
        );

        penniCheckoutModule.setOrderSummary([
            {
                type: MessageAutomationInsuranceTypesEnum.Other,
                title: 'Rejseforsikring',
                price: premium,
                subtitle: 'Dækning for din rejse',
                coverages: filteredCoverages,
            },
        ]);
    }
}
