import {PluginObject, VueConstructor} from 'vue';
import PenniForm from './Form.vue';

export * from './Form.vue';

export const PenniFormPlugin: PluginObject<null> = {
    install(Vue: VueConstructor): void {
        Vue.component('PenniForm', PenniForm);
    },
};
