




import {Component, Vue} from 'vue-property-decorator';
import GenericError from './GenericError.vue';

@Component({components: {GenericError}})
export default class NotFound extends Vue {}
