



























import {
    penniCheckoutModule,
    penniContentModule,
    penniInputModule,
    penniSignatureModule,
    penniStepModule,
} from '@/store';
import {SignatureProviderEnum} from '@/signature/model';
import {SignatureDocument, SignatureModule, SignatureResponse} from '@/store/signatureModule';
import {Component, Prop, Watch, Mixins} from 'vue-property-decorator';
import StepMixin from '../steps/mixin';
import {getBaseReturnUrl} from '@/utils';

@Component
export default class SignatureProvider extends Mixins(StepMixin) {
    @Prop({required: false, default: '', type: Number}) public readonly step!: number;

    public documents: SignatureDocument[] = [];
    public isLoading: boolean = true;
    public signatureResponse: SignatureResponse = {signatureUrl: '', envelopeId: ''};

    public get currentStep(): number {
        return penniStepModule.currentStep;
    }

    @Watch('currentStep')
    public async onCurrentStep(): Promise<void> {
        if (this.step === this.currentStep) {
            await this.startSignatureProcess();
        }
    }

    public async startSignatureProcess(): Promise<void> {
        this.isLoading = true;
        this.documents = [];
        this.signatureResponse = await this.getSignatureResponse();
        this.documents = await penniSignatureModule.getSignatureDocuments({
            envelopeId: this.signatureResponse.envelopeId,
            provider: this.selectedProvider,
        });
        this.isLoading = false;
    }

    public get selectedProvider(): SignatureProviderEnum {
        return SignatureProviderEnum.DOCUSIGN;
    }

    public getDocumentUrl(documentId: string, documentName: string): string {
        if (this.signatureResponse.envelopeId == '') {
            throw new Error('Cannot proceed - cannot get document list without envelopeId');
        }

        const url = SignatureModule.getDocumentUrl(
            documentId,
            documentName,
            this.signatureResponse.envelopeId,
            this.selectedProvider
        );
        return `${encodeURI(url)}.pdf`;
    }

    public get enableSMS(): boolean {
        return penniContentModule.options.features?.signatureSMS?.enabled != null;
    }

    public async getSignatureResponse(): Promise<SignatureResponse> {
        try {
            if (penniCheckoutModule?.basketResponse == null) {
                throw new Error(`${this.selectedProvider} Cannot proceed - no basket response received.`);
            }

            if (penniCheckoutModule?.quoteResponse == null) {
                throw new Error(`${this.selectedProvider} Cannot proceed - no quote response received.`);
            }

            // TODO: this is super weak, how else can it be done (without injection from parent)
            const firstNameInput = penniInputModule.getInput('firstname');
            const lastNameInput = penniInputModule.getInput('lastname');
            const emailInput = penniInputModule.getInput('email');
            const phoneInput = penniInputModule.getInput('phone');

            if (firstNameInput?.value == null || emailInput?.value == null) {
                throw new Error(`${this.selectedProvider} Cannot proceed - email or name not detected`);
            }
            if (this.selectedProvider == null) {
                throw new Error(`${this.selectedProvider} Cannot proceed - no signature provider selected.`);
            }

            const fullName = `${firstNameInput?.value ?? ''} ${lastNameInput?.value ?? ''}`;

            const signatureResponse = await penniSignatureModule.getSignatureUrl({
                provider: this.selectedProvider,
                basketId: penniCheckoutModule.basketResponse.id,
                transactionId: penniCheckoutModule.quoteResponse.transactionId || '',
                customerName: fullName,
                email: emailInput.value as string,
                phone: this.enableSMS ? phoneInput?.value?.toString() : undefined,
                returnUrl: getBaseReturnUrl(),
            });
            if (signatureResponse == null) {
                throw new Error(`${this.selectedProvider} Cannot proceed - no response.`);
            }

            return signatureResponse;
        } catch (error) {
            throw error;
        } finally {
        }
    }

    public async startSignature(): Promise<void> {
        if (this.formIsValid == false) {
            this.validateInputs();

            this.$nextTick(() => {
                this.focusFirstInvalidInput();
            });
            return;
        }
        if (this.signatureResponse.signatureUrl == '') {
            throw new Error(`${this.selectedProvider} Cannot proceed - no signature URL received.`);
        }

        window.location.href = this.signatureResponse.signatureUrl;
    }

    public get formIsValid(): boolean {
        /**
         * A form is valid if it's null or an empty string.
         * Sometimes a Step might not need a form, and that is why this is possible.
         */
        if (this.form == null || this.form === '') {
            return true;
        }
        return penniInputModule.getFormValidity(this.form);
    }
}
