import {prism} from '@/analytics/plugin';
import {penniHttpClient} from '@/plugin';
import {CreateQuoteRequest, SidebarResource} from '@/store/models';
import {
    BasketResponse,
    CalculatedProductVariationReference,
    CalculationProduct,
    CalculationRequest,
    CalculationResponse,
    Customer,
    CustomerContact,
    Premium,
    QuoteCreateResponse,
} from '@penni/generic-api';
import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {OldBasketResponse, transformBasket} from './basket-compatibility';
import {OrderProduct} from './models';
import {BasketItem, CreateBasketEmailReminderRequest, MessageAutomationEnum} from './models/message';
import {InputValues} from '..';
import _set from 'lodash/set';
import _get from 'lodash/get';

export const CHECKOUT_MODULE_NAMESPACE = 'checkoutModule';

type Basket = {
    products?: BasketItem[];
    total?: string;
};

const basketReminderRequestMapper = (orderSummary: OrderProduct[], priceUnit: string): Basket => {
    const total = orderSummary.reduce((acc: number, current: OrderProduct) => acc + (current.price || 0), 0).toString();

    return {
        products: orderSummary.map(
            (item): BasketItem => {
                const price = item.price?.toString() || '';
                return {
                    name: item.title,
                    detail: item.subtitle,
                    price: priceUnit.replace('{price}', price),
                    type: item.type,
                };
            }
        ),
        total: priceUnit.replace('{price}', total),
    };
};

@Module({
    name: CHECKOUT_MODULE_NAMESPACE,
    stateFactory: true,
    namespaced: true,
})
export class CheckoutModule extends VuexModule {
    /** basket object returned by the backend */
    public basketResponse: BasketResponse | null = null;
    public basketMeta: Record<string, InputValues>[] | null = null;
    /** basket retrieval status */
    public basketRetrievalFailed: boolean = false;
    /**
     * has the basket been completed?
     * completed baskets are removed from the database
     */
    public basketCompleted: boolean = false;

    /**
     * has the basket been saved?
     * saved baskets are kept in the database, and email automation is started
     */
    public basketSaved: boolean = false;

    public basketReminderCreated: boolean = false;
    public savedBasketReminderStarted: boolean = false;
    public basketRemindersStopped: boolean = false;
    /**
     * basket product parameters should be mapped to checkout input fields,
     * this should only happen once
     */
    public basketMappedToInputs: boolean = false;
    /**
     * storage of quotes created in the backend
     */
    public quoteResponse: QuoteCreateResponse | null = null;
    /**
     * calculation request object, initially populated with basket data used in widgets
     */
    public calculationRequest: CalculationRequest<CalculationProduct<string, Record<string, unknown>>> | null = null;
    /**
     * calculation response object, initially populated with basket data used in widgets
     */
    public calculationResponse: CalculationResponse<CalculatedProductVariationReference> | null = null;
    /**
     * selected variation (from widget)
     */
    public basketSelectedVariation: CalculatedProductVariationReference | null = null;
    /**
     * order summary
     */
    public orderSummary: OrderProduct[] | null = null;
    /**
     * Downloadable documents in the sidebar
     */
    public documentList: SidebarResource[] | null = null;
    /**
     * Items in the sidebar with a thumbs up icon
     */
    public sidebarSocialProof: SidebarResource[] | null = null;
    /**
     * Price/time unit for the basket reminders
     */
    public priceUnit: string = '{price}';
    /**
     * quote cancel has been done
     */
    public quoteCancelled: boolean = false;
    /**
     * quote has been finalized (after payment usually)
     */
    public quoteFinalized: boolean = false;
    public selectedPremiumType: keyof Premium = 'monthly';
    public firstName: string = '';
    public basketEmail: string = '';
    public previousBasketEmail: string = '';
    public partnerName: string = '';
    public allowAbandonedBasketReminder: boolean = false;
    public successVariables: Record<string, unknown> = {};
    public paymentButtonPremium: number | null = null;

    @Mutation
    public setBasketResponse<B extends BasketResponse = BasketResponse>(payload: B): void {
        this.basketResponse = payload;
    }

    @Mutation
    public setBasketMeta(payload: Record<string, InputValues>[]): void {
        this.basketMeta = payload;
    }

    @Mutation
    public setBasketRetrievalFailed(payload: boolean): void {
        this.basketRetrievalFailed = payload;
    }

    @Mutation
    public setBasketCompleted(payload: boolean): void {
        this.basketCompleted = payload;
    }

    @Mutation
    public setBasketSaved(payload: boolean): void {
        this.basketSaved = payload;
    }

    @Mutation
    public setBasketReminderCreated(payload: boolean): void {
        this.basketReminderCreated = payload;
    }

    @Mutation
    public setSavedBasketReminderStarted(payload: boolean): void {
        this.savedBasketReminderStarted = payload;
    }

    @Mutation
    public setBasketRemindersStopped(payload: boolean): void {
        this.basketRemindersStopped = payload;
    }

    @Mutation
    public setCalculationRequest(
        payload: CalculationRequest<CalculationProduct<string, Record<string, unknown>>>
    ): void {
        this.calculationRequest = payload;
    }

    @Mutation
    public setSelectedPremiumType(payload: keyof Premium): void {
        this.selectedPremiumType = payload;
    }

    @Mutation
    public setCalculationResponse<
        C extends CalculationResponse<CalculatedProductVariationReference> = CalculationResponse<
            CalculatedProductVariationReference
        >
    >(payload: C): void {
        this.calculationResponse = payload;
    }

    @Mutation
    public setBasketMappedToInputs(payload: boolean): void {
        this.basketMappedToInputs = payload;
    }

    @Mutation
    public setQuoteResponse<Q extends QuoteCreateResponse>(payload: Q): void {
        this.quoteResponse = payload;
    }

    @Mutation
    public setBasketSelectedVariation(payload: CalculatedProductVariationReference): void {
        this.basketSelectedVariation = payload;
    }

    @Mutation
    public setOrderSummary(payload: OrderProduct[]): void {
        this.orderSummary = payload;
    }

    @Mutation
    public setDocumentList(payload: SidebarResource[]): void {
        this.documentList = payload;
    }

    @Mutation
    public setSidebarSocialProof(payload: SidebarResource[]): void {
        this.sidebarSocialProof = payload;
    }

    @Mutation
    public setPriceUnit(payload: string): void {
        this.priceUnit = payload;
    }

    @Mutation
    public setFirstName(payload: string): void {
        this.firstName = payload;
    }

    @Mutation
    public setBasketEmail(payload: string): void {
        this.previousBasketEmail = this.basketEmail;
        this.basketEmail = payload;
    }

    @Mutation
    public setPaymentButtonPremium(premium: number | null): void {
        this.paymentButtonPremium = premium;
    }

    @Mutation
    public setPartnerName(payload: string): void {
        this.partnerName = payload;
    }

    @Mutation
    public setAllowAbandonedBasketReminder(payload: boolean): void {
        this.allowAbandonedBasketReminder = payload;
    }

    @Mutation
    public setQuoteCancelled(payload: boolean): void {
        this.quoteCancelled = payload;
    }

    @Mutation
    public setQuoteFinalized(payload: boolean): void {
        this.quoteFinalized = payload;
    }

    @Mutation
    public setSuccessVariables(payload: Record<string, unknown>): void {
        this.successVariables = payload;
    }

    @Action
    public async fetchBasket(payload: {basketId: string}): Promise<void> {
        try {
            const requestUrl = new URL(`${penniHttpClient.defaults.baseURL}/basket/${payload.basketId}`);

            const response = await penniHttpClient.get<BasketResponse | OldBasketResponse>(requestUrl.toString());
            const basket = transformBasket(response.data);

            this.context.commit('setBasketResponse', basket);

            this.context.commit(
                'setBasketSelectedVariation',
                basket.products[0].response.productPrices[0].variations.find(
                    (variation) => variation.id === basket.products[0].selectedVariationId
                )
            );

            /**
             * re-hydrates the quote response from the basket
             */
            if (_get(basket, 'products.0.response.quote')) {
                this.setQuoteResponse(_get(basket, 'products.0.response.quote'));
            }

            /**
             * save basket meta data
             */
            if (_get(basket, 'products.0.meta')) {
                this.context.commit('setBasketMeta', _get(basket, 'products.0.meta'));
            }

            this.context.commit('setBasketRetrievalFailed', false);
            this.context.commit('setCalculationRequest', basket.products[0].request);
            this.context.commit('setCalculationResponse', basket.products[0].response);
        } catch (error) {
            console.error('failed to fetch basket', error);
            this.context.commit('setBasketRetrievalFailed', true);
            this.context.commit('setBasketResponse', null);
            this.context.commit('commonModule/setDidThrow', {error, didThrow: true}, {root: true});
        }
    }

    @Action
    public async updateBasket(basket: BasketResponse): Promise<void> {
        try {
            const requestUrl = new URL(`${penniHttpClient.defaults.baseURL}/basket/${basket.id}/update`);
            await penniHttpClient.post<BasketResponse>(requestUrl.toString(), basket);
            this.context.commit('setBasketResponse', basket);
            this.context.commit('setCalculationRequest', basket.products[0].request);
            this.context.commit('setCalculationResponse', basket.products[0].response);
        } catch (error) {
            console.error('failed to update basket', error);
            this.context.commit('commonModule/setDidThrow', {error, didThrow: true}, {root: true});
        }
    }

    @Action
    public async postQuote<Q extends CreateQuoteRequest>(payload: Q): Promise<void> {
        try {
            const requestUrl = new URL(`${penniHttpClient.defaults.baseURL}/quote`);
            const response = await penniHttpClient.post<QuoteCreateResponse>(requestUrl.toString(), payload);

            /**
             * store information about the quote response in the basket
             */
            if (this.basketResponse != null && payload.completeBasket === false) {
                // In case basket is updated in the backend we fetch the basket first and making a deep copy since Vuex complains about changing it outside mutation if I dont
                await this.fetchBasket({basketId: this.basketResponse.id});
                const basketResponseCopy = JSON.parse(JSON.stringify(this.basketResponse));
                const updatedBasket = _set({...basketResponseCopy}, 'products.0.response.quote', response.data);
                this.updateBasket(updatedBasket);
            }

            this.setQuoteResponse(response.data);

            /**
             * If completeBasket is either null or true we should complete the basket.
             * This is used in cases where we still need the basket after a purchase.
             */
            if (payload.completeBasket == null || payload.completeBasket === true) {
                await this.completeBasket();
                const customerEmail: string = (payload.customer as Customer<
                    null,
                    null,
                    null,
                    CustomerContact<string>,
                    null
                >).contact.email;
                if (this.allowAbandonedBasketReminder) {
                    this.stopBasketReminders({email: customerEmail});
                }
            }
        } catch (error) {
            this.context.commit('setQuoteResponse', null);
            this.context.commit('commonModule/setDidThrow', {error, didThrow: true}, {root: true});
        }
    }

    @Action
    public async completeBasket(): Promise<void> {
        try {
            if (this.basketResponse != null) {
                const requestUrl = `${penniHttpClient.defaults.baseURL}/basket/${this.basketResponse.id}/complete`;
                await penniHttpClient.post<void>(requestUrl.toString());
            }
        } catch (error) {
            console.error('failed to complete basket', error);
            this.context.commit('setBasketCompleted', false);
            this.context.commit('commonModule/setDidThrow', {error, didThrow: true}, {root: true});
        } finally {
            this.context.commit('setBasketCompleted', true);
        }
    }

    @Action
    public async postCalculate(payload: CalculationRequest<CalculationProduct & {variationId: string}>): Promise<void> {
        try {
            const requestUrl = new URL(
                `${penniHttpClient.defaults.baseURL}/calculate/${payload.products[0].variationId}`
            );
            const response = await penniHttpClient.post<CalculationResponse<CalculatedProductVariationReference>>(
                requestUrl.toString(),
                payload
            );

            this.context.commit('setCalculationResponse', response.data);

            prism?.client?.viewProduct(this.context.rootState.productModule.product);
        } catch (error) {
            console.error('failed to do calculation', error);
            this.context.commit('setCalculationResponse', null);
            this.context.commit('commonModule/setDidThrow', {error, didThrow: true}, {root: true});
        }
    }

    /**
     * Should finalize quote and policy creation after successful payment
     *
     * @param transactionId
     * @param partnerId
     */
    @Action
    public async finalizeQuote(payload: {transactionId: string; partnerId: string}): Promise<void> {
        try {
            const requestUrl = new URL(`${penniHttpClient.defaults.baseURL}/quote/finalize`);

            await penniHttpClient.post<string>(requestUrl.toString(), payload);

            this.context.commit('setQuoteFinalized', true);
        } catch (error) {
            console.error('failed to finalize quote', error);
            this.context.commit('setQuoteFinalized', false);
            this.context.commit('commonModule/setDidThrow', {error, didThrow: true}, {root: true});
            await this.cancelQuote(payload);
        }
    }

    /**
     * Should clean up quote in case of errors
     *
     * @param transactionId
     */
    @Action
    public async cancelQuote(payload: {transactionId: string; partnerId: string}): Promise<void> {
        try {
            const requestUrl = new URL(`${penniHttpClient.defaults.baseURL}/quote/cancel`);

            await penniHttpClient.post<string>(requestUrl.toString(), payload);

            this.context.commit('setQuoteCancelled', true);
        } catch (error) {
            console.error('failed to cancel quote', error);
            this.context.commit('setQuoteCancelled', false);
        }
    }

    @Action
    public async createBasketReminder(payload: {
        marketingConsent: boolean;
        email: string;
        type: MessageAutomationEnum;
        name: string | undefined;
        partnerName: string | undefined;
        basket?: Basket;
    }): Promise<void> {
        if (!payload.email) {
            throw new Error('Reminder Email must be set');
        }

        try {
            const partnerName = payload.partnerName ?? 'unknown';
            const name = payload.name === '' ? undefined : payload.name;
            const requestUrl = new URL(`${penniHttpClient.defaults.baseURL}/message/reminder`);
            const basketUrl = new URL(window.location.href);
            let utmSource = '';
            switch (payload.type) {
                case MessageAutomationEnum.SavedBasket:
                    utmSource = 'saved_basket';
                    break;
                case MessageAutomationEnum.AbandonedBasket:
                    utmSource = 'abandoned_basket';
                    break;
            }

            basketUrl.searchParams.set('utm_campaign', partnerName);
            basketUrl.searchParams.set('utm_source', utmSource);
            basketUrl.searchParams.set('utm_medium', 'email');

            const data: CreateBasketEmailReminderRequest = {
                name,
                email: payload.email,
                marketingConsent: payload.marketingConsent,
                type: payload.type,
                basketUrl: basketUrl.toString(),
                partnerName,
                basket:
                    this.orderSummary && this.orderSummary.length > 0
                        ? basketReminderRequestMapper(this.orderSummary, this.priceUnit)
                        : undefined,
            };
            this.context.commit(
                'setBasketReminderCreated',
                (await penniHttpClient.post<boolean>(requestUrl.toString(), data)).data
            );
            return;
        } catch (error) {
            console.error('failed to create basket', error);
            // Do not setDidThrow, this is not a critical error that should disturb the user interface
        }

        this.context.commit('setBasketReminderCreated', false);
    }

    @Action
    public async startSavedBasketReminder(payload: {email: string}): Promise<void> {
        try {
            const requestUrl = new URL(`${penniHttpClient.defaults.baseURL}/message/reminder/start`);
            const data = {
                email: payload.email,
            };

            this.context.commit(
                'setSavedBasketReminderStarted',
                (await penniHttpClient.post<boolean>(requestUrl.toString(), data)).data
            );
            return;
        } catch (error) {
            console.error('failed to start basket reminder', error);
            // Do not setDidThrow, this is not a critical error that should disturb the user interface
        }

        this.context.commit('setSavedBasketReminderStarted', false);
    }

    @Action
    public async stopBasketReminders(payload: {email: string; type?: MessageAutomationEnum}): Promise<void> {
        try {
            const requestUrl = new URL(`${penniHttpClient.defaults.baseURL}/message/reminder/stop`);
            const data = {
                email: payload.email,
                type: payload.type,
            };
            this.context.commit(
                'setBasketRemindersStopped',
                (await penniHttpClient.post<boolean>(requestUrl.toString(), data)).data
            );
            return;
        } catch (error) {
            console.warn('failed to delete basket reminders', error);
            // Do not setDidThrow, this is not a critical error that should disturb the user interface
        }

        this.context.commit('setBasketRemindersStopped', false);
    }

    @Action
    public async updateAbandonedBasketReminders(payload: {
        oldEmail?: string;
        newEmail?: string;
        firstName?: string;
        partnerName?: string;
    }): Promise<void> {
        if (payload.newEmail && payload.newEmail !== payload.oldEmail) {
            // The email has changed; stop the existing reminder countdown
            if (payload.oldEmail) {
                await this.stopBasketReminders({email: payload.oldEmail});
            }

            if (this.allowAbandonedBasketReminder) {
                this.createBasketReminder({
                    email: payload.newEmail,
                    type: MessageAutomationEnum.AbandonedBasket,
                    marketingConsent: false,
                    name: payload.firstName,
                    partnerName: payload.partnerName,
                    basket:
                        this.orderSummary && this.orderSummary.length > 0
                            ? basketReminderRequestMapper(this.orderSummary, this.priceUnit)
                            : undefined,
                });

                this.startSavedBasketReminder({
                    email: payload.newEmail,
                });
            }
        } else {
            // There's an existing reminder; restart the countdown
            if (payload.oldEmail) {
                this.startSavedBasketReminder({
                    email: payload.oldEmail,
                });
            }
        }
    }

    @Mutation
    public resetState(): void {
        this.basketResponse = null;
        this.basketRetrievalFailed = false;
        this.basketCompleted = false;
        this.basketMappedToInputs = false;
        this.quoteResponse = null;
        this.calculationResponse = null;
        this.calculationRequest = null;
        this.basketSelectedVariation = null;
        this.orderSummary = null;
        this.documentList = null;
        this.sidebarSocialProof = null;
        this.priceUnit = '{price}';
        this.firstName = '';
        this.basketEmail = '';
        this.partnerName = '';
        this.previousBasketEmail = '';
    }
}
