import {PluginObject, VueConstructor} from 'vue';
import PenniPhone from './Phone.vue';

export * from './Phone.vue';

export const PenniPhonePlugin: PluginObject<null> = {
    install(Vue: VueConstructor): void {
        Vue.component('PenniPhone', PenniPhone);
    },
};
