

















































































































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import * as Api from '../types';
import * as GenericApi from '@penni/generic-api';
import {
    penniInputModule,
    penniProductModule,
    penniCheckoutModule,
    penniCommonModule,
    penniContentModule,
} from '@/store';
import {CancellationType, OptionItem} from '@penni/generic-api';
import {getResource, ContentModelTypes} from '@penni/contentful-api';
import cloneDeep from 'lodash.clonedeep';
import {Input, MessageAutomationInsuranceTypesEnum, penniHttpClient} from '@/index';
import {NoIntegrationPartners} from '../types';

interface ContentfulOptions {
    steps?: {
        askIsMember: boolean;
    };
}

@Component
export default class MotorView extends Vue {
    public cachedAge: number = 18;
    public askForDriverExperienceCache: boolean = true;
    private hasRegno: boolean = true;
    public leasingCompanyOrderedList: OptionItem[] = [];

    public async created(): Promise<void> {
        penniCommonModule.setAllowSendToEmail(true);

        this.setOrderSummary();

        const basket = penniCheckoutModule.basketResponse;

        if (basket != null) {
            this.hasRegno =
                (basket.products[0].request.products[0] as Api.ApiCalculationSimpleRequest).insuredObject.regno !==
                undefined;

            const basketCalculationRequest = basket.products[0].request.products[0] as Api.ApiCalculationSimpleRequest;
            this.cachedAge = basketCalculationRequest.customer.age ? +basketCalculationRequest.customer.age : 18;
            this.askForDriverExperienceCache = this.cachedAge ? this.cachedAge < 30 : true;
        }
        this.leasingCompanyOrderedList = this.leasingCompanyOptions();
    }

    public get product() {
        return penniProductModule.product;
    }

    @Watch('product')
    public updateAfterGettingProduct(): void {
        this.setOrderSummary();
        this.leasingCompanyOrderedList = this.leasingCompanyOptions();
    }

    private setOrderSummary(): void {
        const variantTitle =
            penniContentModule.checkout == null
                ? ''
                : getResource(
                      `${penniContentModule.checkout.type}-text-variation-title-${
                          penniCheckoutModule.basketSelectedVariation?.id ?? ''
                      }`,
                      ContentModelTypes.TEXT,
                      penniContentModule.checkout
                  )?.value;

        const calculationRequest = penniCheckoutModule.calculationRequest
            ?.products[0] as Api.ApiCalculationSimpleRequest;
        const calculationResponse = penniCheckoutModule.calculationResponse
            ?.productPrices[0] as Api.ApiCalculationProductResponse;
        const selectedVariationId = penniCheckoutModule.basketSelectedVariation?.id;
        const selectedVariation = penniCheckoutModule.calculationResponse?.productPrices[0].variations.find(
            (v) => v.id === selectedVariationId
        );

        const variationCoverages = penniProductModule.variations.find((v) => v.id === selectedVariationId)?.coverages;

        /**
         * Map the parameters from the request to the text they correspond to in the product options
         * This can significantly be improved by either having the text returned in the calculation
         * request or having a specific way of getting that
         */
        const {deductible, mileage} = calculationRequest.insuredObject;

        const parameters = {
            deductible: {
                text:
                    penniProductModule.options?.deductible == null
                        ? ''
                        : (penniProductModule.options.deductible as Record<string, unknown>[]).find(
                              (e: Record<string, unknown>) => e.code === deductible?.code
                          )?.text || '',
            },
            mileage: {
                text:
                    penniProductModule.options?.mileage == null
                        ? ''
                        : (penniProductModule.options.mileage as Record<string, unknown>[]).find(
                              (e: Record<string, unknown>) => e.code === mileage?.code
                          )?.text || '',
            },
        };

        penniCheckoutModule.setOrderSummary([
            {
                type: MessageAutomationInsuranceTypesEnum.Car,
                title: variantTitle ?? 'Bilforsikring',
                price: selectedVariation?.premium.monthly,
                subtitle: `${calculationResponse?.description} ${
                    calculationRequest?.insuredObject.regno !== undefined
                        ? `(${calculationRequest?.insuredObject.regno?.toUpperCase()})`
                        : ''
                }`,
                parameters,
                coverages: variationCoverages,
            },
        ]);
    }

    public get askIsMember(): boolean {
        return (penniContentModule.checkout?.options as ContentfulOptions)?.steps?.askIsMember ?? true;
    }

    public get isMember(): boolean | undefined {
        const value = (penniInputModule.getInput('member')?.value as string) ?? '';
        if (!value.length) {
            return;
        }
        return value === 'true';
    }

    public get noIntegrationPartner(): boolean {
        // Special case for Polestar partner check ticket PCT-3016
        const basketCalculationRequest = penniCheckoutModule.basketResponse?.products[0].request
            .products[0] as Api.ApiCalculationSimpleRequest;
        return Object.values(NoIntegrationPartners).includes(
            basketCalculationRequest.partnerId as NoIntegrationPartners
        );
    }

    public get needRegno(): boolean {
        return !this.hasRegno;
    }

    public marketingConsent(): boolean {
        const value = penniInputModule.getInput('marketingConsent')?.value;
        return value === 'true' ? true : false;
    }

    public get radio(): Input | undefined {
        return penniInputModule.getInput('existingInsurrance');
    }

    public get options(): Api.ApiProductOptions | null {
        return penniProductModule.options;
    }

    public get transferDateOptions(): OptionItem[] | undefined {
        return this.options ? this.options.transferDate : undefined;
    }

    public get insuranceCompanyOptions(): OptionItem[] | undefined {
        return this.options ? this.options.insuranceCompany : undefined;
    }

    public get carModelOptions(): OptionItem[] | undefined {
        return this.options ? this.options.carModel : undefined;
    }

    public get equipmentDropdownItems(): OptionItem[] | undefined {
        return this.options ? this.options.addons : undefined;
    }

    public get timeDropdownItems(): OptionItem[] | undefined {
        return this.options ? this.filterDriverExperience(this.options.driverExperience) : undefined;
    }

    public get isLeasing(): boolean | undefined {
        return (
            (penniCheckoutModule.calculationRequest?.products[0] as Api.ApiQuoteProduct).insuredObject.leasing ===
            'true'
        );
    }

    public leasingCompanyOptions(): OptionItem[] {
        if (this.options != null && this.options.leasingCompany != null) {
            return this.options.leasingCompany
                .slice()
                .sort((a, b) => (a.text ? a.text.toLowerCase().localeCompare(b.text ? b.text.toLowerCase() : '') : 0));
        }
        return [];
    }

    public get firstTimePaymentSumOptions(): OptionItem[] | undefined {
        return this.options ? this.options.firstTimePaymentSum : undefined;
    }

    public get tireAndWheelRimSumOptions(): OptionItem[] | undefined {
        return this.options ? this.options.tireAndWheelRimSum : undefined;
    }

    private filterDriverExperience(optionList?: OptionItem[]): OptionItem[] {
        if (optionList === undefined) {
            return [];
        }
        if (this.cachedAge >= 30) {
            return [
                optionList.find((item: OptionItem) => {
                    return item.code === '99';
                }) as OptionItem,
            ];
        } else {
            const maxExperience = this.cachedAge - 18;
            return optionList.filter((item: OptionItem) => {
                return Number.parseInt(item.code, 10) <= maxExperience;
            });
        }
    }

    public get claimCountOptions(): OptionItem[] | undefined {
        return this.options ? this.options.claimCount : undefined;
    }

    public get askForDriverExperience(): boolean {
        return this.askForDriverExperienceCache;
    }

    public get transferDatesOptionText(): string {
        return penniContentModule.checkout == null
            ? ''
            : getResource(
                  `${penniContentModule.checkout.type}-text-existing-insurance-cancel-first-option`,
                  ContentModelTypes.TEXT,
                  penniContentModule.checkout
              )?.value ?? 'text-not-found-existing-insurance-cancel-first-option';
    }

    public get transferDates(): OptionItem[] | undefined {
        const formatOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'long', day: 'numeric'};
        return this.options && this.options.transferDate
            ? cloneDeep(this.options.transferDate).map((option: GenericApi.OptionItem, index: number) => {
                  const text: string = new Date(option.code).toLocaleDateString('da-DK', formatOptions);
                  return {
                      code: option.code,
                      text: index === 0 ? `${this.transferDatesOptionText} (${text})` : text,
                      default: option.default,
                  };
              })
            : undefined;
    }

    public get basketWasMappedToInput(): boolean {
        return penniCheckoutModule.basketMappedToInputs;
    }

    public async recalculate(): Promise<void> {
        const basketCalculationRequest = penniCheckoutModule.basketResponse?.products[0].request
            .products[0] as Api.ApiCalculationSimpleRequest;
        const basketSelectedVariation = penniCheckoutModule.basketSelectedVariation;

        if (basketSelectedVariation == null) {
            return;
        }
        const params = penniInputModule.getInputsAsParameters;
        const regno =
            basketCalculationRequest.insuredObject.regno ??
            (params['regno'] != null ? `${params['regno']}` : undefined);
        const calculationRequest = {
            customer: {
                name: {
                    firstName: `${params['firstname']}`,
                    lastName: `${params['lastname']}`,
                },
                ssn: `${params['socialSecurityNumber']}`.replace(/-/g, ''),
                contact: {
                    email: `${params['email']}`,
                    phone:
                        basketCalculationRequest.customer.contact?.phone ??
                        basketCalculationRequest.customer.phone ??
                        '',
                },
                address: basketCalculationRequest.customer.address,
                phone: basketCalculationRequest.customer.phone,
            },
            products: [
                {
                    productId: basketCalculationRequest.productId,
                    campaignCode: basketCalculationRequest.campaignCode,
                    insuredObject: {
                        ...basketCalculationRequest.insuredObject,
                        claimCount: {
                            code: `${params['claimCount']}`,
                        },
                        odometerReading: {
                            code: `${params['odometerReading']}`,
                        },
                        tireAndWheelRimSum: {
                            code: `${params['tireAndWheelRimSum']}`,
                        },
                        leasingCompany: params['leaseCompany']
                            ? {
                                  code: `${params['leaseCompany']}`,
                              }
                            : undefined,
                        firstTimePaymentSum: params['firstTimePaymentSumOptions']
                            ? {
                                  code: `${params['firstTimePaymentSumOptions']}`,
                              }
                            : undefined,
                        regno,
                    },
                    parameters: basketCalculationRequest.parameters,
                },
            ],
            partnerId: basketCalculationRequest.partnerId,
            basketId: penniCheckoutModule.basketResponse?.id,
        };
        await this.recalculatePrice(calculationRequest, basketSelectedVariation.id);
        this.setOrderSummary();
    }

    public async recalculatePrice(calculationRequest: Api.ApiCalculationRequest, variationId: string): Promise<void> {
        try {
            const response = await penniHttpClient.post<Api.ApiProductPrices>(
                `/calculate/${variationId}`,
                calculationRequest
            );

            penniCheckoutModule.setCalculationRequest({
                products: calculationRequest.products,
            });
            penniCheckoutModule.setCalculationResponse({
                productPrices: response.data.productPrices,
            });
        } catch (error) {
            penniCheckoutModule.setCalculationResponse({productPrices: []});
            penniCommonModule.setDidThrow({error: error as Error, didThrow: true});
            penniCommonModule.setLoading(false);
            throw new Error('failed to recalculate product');
        }
    }

    public async createQuote(): Promise<void> {
        const calculationRequest = cloneDeep(
            penniCheckoutModule.calculationRequest?.products[0]
        ) as Api.ApiCalculationSimpleRequest;
        const params = penniInputModule.getInputsAsParameters;
        const basketSelectedVariation = penniCheckoutModule.basketSelectedVariation;

        const basket = penniCheckoutModule.basketResponse;
        const basketCalculationRequest = basket?.products[0].request.products[0] as Api.ApiCalculationSimpleRequest;

        if (basketSelectedVariation == null) {
            return;
        }

        const selectedVariation = penniCheckoutModule.calculationResponse?.productPrices[0].variations.find(
            (v) => v.id === basketSelectedVariation.id
        );

        if (selectedVariation?.premium.monthly == null) {
            throw new Error('premium should be defined');
        }

        const request: Api.ApiQuoteRequest = {
            customer: {
                name: {
                    firstName: `${params['firstname']}`,
                    lastName: `${params['lastname']}`,
                },
                ssn: `${params['socialSecurityNumber']}`.replace(/-/g, ''),
                contact: {
                    email: `${params['email']}`,
                    phone:
                        basketCalculationRequest.customer.contact?.phone ??
                        basketCalculationRequest.customer.phone ??
                        '',
                },
                address: basketCalculationRequest.customer.address,
                consent: {
                    all: this.marketingConsent(),
                },
            },
            payment: {
                type: Api.PaymentType.PPS,
                bank: `${params['bank']}`,
                account: `${params['banknumber']}`,
            },
            products: [
                {
                    productId: calculationRequest.productId,
                    productVariationId: basketSelectedVariation.id,
                    partnerId: basketCalculationRequest.partnerId,
                    parameters: calculationRequest.parameters,
                    insuredObject: {
                        ...calculationRequest.insuredObject,
                        transferDate: `${params['cancelInsuranceDate']}`,
                        insuranceCompany: `${params['selectExistingInsurance']}`,
                    },
                    premium: selectedVariation?.premium.monthly,
                    campaignCode: calculationRequest.campaignCode,
                    cancellation: this.getCancellationInformation(),
                },
            ],
            basketId: penniCheckoutModule.basketResponse!.id,
        };
        if (penniCheckoutModule.basketResponse?.affiliates != null) {
            request.affiliates = penniCheckoutModule.basketResponse.affiliates;
        }
        await penniCheckoutModule.postQuote(request);
    }

    private getCancellationInformation(): Api.ApiCancellation {
        const params = penniInputModule.getInputsAsParameters;
        if (this.noIntegrationPartner) {
            return {
                type: CancellationType.NEW,
            } as Api.ApiCancellationNew;
        }
        switch (params['existingInsurrance']) {
            case 'true':
                return {
                    type: CancellationType.TRANSFER,
                    insuranceCompany: {
                        code: params['selectExistingInsurance'],
                    },
                    transferDate: {
                        code: params['cancelInsuranceDate'],
                    },
                } as Api.ApiCancellationTransfer;
            case 'false':
                return {
                    type: CancellationType.NEW,
                } as Api.ApiCancellationNew;
            default:
                throw Error('cancellation information not specified');
        }
    }
    private get getIntegrationStepNumber(): number {
        if (this.noIntegrationPartner) {
            return 3;
        }
        return 4;
    }
}
