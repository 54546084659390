import {prism} from '../analytics/plugin';
import {Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {Modal, Titles} from './models';

export const COMMON_MODULE_NAMESPACE = 'commonModule';

@Module({
    name: COMMON_MODULE_NAMESPACE,
    stateFactory: true,
    namespaced: true,
})
export class CommonModule extends VuexModule {
    public loading: boolean = false;
    public didThrow: boolean = false;
    public error: Error | null = null;
    public modals: Modal[] = [];
    public title: Titles = 'general-header';
    public showMobileOrderSummaryDetails: boolean = false;
    public allowSendToEmail: boolean = false;
    public currency: string = 'DKK';
    public locale: string = 'da-DK';
    // TODO: The backend should all use minor unit prices
    public useDineroPrices: boolean = false;

    /**
     * Only used in the rare occasion that we have multiple payment buttons,
     * and need to know when to complete the basket on the /payment-success route.
     *
     * This flag will be set to `true` from the checkout flow, which tells the /payment-success
     * route to call the /basket/complete endpoint to complete the basket.
     *
     * This should preferably be deprecated soon.
     *
     */
    public shouldCompleteBasket: boolean = false;

    @Mutation
    public setLoading(payload: boolean): void {
        this.loading = payload;
    }

    @Mutation
    public addModal(payload: Modal): void {
        if (this.modals.find((modal) => modal.name === payload.name)) {
            return;
        }
        this.modals = [...this.modals, payload];
    }

    @Mutation
    public toggleModal(payload: Modal): void {
        const modal = this.modals.find((modal) => modal.name === payload.name);
        if (modal != null) {
            modal.active = payload.active;
        }
    }

    @Mutation
    public setTitle(payload: Titles): void {
        this.title = payload;
    }

    @Mutation
    public setShowMobileOrderSummaryDetails(payload: boolean): void {
        this.showMobileOrderSummaryDetails = payload;
    }

    @Mutation
    public setAllowSendToEmail(payload: boolean): void {
        this.allowSendToEmail = payload;
    }

    @Mutation
    public setUseDineroPrices(payload: boolean): void {
        this.useDineroPrices = payload;
    }

    @Mutation
    public setCurrency(payload: string): void {
        this.currency = payload;
    }

    @Mutation
    public setLocale(payload: string): void {
        this.locale = payload;
    }

    @Mutation
    public resetState(): void {
        this.loading = false;
        this.didThrow = false;
        this.error = null;
        this.modals = [];
        this.title = 'general-header';
        this.showMobileOrderSummaryDetails = false;
        this.allowSendToEmail = false;
    }

    @Mutation
    public setDidThrow(payload: {error?: Error; didThrow: boolean}): void {
        this.didThrow = payload.didThrow;
        this.error = payload.error ?? null;

        // if an error is thrown, the user enters a final state where an error screen is presented
        // entering this erroneous final state, is sent to analytics.
        if (this.didThrow && this.error != null) {
            prism?.client?.error(this.error.message);
        }
    }

    @Mutation
    public setShouldCompleteBasket(payload: boolean): void {
        this.shouldCompleteBasket = payload;
    }

    public get getModalById(): (id: string) => Modal | undefined {
        return (id: string) => this.modals.find((modal) => modal.name === id);
    }
}
