












import {penniCommonModule} from '../store';
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class SummaryProgress extends Vue {
    public toggleShowSummary(): void {
        penniCommonModule.setShowMobileOrderSummaryDetails(!this.showMobileOrderSummaryDetails);
    }

    public get showMobileOrderSummaryDetails(): boolean {
        return penniCommonModule.showMobileOrderSummaryDetails;
    }
}
