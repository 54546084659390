















import {Component, Mixins} from 'vue-property-decorator';
import {penniCheckoutModule, penniCommonModule, penniContentModule, penniInputModule} from '../store';
import ViewsMixin from './mixin';

@Component
export default class GenericSuccess extends Mixins(ViewsMixin) {
    public async mounted(): Promise<void> {
        penniCommonModule.setTitle('success-header');
        if (window) {
            window.scrollTo(0, 0);
        }
        this.setSelectedPremium();
    }

    public get replacementVars(): Record<string, unknown> | undefined {
        let variables: Record<string, unknown> = {};

        if (this.configReplacementVariables != null && this.configReplacementVariables.length > 0) {
            this.configReplacementVariables.forEach((v) => {
                variables[`{${v}}`] = penniInputModule.getInput(v)?.value ?? '';
            });
        }

        // Check if variables set in the store exist and add to variables to be replaced in success text
        if (penniCheckoutModule.successVariables != null) {
            const storeVariables = Object.entries(penniCheckoutModule.successVariables);
            if (storeVariables.length > 0) {
                storeVariables.forEach((entry) => {
                    variables[`{${entry[0]}}`] = entry[1];
                });
            }
        }

        return variables;
    }

    public get configReplacementVariables(): string[] | undefined {
        return penniContentModule.checkout?.options.successReplacementVariables;
    }
}
