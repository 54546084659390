


































import {Component, Vue} from 'vue-property-decorator';
import {penniInputModule, penniCommonModule, penniContentModule} from '../store';
import {Button, Message} from 'buefy';
import {Input} from '@/store/models';
import {CustomerJourneyCheckoutOptions} from '@penni/contentful-api';

Vue.use(Button);
Vue.use(Message);

@Component
export default class RecoveryError extends Vue {
    public mounted(): void {
        penniInputModule.updateInput({
            name: this.errorInput.name,
            value: this.errorInput.value,
            summary: `${this.errorInput.value}`,
            valid: this.errorInput?.valid ?? null,
        });

        penniCommonModule.setTitle('error-header');
    }

    public onSubmit(): void {
        console.warn('The recovery screen is not done yet. Nothing happened.');
    }

    public get errorInput(): Input {
        if (this.recoveryErrorScreen == null) {
            throw new Error('recovery error screen has not been configured');
        }

        const formInput = penniInputModule.getInput(this.recoveryErrorScreen.inputFieldName);

        return {
            ...formInput,
            form: 'recovery-error-form',
            type: 'text',
            required: true,
            name: `recovery-error-${formInput?.name ?? 'phone'}`,
            req: `recovery-error-${formInput?.req ?? 'phone'}`,
            value: formInput?.value ?? '',
            summary: `${formInput?.value}` ?? '',
            valid: formInput?.valid ?? null,
            label: formInput?.label ?? 'phone',
            pattern: formInput?.pattern ?? '[0-9]*',
            validationMessage: formInput?.validationMessage ?? 'ERROR',
            placeholder: formInput?.placeholder ?? 'phone',
        };
    }

    public get recoveryErrorScreen(): CustomerJourneyCheckoutOptions['recoveryErrorScreen'] {
        if (this.options?.recoveryErrorScreen?.inputFieldName == null) {
            throw new Error('The recovery error screen inputFieldName is not set');
        }
        return this.options.recoveryErrorScreen;
    }

    public get formIsValid(): boolean {
        return penniInputModule.getFormValidity('recovery-error-form');
    }

    public get options(): CustomerJourneyCheckoutOptions | undefined {
        return penniContentModule.checkout?.options;
    }
}
