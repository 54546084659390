



































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import {penniCommonModule, penniCheckoutModule} from '../store';
import {prism} from '../analytics/plugin';
import ContentfulMixin from '../content/mixin';
import {ContentModelTypes} from '@penni/contentful-api';
import {Premium} from '@penni/generic-api';
import {MessageAutomationEnum} from '../store/models/message';

@Component
export default class SaveBasketModal extends Mixins(ContentfulMixin) {
    @Prop({required: true, type: String}) public name!: string;

    public readonly emailField: string = 'save-basket-email';
    public readonly marketingConsentField: string = 'save-basket-marketing-consent';
    public email: string = '';
    public marketingConsent: boolean = false;
    public requestSent: boolean = false;

    public keydown(event: KeyboardEvent): void {
        prism?.client?.keystroke(this.emailField, event.key);
    }

    public blur(): void {
        prism?.client?.engagement();
    }

    public closeModal(): void {
        penniCommonModule.toggleModal({name: this.name, active: false});
    }

    public async sendBasket(): Promise<void> {
        if (this.isFormValid() === false) {
            return;
        }

        penniCheckoutModule.setPriceUnit(this.contentfulPrice);

        await penniCheckoutModule.createBasketReminder({
            marketingConsent: this.marketingConsent,
            type: MessageAutomationEnum.SavedBasket,
            email: this.email,
            name: penniCheckoutModule.firstName,
            partnerName: penniCheckoutModule.partnerName,
        });

        this.requestSent = true;

        const text = (this.$refs.trigger as Vue).$el.textContent ?? 'save-basket-triger';

        prism?.client?.click('send-email-checkout', text);
    }

    public isFormValid(): boolean {
        // checkHtml5Validity is a buefy method
        const emailComp = this.$refs.emailInput as Vue & {checkHtml5Validity: () => void};
        const emailInput = emailComp.$el as HTMLInputElement;

        emailComp.checkHtml5Validity();

        if (emailInput == null || !emailInput.querySelector(`input`)?.validity.valid) {
            return false;
        }
        return true;
    }

    public get contentfulLabel(): string {
        return this.getTextResource(`${this.emailField}-label`, 'no label');
    }

    public get contentfulPlaceholder(): string {
        return this.getTextResource(`${this.emailField}-placeholder`, 'no placeholder');
    }

    public get contentfulError(): string {
        return this.getTextResource(`${this.emailField}-error`, 'no error');
    }

    public get emailVariables(): Record<string, string> {
        return {'{email}': this.email};
    }

    public get emailRequestStatus(): 'success' | 'error' {
        return penniCheckoutModule.basketReminderCreated ? 'success' : 'error';
    }

    public get contentfulPrice(): string {
        return this.getTextResource(`price-${this.selectedPremiumType}`, 'no price');
    }

    public get hasMarketingConsent(): boolean {
        return this.resourceExists('save-basket-marketing-consent', ContentModelTypes.TEXT);
    }

    public get selectedPremiumType(): keyof Premium {
        return penniCheckoutModule.selectedPremiumType;
    }

    @Watch('marketingConsent')
    public onMarketingConsentChange(newVal: boolean): void {
        prism?.client?.value('marketingConsent', newVal);
    }
}
