
























import {Component, Mixins, Prop} from 'vue-property-decorator';
import ContentfulMixin from '../../content/mixin';

@Component
export default class Usp extends Mixins(ContentfulMixin) {
    public mediaParams: Record<string, string> = {fit: 'fill', h: '250', w: '250', r: 'max'};

    @Prop({required: true, type: String}) public readonly id!: string;
    @Prop({required: false, type: Number, default: 1}) public readonly uspCount!: number;
    @Prop({required: false, type: Array, default: () => []}) public readonly disableButton!: number[];

    public get isCountValid(): boolean {
        const isValid = this.uspCount < 4 && this.uspCount > 0;
        if (!isValid) {
            throw new Error('uspCount must be between 1-3');
        }
        return isValid;
    }
}
