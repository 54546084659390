import {PluginObject, VueConstructor} from 'vue';
import PenniSteps from './Steps.vue';
import PenniStepItem from './StepItem.vue';

export * from './StepItem.vue';

export const PenniStepsPlugin: PluginObject<null> = {
    install(Vue: VueConstructor): void {
        Vue.component('PenniSteps', PenniSteps);
        Vue.component('PenniStepItem', PenniStepItem);
    },
};
